import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPassword, verifyKey } from '../../actions/authentication';
// import { clearMessages } from "../../actions/messages";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';

import Products from '../Global/Products/Products';

import Loading from '../../components/Global/Loading';


class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      touched: false,
      touched2: false,
      eightChar: false,
      upperCase: false,
      specChar: false,
      number: false,
      match: false,
      method: 'pwd',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.verifyKey(this.props.match.params);
  }

  handleChange(e) {
    // update after onChange() to render errors if true
    this.setState({ touched: true });

    // set password1 to state
    if (e.target.name === 'password1') {
      this.setState({ password1: e.target.value });
    }
    // set password2 to state
    if (e.target.name === 'password2') {
      this.setState({ password2: e.target.value });
    }

    // password1 length test
    if (e.target.name === 'password1') {
      if (e.target.value.length >= 8) {
        this.setState({ eightChar: true });
      } else {
        this.setState({ eightChar: false });
      }

      // password1 uppercase test
      if (/([A-Z]+)/g.test(e.target.value)) {
        this.setState({ upperCase: true });
      } else {
        this.setState({ upperCase: false });
      }

      // password1 special character test
      if (/([`~!@#$%^&*()_|+\-=?;:'',.<>\]\\]+)/g.test(e.target.value)) {
        this.setState({ specChar: true });
      } else {
        this.setState({ specChar: false });
      }

      // password1 has number
      if (/([0-9]+)/g.test(e.target.value)) {
        this.setState({ number: true });
      } else {
        this.setState({ number: false });
      }
    }

    // check if the passwords match
    if (e.target.name === 'password2') {
      this.setState({ touched2: true });
      if (e.target.value === this.state.password1) {
        this.setState({ match: true });
      } else {
        this.setState({ match: false });
      }
    }
  }

  // Where the user input is sent over to the action to be validated by server
  handleSubmit(e) {
    e.preventDefault();
    this.props.setPassword(this.state, this.props.match.params);
  }

  // The main body to be rendered
  render() {
    const { permitRender } = this.props;
    const {
      eightChar,
      match,
      number,
      password1,
      password2,
      specChar,
      touched,
      touched2,
      upperCase,
    } = this.state;

    if (permitRender) {
      return (
        <>
          <div className='reset-password'>
            <Form onSubmit={this.handleSubmit}>
              <Card className='custom-field-spacing set-password'>
                <p
                  style={{
                    fontSize: '18px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  The new password must meet the following criteria:
                </p>
                <ul
                  style={{
                    fontSize: '18px !important',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  <li
                    className={
                      touched && eightChar
                        ? 'success'
                        : touched && !eightChar
                        ? 'error'
                        : 'neutral'
                    }
                    style={{ fontSize: '18px', color: '#151b2C' }}
                  >
                    <i
                      className={
                        touched && eightChar
                          ? 'fas fa-check'
                          : touched && !eightChar
                          ? 'fas fa-times'
                          : 'fas fa-minus'
                      }
                      style={{ fontSize: '18px' }}
                    ></i>{' '}
                    At least eight characters in length
                  </li>
                  <li
                    className={
                      touched && upperCase
                        ? 'success'
                        : touched && !upperCase
                        ? 'error'
                        : 'neutral'
                    }
                    style={{ fontSize: '18px', color: '#151b2C' }}
                  >
                    <i
                      className={
                        touched && upperCase
                          ? 'fas fa-check'
                          : touched && !upperCase
                          ? 'fas fa-times'
                          : 'fas fa-minus'
                      }
                      style={{ fontSize: '18px' }}
                    ></i>{' '}
                    One uppercase character
                  </li>
                  <li
                    className={
                      touched && specChar
                        ? 'success'
                        : touched && !specChar
                        ? 'error'
                        : 'neutral'
                    }
                    style={{ fontSize: '18px', color: '#151b2C' }}
                  >
                    <i
                      className={
                        touched && specChar
                          ? 'fas fa-check'
                          : touched && !specChar
                          ? 'fas fa-times'
                          : 'fas fa-minus'
                      }
                      style={{ fontSize: '18px' }}
                    ></i>{' '}
                    One special character
                  </li>
                  <li
                    className={
                      touched && number
                        ? 'success'
                        : touched && !number
                        ? 'error'
                        : 'neutral'
                    }
                    style={{ fontSize: '18px', color: '#151b2C' }}
                  >
                    <i
                      className={
                        touched && number
                          ? 'fas fa-check'
                          : touched && !number
                          ? 'fas fa-times'
                          : 'fas fa-minus'
                      }
                      style={{ fontSize: '18px' }}
                    ></i>{' '}
                    One number
                  </li>
                  <li
                    className={
                      touched2 && match
                        ? 'success'
                        : touched2 && !match
                        ? 'error'
                        : 'neutral'
                    }
                    style={{ fontSize: '18px', color: '#151b2C' }}
                  >
                    <i
                      className={
                        touched2 && match
                          ? 'fas fa-check'
                          : touched2 && !match
                          ? 'fas fa-times'
                          : 'fas fa-minus'
                      }
                      style={{ fontSize: '18px' }}
                    ></i>{' '}
                    Passwords match
                  </li>
                </ul>

                <Row>
                  <Col>
                    <Input
                      value={this.state.password1}
                      onChange={this.handleChange}
                      type='password'
                      name='password1'
                      placeholder='Enter New Password'
                    />
                    <Input
                      disabled={
                        eightChar && number && specChar && upperCase
                          ? false
                          : true
                      }
                      value={this.state.password2}
                      onChange={this.handleChange}
                      type='password'
                      name='password2'
                      placeholder='Re-enter New Password'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {' '}
                    <Button
                      color='primary'
                      className='blue-button'
                      style={{ marginBottom: '25px' }}
                      type='submit'
                      disabled={
                        password1 &&
                        password2 &&
                        eightChar &&
                        number &&
                        specChar &&
                        upperCase &&
                        match
                          ? false
                          : true
                      }
                    >
                      Update Password
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </div>
          <Products />
        </>
      );
    } else if (!permitRender) {
      return <Loading />;
    }
  }
}

// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
function mapStateToProps(state) {
  return {
    permitRender: state.auth.permitRender,
  };
}

export default connect(mapStateToProps, { setPassword, verifyKey })(
  SetPassword
);
