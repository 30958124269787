import React, { Component } from 'react';
import { connect } from 'react-redux';

import { submitMessage } from '../../actions/contact';
import { getCSRF } from '../../actions/authentication';

import { Button, Form, FormGroup, Input, Row, Col } from 'reactstrap';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      category: '',
      comments: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeComments = this.handleChangeComments.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getCSRF();
  }

  componentDidMount() {
    if (this.props.hash === '#contact_form') {
      const id = this.props.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element.id === id) element.scrollIntoView();
      this.setState({ collapse: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hash === '#contact_form') {
      this.setState({ collapse: true });
    } else {
      this.setState({ collapse: false });
    }
  }

  handleChangeCategory(e) {
    this.setState({
      category: e.target.value,
    });
  }

  handleChangeComments(e) {
    this.setState({
      comments: e.target.value,
    });
  }

  handleSubmit(e) {
    const { name, email, category, comments } = e.target;
    const submission = {
      name: name.placeholder,
      email: email.placeholder,
      category: category.value,
      comments: comments.value,
    };
    e.preventDefault();
    this.props.submitMessage(submission, this.props.csrfToken);
    this.setState({
      comments: '',
      category: '',
    });
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
    e.preventDefault();
  }

  render() {
    const { category, comments } = this.state;
    const { user } = this.props;

    return (
      <div id='contact_form'>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Row>
              <Col md='5'>
                <h4>Contact Form</h4>
                <hr />
                <p>
                  If you need help managing the access of employees at your
                  company, please contact Laurie Krashanoff at The Croner
                  Company at 415-485-5521 or{' '}
                  <a href='mailto: laurie@croner.com'>laurie@croner.com </a>
                  for help.
                </p>
              </Col>
              <Col md='7'>
                <Input
                  name='name'
                  disabled={user[0]}
                  placeholder={user[0].first_name + ' ' + user[0].last_name}
                />
                <Input
                  name='email'
                  disabled={user[0]}
                  placeholder={user[0].email}
                />
                <Input
                  onChange={this.handleChangeCategory}
                  required
                  name='category'
                  type='select'
                  value={this.state.category}
                >
                  <option>Select option below...</option>
                  <option>Consulting Services</option>
                  <option>Survey Support</option>
                  <option>Technical Support</option>
                  <option>Website Feedback</option>
                </Input>
                <Input
                  onChange={this.handleChangeComments}
                  required
                  name='comments'
                  type='textarea'
                  rows='6'
                  value={this.state.comments}
                  placeholder='Message'
                />
                <Button
                  disabled={category && comments ? false : true}
                  type='submit'
                  className='red-button'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.contact.questions.user,
    csrfToken: state.auth.csrfToken,
  };
}

export default connect(mapStateToProps, { submitMessage, getCSRF })(
  ContactForm
);
