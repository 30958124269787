import _ from 'lodash';
import React, { Component } from 'react';

export default class BasicQueryTemplate extends Component {
  print(fieldStr, fieldObj) {
    // console.log('fieldStr: ' + fieldStr);
    // console.log('fieldObj: ' + fieldObj);
    // console.log(typeof fieldObj);
  }

  // render incumbent data points
  renderIncumbent(percentiles, cr, varName, divisor, symbol, digits) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the inc data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'inc');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'inc' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));
          // console.log(fieldStr);
          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);
          // see if variable is an n
          if (fieldStr[fieldStr.length - 1] === 'n' && fieldObj === null) {
            return <td key={i++} />;
          } else if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj >= 0 &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            // console.log('N');
            return <td key={i++}>{fieldObj.toLocaleString()}</td>;
          } else if (fieldObj >= 0) {
            // assign the data point to a const
            const percentile = (fieldObj / divisor).toLocaleString('en-US', {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            });
            // console.log(percentile);

            return (
              <td key={i++}>
                {fieldObj === null
                  ? ''
                  : symbol === ' kr' || symbol === ' zł'
                  ? percentile + symbol
                  : symbol + percentile}
              </td>
            );
          } else {
            // console.log('None');
            if (window.location.pathname === '/demo/query/') {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              return <td key={i++}> </td>;
            }
          }
        }
      )
    );
  }

  // render company data points
  renderCompany(percentiles, cr, varName, divisor, symbol, digits) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the co data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'co');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'co' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));
          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);

          // see if variable is an n
          if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj === null &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            return <td className='bq-left-border' key={i++} />;
          } else if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj >= 0 &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            return (
              <td className='bq-left-border' key={i++}>
                {fieldObj.toLocaleString()}
              </td>
            );
          } else if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            !fieldObj &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            return <td className='bq-left-border' key={i++} />;
          } else {
            // assign the data point to a const
            const percentile = (fieldObj / divisor).toLocaleString('en-US', {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            });
            if (window.location.pathname === '/demo/query/') {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? percentile + symbol
                    : symbol + percentile}
                </td>
              );
            }
          }
        }
      )
    );
  }

  // render incumbent data points
  renderIncumbentRev(percentiles, cr, varName, divisor, symbol, digits) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the inc data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'inc');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'inc' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));

          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);

          let percentile = '';

          // see if variable is an n
          if (fieldStr[fieldStr.length - 1] === 'n' && fieldObj === null) {
            // console.log('inc rev 1');
            return <td key={i++} />;
          } else if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj >= 0 &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            // console.log('inc rev 2');
            return <td key={i++}>{fieldObj.toLocaleString()}</td>;
          } else if (
            (fieldStr.includes('corprgu') ||
              fieldStr.includes('fieldrgu') ||
              fieldStr.includes('corpsub') ||
              fieldStr.includes('fieldsub') ||
              fieldStr.includes('corprev') ||
              fieldStr.includes('fieldrev') ||
              fieldStr.includes('digitalrev') ||
              fieldStr.includes('revlfy') ||
              fieldStr.includes('ussub')) &&
            (fieldStr.includes('median') || fieldStr.includes('mean'))
          ) {
            // console.log('inc rev 3');
            const percentile = (fieldObj / divisor).toLocaleString('en-US', {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,
            });
            if (window.location.pathname === '/demo/query/') {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? percentile + symbol
                    : symbol + percentile}
                </td>
              );
            }
          } else if (
            fieldObj >= 0 &&
            (fieldStr.indexOf('corprgu') ||
              fieldStr.indexOf('fieldrgu') ||
              fieldStr.indexOf('corpsub') ||
              fieldStr.indexOf('fieldsub') ||
              fieldStr.indexOf('corprev') ||
              fieldStr.indexOf('fieldrev') ||
              fieldStr.indexOf('digitalrev') ||
              fieldStr.indexOf('revlfy') ||
              fieldStr.indexOf('ussub'))
          ) {
            // console.log('inc rev 4');
            // assign the data point to a const
            if (
              fieldStr.includes('corprgu') ||
              fieldStr.includes('fieldrgu') ||
              fieldStr.includes('corpsub') ||
              fieldStr.includes('fieldsub') ||
              fieldStr.includes('corprev') ||
              fieldStr.includes('fieldrev') ||
              fieldStr.includes('digitalrev') ||
              fieldStr.includes('revlfy') ||
              fieldStr.includes('ussub')
            ) {
              // console.log('inc rev 5');
              percentile = '';
            } else {
              // console.log('inc rev 6');
              percentile = (fieldObj / divisor).toLocaleString('en-US', {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              });
            }

            return (
              <td key={i++}>
                {fieldObj === null
                  ? ''
                  : percentile === ''
                  ? percentile
                  : symbol === ' kr' || symbol === ' zł'
                  ? percentile + symbol
                  : symbol + percentile}
              </td>
            );
          } else {
            // console.log('inc rev 7');
            // console.log(fieldObj);
            if (window.location.pathname === '/demo/query/' && fieldObj) {
              // console.log('demo');
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : fieldObj === ''
                    ? fieldObj
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              return <td key={i++} />;
            }
          }
        }
      )
    );
  }

  // render company data points
  renderCompanyRev(percentiles, cr, varName, divisor, symbol, digits) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the co data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'co');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'co' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));

          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);

          let percentile = '';

          // see if variable is an n
          if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj === null &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            return <td className='bq-left-border' key={i++} />;
          } else if (
            fieldStr[fieldStr.length - 1] === 'n' &&
            fieldObj >= 0 &&
            !fieldStr.includes('mean') &&
            !fieldStr.includes('median')
          ) {
            return (
              <td className='bq-left-border' key={i++}>
                {fieldObj.toLocaleString()}
              </td>
            );
          } else if (
            (fieldStr.includes('corprgu') ||
              fieldStr.includes('fieldrgu') ||
              fieldStr.includes('corpsub') ||
              fieldStr.includes('fieldsub') ||
              fieldStr.includes('corprev') ||
              fieldStr.includes('fieldrev') ||
              fieldStr.includes('digitalrev') ||
              fieldStr.includes('revlfy') ||
              fieldStr.includes('ussub')) &&
            (fieldStr.includes('median') || fieldStr.includes('mean'))
          ) {
            if (window.location.pathname === '/demo/query/') {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              const percentile = (fieldObj / divisor).toLocaleString('en-US', {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              });
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : symbol === ' kr' || symbol === ' zł'
                    ? percentile + symbol
                    : symbol + percentile}
                </td>
              );
            }
          } else if (
            fieldObj >= 0 &&
            (fieldStr.indexOf('corprgu') ||
              fieldStr.indexOf('fieldrgu') ||
              fieldStr.indexOf('corpsub') ||
              fieldStr.indexOf('fieldsub') ||
              fieldStr.indexOf('corprev') ||
              fieldStr.indexOf('fieldrev') ||
              fieldStr.indexOf('digitalrev') ||
              fieldStr.indexOf('revlfy') ||
              fieldStr.indexOf('ussub'))
          ) {
            // assign the data point to a const
            if (
              fieldStr.includes('corprgu') ||
              fieldStr.includes('fieldrgu') ||
              fieldStr.includes('corpsub') ||
              fieldStr.includes('fieldsub') ||
              fieldStr.includes('corprev') ||
              fieldStr.includes('fieldrev') ||
              fieldStr.includes('digitalrev') ||
              fieldStr.includes('revlfy') ||
              fieldStr.includes('ussub')
            ) {
              percentile = '';
            } else {
              percentile = (fieldObj / divisor).toLocaleString('en-US', {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
              });
            }
            return (
              <td key={i++}>
                {fieldObj === null
                  ? ''
                  : percentile === ''
                  ? percentile
                  : symbol === ' kr' || symbol === ' zł'
                  ? percentile + symbol
                  : symbol + percentile}
              </td>
            );
          } else {
            // console.log('last');
            if (window.location.pathname === '/demo/query/' && fieldObj) {
              return (
                <td key={i++}>
                  {fieldObj === null
                    ? ''
                    : fieldObj === ''
                    ? fieldObj
                    : symbol === ' kr' || symbol === ' zł'
                    ? fieldObj + symbol
                    : symbol + fieldObj}
                </td>
              );
            } else {
              return <td key={i++} />;
            }
          }
        }
      )
    );
  }

  // render incumbent data points
  renderIncumbentPercentage(percentiles, cr, varName) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the inc data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'inc');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'inc' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));

          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);

          // see if variable is an n
          if (fieldObj || fieldObj === 0) {
            if (
              fieldStr[fieldStr.length - 1] === 'n' &&
              !fieldStr.includes('mean') &&
              !fieldStr.includes('median')
            ) {
              return <td key={i++}>{fieldObj.toLocaleString()}</td>;
            } else {
              // assign the data point to a const
              if (window.location.pathname === '/demo/query/') {
                return <td key={i++}>{fieldObj === null ? '' : '░.░░%'}</td>;
              } else {
                const percentile = (fieldObj * 100).toLocaleString('en-US', {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                });
                return (
                  <td key={i++}>{fieldObj === null ? '' : percentile + '%'}</td>
                );
              }
            }
          } else {
            return (
              <td
                key={i++}
                className={
                  varName.match('paired')
                    ? 'no-data data-point-width'
                    : 'data-point-width'
                }
              />
            );
          }
        }
      )
    );
  }

  // render company data points
  renderCompanyPercentage(percentiles, cr, varName) {
    // iterate for the key
    let i = 0;
    return (
      // parse out just the co data points
      _.map(
        _.pickBy(percentiles, function (value, key) {
          return _.startsWith(key, 'co');
        }),
        (p) => {
          // create a string for logic to be run on
          let fieldStr =
            varName +
            'co' +
            (p === 'n'
              ? 'n'
              : p === 50
              ? 'median'
              : p.toString().replace('.', '_'));

          // convert string to object
          let fieldObj = cr[fieldStr];

          this.print(fieldStr, fieldObj);

          // see if variable is an n
          if (fieldObj || fieldObj === 0) {
            if (
              fieldStr[fieldStr.length - 1] === 'n' &&
              !fieldStr.includes('mean') &&
              !fieldStr.includes('median')
            ) {
              if (
                (fieldStr === 'pairedtcccon' &&
                  cr.pairedcon < cr.pairedtcccon &&
                  cr.pairedtcccomean) ||
                (fieldStr === 'pairedtdccon' &&
                  cr.pairedcon < cr.pairedtdccon &&
                  cr.pairedtdccomean) ||
                (fieldStr === 'pairedtcccon' &&
                  cr.pairedtcccon < 5 &&
                  cr.pairedtcccomean)
              ) {
                return (
                  <td className='bq-left-border' key={i++}>
                    {cr.pairedcon}
                  </td>
                );
              } else if (
                fieldStr === 'pairedtdccon' &&
                cr.pairedtdccon < 5 &&
                cr.pairedtdccomean
              ) {
                return (
                  <td className='bq-left-border' key={i++}>
                    {cr.pairedtcccon < 5 ? 5 : cr.pairedtcccon}
                  </td>
                );
              } else {
                return (
                  <td className='bq-left-border' key={i++}>
                    {fieldObj}
                  </td>
                );
              }
            } else {
              // assign the data point to a const
              if (window.location.pathname === '/demo/query/') {
                return <td key={i++}>{fieldObj === null ? '' : '░.░░%'}</td>;
              } else {
                const percentile = (fieldObj * 100).toLocaleString('en-US', {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                });

                return (
                  <td key={i++}>{fieldObj === null ? '' : percentile + '%'}</td>
                );
              }
            }
          } else {
            if (
              fieldStr[fieldStr.length - 1] === 'n' &&
              !fieldStr.includes('mean') &&
              !fieldStr.includes('median')
            ) {
              return (
                <td className='bq-left-border data-point-wdith' key={i++} />
              );
            } else {
              return <td key={i++} />;
            }
          }
        }
      )
    );
  }

  render() {
    const { cr, hourly, i, p, mso_sales } = this.props;

    let currency = '';

    if (cr.country) {
      if (cr.country === 'United Kingdom') {
        currency = '£';
      } else if (cr.country === 'China') {
        currency = '¥';
      } else if (cr.country === 'Australia') {
        currency = '$';
      } else if (cr.country === 'India') {
        currency = '₹';
      } else if (cr.country === 'Singapore') {
        currency = '$';
      } else if (cr.country === 'South Korea') {
        currency = '₩';
      } else if (cr.country === 'Sweden') {
        currency = ' kr';
      } else if (cr.country === 'Japan') {
        currency = '¥';
      } else if (cr.country === 'Poland') {
        currency = ' zł';
      } else if (cr.country === 'Brazil') {
        currency = 'R$ ';
      } else if (
        cr.country === 'Spain' ||
        cr.country === 'Germany' ||
        cr.country === 'France' ||
        cr.country === 'Finland' ||
        cr.country === 'Ireland' ||
        cr.country === 'Netherlands' ||
        cr.country === 'Italy'
      ) {
        currency = '€';
      } else {
        currency = '$';
      }
    } else if (cr.surveydataview === 'International') {
      currency = '';
    } else {
      currency = '$';
    }

    return (
      <tbody key={i}>
        {/*CUT NAME*/}
        <tr>
          <td
            className='table-section-heading'
            colSpan={
              cr.surveycode.startsWith('F_') ||
              cr.surveycode.startsWith('E_') ||
              cr.surveycode.startsWith('S_') ||
              cr.surveycode.startsWith('P_')
                ? 14
                : 13
            }
          >
            {cr.rptcutcat === 'All Incumbents' && cr.rptcut === 'All Incumbents'
              ? 'All Incumbents'
              : cr.surveycode.startsWith('A_')
              ? cr.rptcut
              : cr.rptcutcat + ' - ' + cr.rptcut}
          </td>
        </tr>

        {/*BASE SALARY*/}
        {hourly ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Base Salary
            </td>
            {this.renderIncumbent(p, cr, 'base', 1, currency, 2)}
            {this.renderCompany(p, cr, 'base', 1, currency, 2)}
          </tr>
        ) : (
          <tr
            className={
              cr.surveycode === 'A_CAN_19' ||
              cr.surveycode === 'A_CAN_21' ||
              cr.surveycode === 'E_INTL_22'
                ? 'bq-bottom-border'
                : ''
            }
          >
            <td className='bq-column-border' colSpan='3'>
              Base Salary
            </td>
            {this.renderIncumbent(p, cr, 'base', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'base', 1000, currency, 1)}
          </tr>
        )}

        {/*PAIRED COMPARISON - BASE SALARY*/}
        {cr.surveycode !== 'A_CAN_19' &&
        cr.surveycode !== 'A_CAN_21' &&
        cr.surveycode !== 'E_INTL_22'
        ? (
          !cr.surveycode.startsWith('A_') ? (
            <tr className='bq-bottom-border'>
              <td className='bq-column-border' colSpan='3'>
                Paired Comparison Base Salary
              </td>
              {this.renderIncumbentPercentage(p, cr, 'paired')}
              {this.renderCompanyPercentage(p, cr, 'paired')}
            </tr>
          ) : (
            <tr
              className={
                cr.surveycode === 'A_CAN_19' ||
                cr.surveycode === 'A_CAN_21' ||
                cr.surveycode === 'A_CAN_22' ||
                cr.surveycode === 'A_CAN_23' ||
                cr.surveycode === 'E_INTL_22' 
                  ? 'bq-bottom-border'
                  : ''
              }
            >
              <td className='bq-column-border' colSpan='3'>
                Paired Comparison Base Salary
              </td>
              {this.renderIncumbentPercentage(p, cr, 'paired')}
              {this.renderCompanyPercentage(p, cr, 'paired')}
            </tr>
          )
        ) : null}

        {/*ESTIMATED WEEKLY OT HOURS*/}
        {cr.surveycode.startsWith('A_') && !cr.surveycode.includes('_CAN_') ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Estimated Weekly OT Hours
            </td>
            {this.renderIncumbent(p, cr, 'stdhrs', 1, '', 1)}
            {this.renderCompany(p, cr, 'stdhrs', 1, '', 1)}
          </tr>
        ) : null}

        {/*BASE SALARY + ANNUAL OT*/}
        {cr.surveycode.startsWith('A_') && !cr.surveycode.includes('_CAN_') ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Base Salary + Annual OT
            </td>
            {this.renderIncumbent(p, cr, 'baseinclot', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'baseinclot', 1000, currency, 1)}
          </tr>
        ) : null}

        {/*TARGET TOTAL CASH COMPENSATION*/}
        {!cr.surveycode.startsWith('F_') ? (
          hourly ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Target Total Cash Compensation
              </td>
              {this.renderIncumbent(p, cr, 'tgttcc', 1, currency, 2)}
              {this.renderCompany(p, cr, 'tgttcc', 1, currency, 2)}
            </tr>
          ) : (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Target Total Cash Compensation
              </td>
              {this.renderIncumbent(p, cr, 'tgttcc', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'tgttcc', 1000, currency, 1)}
            </tr>
          )
        ) : null}

        {/*CASH INCENTIVES / BONUSES*/}
        {cr.surveycode.startsWith('F_') ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Cash Incentives / Bonuses
            </td>
            {this.renderIncumbent(p, cr, 'addlcash', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'addlcash', 1000, currency, 1)}
          </tr>
        ) : null}

        {/*ACTUAL TOTAL CASH COMPENSATION*/}
        {hourly ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Actual Total Cash Compensation
            </td>
            {this.renderIncumbent(p, cr, 'tcc', 1, currency, 2)}
            {this.renderCompany(p, cr, 'tcc', 1, currency, 2)}
          </tr>
        ) : (
          <tr>
            <td className={'bq-column-border'} colSpan='3'>
              {cr.surveycode.startsWith('F_')
                ? 'Annual Total Cash'
                : 'Actual Total Cash Compensation'}
            </td>
            {this.renderIncumbent(p, cr, 'tcc', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'tcc', 1000, currency, 1)}
          </tr>
        )}

        {/*PAIRED COMPARISON - TCC*/}
        {cr.surveycode !== 'A_CAN_19' &&
        cr.surveycode !== 'A_CAN_21' &&
        cr.surveycode !== 'E_INTL_22' 
        ? (
          hourly ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Paired Comparison Actual Total Cash Compensation
              </td>
              {this.renderIncumbentPercentage(p, cr, 'pairedtcc')}
              {this.renderCompanyPercentage(p, cr, 'pairedtcc')}
            </tr>
          ) : (
            <tr
              className={
                cr.surveycode === 'A_CAN_19' ||
                cr.surveycode === 'A_CAN_21' ||
                cr.surveycode === 'E_INTL_22' ||
                cr.surveycode.startsWith('F_') ||
                (mso_sales && !cr.rptcut.includes('All Incumbents'))
                  ? 'bq-bottom-border'
                  : ''
              }
            >
              <td className='bq-column-border' colSpan='3'>
                {cr.surveycode.startsWith('F_')
                  ? 'Paired Comparison Annual Total Cash Compensation'
                  : 'Paired Comparison Actual Total Cash Compensation'}
              </td>
              {this.renderIncumbentPercentage(p, cr, 'pairedtcc')}
              {this.renderCompanyPercentage(p, cr, 'pairedtcc')}
            </tr>
          )
        ) : null}

        {/*ESTIMATED TOTAL COMPENSATION*/}
        {cr.surveycode.startsWith('F_') ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Est. Total Compensation
            </td>
            {this.renderIncumbent(p, cr, 'esttcc', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'esttcc', 1000, currency, 1)}
          </tr>
        ) : null}

        {/*ACTUAL TOTAL CASH COMPENSATION + ANNUAL OT*/}
        {cr.surveycode.startsWith('A_') && !cr.surveycode.includes('_CAN_') ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Actual Total Cash Compensation + Annual OT
            </td>
            {this.renderIncumbent(p, cr, 'tccinclot', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'tccinclot', 1000, currency, 1)}
          </tr>
        ) : null}

        {/*ACTUAL CASH AWARDS AS % OF BASE SALARY*/}
        {!cr.surveycode.startsWith('F_') ? (
          mso_sales && !cr.rptcut.includes('All Incumbents') ? null : (
            <tr className='bq-bottom-border'>
              <td className='bq-column-border' colSpan='3'>
                Actual Cash Awards as % of Base Salary
              </td>
              {this.renderIncumbentPercentage(p, cr, 'pctaddl')}
              {this.renderCompanyPercentage(p, cr, 'pctaddl')}
            </tr>
          )
        ) : null}

        {/*TARGET LTI AWARDS*/}
        {!cr.surveycode.startsWith('F_') ? (
          !hourly && !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Annualized Target LTI Awards
              </td>
              {this.renderIncumbent(p, cr, 'ltitgtvalue', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'ltitgtvalue', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/*ANNUALIZED VALUE OF LTI AWARDS*/}
        {!cr.surveycode.startsWith('F_') ? (
          !hourly && !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Annualized Value of LTI Awards
              </td>
              {this.renderIncumbent(p, cr, 'ltaddl', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'ltaddl', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/*TARGET TOTAL DIRECT COMPENSATION*/}
        {!cr.surveycode.startsWith('F_') &&
        !cr.surveycode.startsWith('D_16') &&
        !cr.surveycode.startsWith('_15') ? (
          !cr.surveycode.startsWith('A_') &&
          !hourly &&
          !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Target Total Direct Compensation
              </td>
              {this.renderIncumbent(p, cr, 'tgttdc', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'tgttdc', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/*ACTUAL TOTAL DIRECT COMPENSATION*/}
        {!cr.surveycode.startsWith('F_') ? (
          !hourly && !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr
              className={
                cr.surveycode === 'A_CAN_19' || cr.surveycode === 'A_CAN_21'
                  ? 'bq-bottom-border'
                  : ''
              }
            >
              <td className='bq-column-border' colSpan='3'>
                Actual Total Direct Compensation
              </td>
              {this.renderIncumbent(p, cr, 'tdc', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'tdc', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/* PAIRED COMPARISON - EST. TOTAL COMPENSATION */}
        {cr.surveycode.startsWith('F_') ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Paired Compairson Est. Total Compensation
            </td>
            {this.renderIncumbentPercentage(p, cr, 'pairedesttcc')}
            {this.renderCompanyPercentage(p, cr, 'pairedesttcc')}
          </tr>
        ) : null}

        {/*PAIRED COMPARISON - TDC*/}
        {cr.surveycode !== 'A_CAN_19' &&
        cr.surveycode !== 'A_CAN_21' &&
        cr.surveycode !== 'E_INTL_22' 
        ? (
          !cr.surveycode.startsWith('F_') ? (
            hourly ||
            (mso_sales && !cr.rptcut.includes('All Incumbents')) ? null : (
              <tr
                className={
                  (mso_sales && cr.rptcut.includes('All Incumbents')) ||
                  cr.surveycode.startsWith('A_CAN')
                    ? 'bq-bottom-border'
                    : ''
                }
              >
                <td className='bq-column-border' colSpan='3'>
                  Paired Comparison Actual Total Direct Compensation
                </td>
                {this.renderIncumbentPercentage(p, cr, 'pairedtdc')}
                {this.renderCompanyPercentage(p, cr, 'pairedtdc')}
              </tr>
            )
          ) : null
        ) : null}

        {/*ACTUAL TOTAL DIRECT COMPENSATION + ANNUAL OT*/}
        {cr.surveycode.startsWith('A_') && !cr.surveycode.includes('_CAN_') ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Actual Total Direct Compensation + Annual OT
            </td>
            {this.renderIncumbent(p, cr, 'tdcinclot', 1000, currency, 1)}
            {this.renderCompany(p, cr, 'tdcinclot', 1000, currency, 1)}
          </tr>
        ) : null}

        {/*LTI as % of Base Salary*/}
        {!cr.surveycode.startsWith('F_') ? (
          !cr.surveycode.startsWith('A_') && !hourly && !mso_sales ? (
            <tr className='bq-bottom-border'>
              <td className='bq-column-border' colSpan='3'>
                LTI as % of Base Salary
              </td>
              {this.renderIncumbentPercentage(p, cr, 'ltipctbase')}
              {this.renderCompanyPercentage(p, cr, 'ltipctbase')}
            </tr>
          ) : null
        ) : null}

        {/*ANNUALIZED VALUE OF LTI AWARDS - ONGOING*/}
        {!cr.surveycode.startsWith('F_') ? (
          !cr.surveycode.startsWith('A_') &&
          !cr.surveycode.startsWith('L_') &&
          !hourly &&
          !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr>
              <td className='bq-column-border' colSpan='3'>
                Annualized Value of LTI Awards - Ongoing
              </td>
              {this.renderIncumbent(p, cr, 'ltaddlong', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'ltaddlong', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/*ACTUAL TOTAL DIRECT COMPENSATION - ONGOING*/}
        {!cr.surveycode.startsWith('F_') ? (
          !cr.surveycode.startsWith('A_') &&
          !cr.surveycode.startsWith('L_') &&
          !hourly &&
          !(mso_sales && !cr.rptcut.includes('All Incumbents')) ? (
            <tr className={mso_sales ? 'bq-bottom-border' : ''}>
              <td className='bq-column-border' colSpan='3'>
                Actual Total Direct Compensation - Ongoing
              </td>
              {this.renderIncumbent(p, cr, 'tdcong', 1000, currency, 1)}
              {this.renderCompany(p, cr, 'tdcong', 1000, currency, 1)}
            </tr>
          ) : null
        ) : null}

        {/*LTI AS % OF BASE SALARY - ONGOING*/}
        {!cr.surveycode.startsWith('F_') ? (
          !cr.surveycode.startsWith('A_') &&
          !cr.surveycode.startsWith('L_') &&
          !hourly &&
          !mso_sales ? (
            <tr className='bq-bottom-border'>
              <td className='bq-column-border' colSpan='3'>
                LTI as % of Base Salary - Ongoing
              </td>
              {this.renderIncumbentPercentage(p, cr, 'ltipctbaseong')}
              {this.renderCompanyPercentage(p, cr, 'ltipctbaseong')}
            </tr>
          ) : null
        ) : null}

        {/*DCT MISC CATEGORIES*/}
        {cr.surveycode.startsWith('D_') &&
        (cr.finalcode === 36 ||
          (cr.finalcode >= 80010.5 && cr.finalcode <= 82528)) ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Team Target (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : cr.surveycode.startsWith('D_') &&
          (cr.finalcode < 98012 || cr.finalcode > 99999) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Digital Revenue (in $MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'digitalrev', 1000000, currency, 1)}
            {this.renderCompanyRev(p, cr, 'digitalrev', 1000000, currency, 1)}
          </tr>
        ) : null}

        {cr.surveycode.startsWith('D_') &&
        (cr.finalcode === 36 ||
          (cr.finalcode >= 80010.5 && cr.finalcode <= 82528)) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Indiv Target (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {/*ENT MISC CATEGORIES*/}
        {cr.surveycode.startsWith('E_') &&
        ((cr.finalcode >= 130000 && cr.finalcode <= 130028) ||
          (cr.finalcode >= 230000 && cr.finalcode <= 234025) ||
          (cr.finalcode >= 430000 && cr.finalcode <= 433516) ||
          (cr.finalcode >= 530000 && cr.finalcode <= 530016) ||
          (cr.finalcode >= 30010 && cr.finalcode <= 33528) ||
          cr.finalcode === 3005 ||
          cr.finalcode === 1300500 ||
          cr.finalcode === 2300500 ||
          cr.finalcode === 5300500) ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Team Target (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {cr.surveycode.startsWith('E_') &&
        ((cr.finalcode >= 130000 && cr.finalcode <= 130028) ||
          (cr.finalcode >= 230000 && cr.finalcode <= 234025) ||
          (cr.finalcode >= 430000 && cr.finalcode <= 433516) ||
          (cr.finalcode >= 530000 && cr.finalcode <= 530016) ||
          (cr.finalcode >= 30010 && cr.finalcode <= 33528) ||
          cr.finalcode === 3005 ||
          cr.finalcode === 1300500 ||
          cr.finalcode === 2300500 ||
          cr.finalcode === 5300500) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Indiv Target (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {/*LMS MISC CATEGORIES*/}
        {(cr.surveycode === 'L_22' || cr.surveycode === 'L_23') &&
        cr.finalcode < 13000 ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Target (in $MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'revactlfy', 1000000, currency, 1)}
            {this.renderCompanyRev(p, cr, 'revactlfy', 1000000, currency, 1)}
          </tr>
        ) : (cr.surveycode === 'L_19' ||
            cr.surveycode === 'L_20' ||
            cr.surveycode === 'L_21') &&
          cr.finalcode <= 15027 ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Target (in $MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'revactlfy', 1000000, currency, 1)}
            {this.renderCompanyRev(p, cr, 'revactlfy', 1000000, currency, 1)}
          </tr>
        ) : null}

        {(cr.surveycode === 'L_22' || cr.surveycode === 'L_23') &&
        cr.finalcode < 13000 ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Target Total Cash Compensation as % of Target Revenue
            </td>
            {this.renderIncumbentPercentage(
              p,
              cr,
              'tgttccpcttgtrev',
              1000000,
              currency
            )}
            {this.renderCompanyPercentage(
              p,
              cr,
              'tgttccpcttgtrev',
              1000000,
              currency
            )}
          </tr>
        ) : (cr.surveycode === 'L_19' ||
            cr.surveycode === 'L_20' ||
            cr.surveycode === 'L_21') &&
          cr.finalcode <= 15027 ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Target Total Cash Compensation as % of Target Revenue
            </td>
            {this.renderIncumbentPercentage(
              p,
              cr,
              'tgttccpcttgtrev',
              1000000,
              currency
            )}
            {this.renderCompanyPercentage(
              p,
              cr,
              'tgttccpcttgtrev',
              1000000,
              currency
            )}
          </tr>
        ) : null}

        {/*MSO MISC CATEGORIES*/}
        {cr.surveycode.startsWith('M_') &&
        !mso_sales &&
        ((cr.finalcode >= 11 && cr.finalcode <= 12) ||
          Number(cr.finalcode.toString().slice(-2)) <= 12) ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              {cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'Field Revenues (in $MM)'
                : 'Corporate Revenues (in $MM)'}
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldrev'
                : 'corprev',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldrev'
                : 'corprev',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {cr.surveycode.startsWith('M_') &&
        !mso_sales &&
        ((cr.finalcode >= 11 && cr.finalcode <= 12) ||
          Number(cr.finalcode.toString().slice(-2)) <= 12) ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              {cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'Field Subscribers'
                : 'Corporate Subscribers'}
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldsub'
                : 'corpsub',
              1000,
              '',
              0
            )}
            {this.renderCompanyRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldsub'
                : 'corpsub',
              1000,
              '',
              0
            )}
          </tr>
        ) : null}

        {cr.surveycode.startsWith('M_') &&
        !mso_sales &&
        ((cr.finalcode >= 11 && cr.finalcode <= 12) ||
          Number(cr.finalcode.toString().slice(-2)) <= 12) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              {cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'Field Revenue Generating Units (RGUs)'
                : 'Corporate Revenue Generating Units (RGUs)'}
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldrgu'
                : 'corprgu',
              1000,
              '',
              0
            )}
            {this.renderCompanyRev(
              p,
              cr,
              cr.rptcut.includes('Field') || cr.surveycode === 'M_15'
                ? 'fieldrgu'
                : 'corprgu',
              1000,
              '',
              0
            )}
          </tr>
        ) : null}

        {/*PRG MISC CATEGORIES*/}
        {(cr.surveycode.startsWith('P_') &&
          (cr.finalcode < 70000 || cr.finalcode > 76100) &&
          ((Number(cr.finalcode.toString().slice(-2)) >= 10 &&
            Number(cr.finalcode.toString().slice(-2)) <= 12) ||
            (cr.finalcode >= 1 && cr.finalcode <= 28))) ||
        (cr.surveycode.startsWith('P_') &&
          cr.finalcode >= 1000000 &&
          cr.finalcode <= 1200000) ? (
          <tr
            className={
              cr.surveycode.startsWith('P_22') ? 'bq-bottom-border' : 'none'
            }
          >
            <td className='bq-column-border' colSpan='3'>
              Revenues (in $MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'revlfy', 1000000, currency, 1)}
            {this.renderCompanyRev(p, cr, 'revlfy', 1000000, currency, 1)}
          </tr>
        ) : null}

        {/* {(cr.surveycode.startsWith('P_') &&
          !cr.surveycode.endsWith('22') &&
          (cr.finalcode < 70000 || cr.finalcode > 76100) &&
          ((Number(cr.finalcode.toString().slice(-2)) >= 10 &&
            Number(cr.finalcode.toString().slice(-2)) <= 12) ||
            (cr.finalcode >= 1 && cr.finalcode <= 28))) ||
        (cr.surveycode.startsWith('P_') &&
          !cr.surveycode.endsWith('22') &&
          cr.finalcode >= 1000000 &&
          cr.finalcode <= 1200000) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Cumulative Basic Subscribers (in MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'ussub', 1000000, '', 0)}
            {this.renderCompanyRev(p, cr, 'ussub', 1000000, '', 0)}
          </tr>
        ) : null} */}

        {cr.surveycode.startsWith('P_') &&
        cr.finalcode >= 70000 &&
        cr.finalcode <= 76100 ? (
          <tr>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Team Targets (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtteamcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {cr.surveycode.startsWith('P_') &&
        cr.finalcode >= 70000 &&
        cr.finalcode <= 76100 ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Annual Revenue Indiv Targets (in $MM)
            </td>
            {this.renderIncumbentRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
            {this.renderCompanyRev(
              p,
              cr,
              'revtgtindvcfy',
              1000000,
              currency,
              1
            )}
          </tr>
        ) : null}

        {/*SWG MISC CATEGORIES*/}
        {cr.surveycode.startsWith('S_') &&
        cr.country === 'United States' &&
        ((cr.finalcode >= 25 && cr.finalcode <= 99) ||
          (cr.finalcode >= 100 && cr.finalcode <= 120) ||
          Number(cr.finalcode.toString().slice(-2)) <= 12) ? (
          <tr className='bq-bottom-border'>
            <td className='bq-column-border' colSpan='3'>
              Revenues (in $MM)
            </td>
            {this.renderIncumbentRev(p, cr, 'revlfy', 1000000, currency, 1)}
            {this.renderCompanyRev(p, cr, 'revlfy', 1000000, currency, 1)}
          </tr>
        ) : null}
      </tbody>
    );
  }
}
