import { 
  SESSIONS, 
  USER_INFO 
} from '../actions/accountSettings';

export default function (state = {}, action) {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        user: action.payload,
      };
    case SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      };
    default:
      return state;
  }
  // return state;
}
