// Styles for /home
import './Home.scss';

// Global React-Redux imports
import React, { Component } from 'react';
import { connect } from 'react-redux';


// Containers related to this component
import Loading from '../../components/Global/Loading';

import SurveyList from './SurveyList';
// import Welcome from '../containers/home/welcome';

// Actions related to the this component
import { getHome } from '../../actions/home';

class Home extends Component {
  // Grab all of the data from the server related to this component
  UNSAFE_componentWillMount() {
    this.props.getHome();
  }

  render() {
    const { surveys } = this.props;

    if (!surveys) {
      return <Loading />;
    } else {
      return (
        <div id='home'>
          <div id='title'>
            <h1>WELCOME TO THE CRONER SURVEY PORTAL</h1>
          </div>
          {/* <Welcome /> */}
          {/* <hr id='survey_list' style={{ margin: '0 80px' }} /> */}
          <SurveyList />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    surveys: state.home.surveys,
  };
}

export default connect(mapStateToProps, { getHome })(Home);
