import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';

import cc from './compensation-consulting.png';
import bas from './board-advisory-services.png';
import cs from './compensation-surveys.png';

export default class Products extends Component {
  render() {
    return (
      <div id='products'>
        <Row>
          <Col xl='4'>
            <img src={cc} height='90' alt='Compensation Consulting' />
            <h1>Compensation Consulting</h1>
            <h3>We take the complication out of compensation.</h3>
            <hr />
            <ul>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Customized, market-based pay programs</p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Job architecture creation</p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Career pathing and other retention tools</p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Custom studies of evolving pay practices</p>
                </div>
              </li>
            </ul>
            <a
              href='https://www.croner.com/compensation-consulting'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                type='button'
                className='red-button sm'
                style={{ marginTop: '20px', marginBottom: '50px', letterSpacing: '3px' }}
              >
                Consulting Services
              </button>
            </a>
          </Col>
          <Col xl='4'>
            <img src={bas} height='90' alt='Board Advisory Services' />
            <h1>Board Advisory Services</h1>
            <h3>You can't be a giant unless you're compliant.</h3>
            <hr />
            <ul>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Advising Boards of Directors for over four decades</p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>
                    Named compensation advisor for <em>Fortune 500</em>{' '}
                    organizations
                  </p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '60px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>
                    Advisors to start-ups, privately held corporations and
                    world-changing philanthropic organizations
                  </p>
                </div>
              </li>
            </ul>
            <a href='https://www.croner.com/board-advisory-services' target='_blank'
            rel='noopener noreferrer'>
              <button
                type='button'
                className='red-button sm'
                style={{ marginTop: '20px', marginBottom: '50px', letterSpacing: '3px' }}
              >
                Advisory Services
              </button>
            </a>
          </Col>
          <Col xl='4'>
            <img src={cs} height='90' alt='Compensation Surveys' />
            <h1>Compensation Surveys</h1>
            <h3>Nobody knows jobs like we know jobs.</h3>
            <hr />
            <ul>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>
                    Detailed benchmark compensation data for thousands of jobs
                  </p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>
                    Relevant benchmarking and data cuts for key industries and
                    sectors
                  </p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '5px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px' }}
                  ></i>
                </span>
                <div>
                  <p>Rigorously validated and reliable data</p>
                </div>
              </li>
              <li style={{ listStyle: 'none' }}>
                <span
                  style={{
                    float: 'left',
                    marginRight: '14px',
                    // marginBottom: '30px',
                    paddingTop: '2px',
                  }}
                >
                  <i
                    className='fa fa-check'
                    style={{ color: '#6d0f1d', fontSize: '18px'}}
                  ></i>
                </span>
                <div>
                  <p>
                    Thorough evaluation and reporting of emerging pay practices
                    and compensation trends
                  </p>
                </div>
              </li>
            </ul>
            <a
              href='https://www.croner.com/compensation-surveys'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                type='button'
                className='red-button sm'
                style={{ marginTop: '20px', letterSpacing: '3px'  }}
              >
                Compensation Surveys
              </button>
            </a>
          </Col>
        </Row>
        <Row id='product-buttons-lg'>
          <Col xl='4' style={{ textAlign: 'center', fontSize: '17px' }}>
            <a
              href='https://www.croner.com/compensation-consulting'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                type='button'
                className='red-button'
                style={{ letterSpacing: '3px' }}
              >
                Consulting Services
              </button>
            </a>
          </Col>
          <Col xl='4' style={{ textAlign: 'center', fontSize: '17px' }}>
            <a
              href='https://www.croner.com/board-advisory-services'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                type='button'
                className='red-button'
                style={{ letterSpacing: '3px' }}
              >
                Advisory Services
              </button>
            </a>
          </Col>
          <Col xl='4' style={{ textAlign: 'center', fontSize: '17px' }}>
            <a
              href='https://www.croner.com/compensation-surveys'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                type='button'
                className='red-button'
                style={{ letterSpacing: '3px' }}
              >
                Compensation Surveys
              </button>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}
