import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearStatus, submitDocuments } from '../../actions/documents';
import { getCSRF } from '../../actions/authentication';
import Dropzone from 'react-dropzone';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

class SubmitDocuments extends Component {
  constructor() {
    super();
    this.state = {
      collapse: false,
      filesNotToBeSent: [],
      filesToBeSent: [],
      filesAccepted: [],
      filesInfected: [],
      filesRejected: [],
      modal: true,
      preview: [],
      rejectedFilenames: [],
      disabled: true
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeInfectedFiles = this.removeInfectedFiles.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getCSRF();
  }

  sanitizeFilename(filename) {
    // Replace any character that is not a letter, number, dot, or underscore
    return filename.replace(/[^a-zA-Z0-9.\-_ ]/g, '_');
  }

  handleClick(event) {
    this.props.clearStatus();
    this.setState({
      filesNotToBeSent: [],
      filesToBeSent: [],
      filesAccepted: [],
      filesInfected: [],
      filesRejected: [],
      modal: true,
      rejectedFilenames: [],
      disabled: true
    });
  }

  handleSubmit(event) {
    const { submitDocuments } = this.props;

    const { filesToBeSent, rejectedFilenames } = this.state;

    this.setState({
      disabled: true
    })

    event.preventDefault();
    submitDocuments(
      filesToBeSent,
      rejectedFilenames,
      sessionStorage.getItem('survey_id'),
      this.props.csrfToken
    );
    // this.setState({ disabled: true});
  }

  onDrop(files) {
    // Sanitize filenames of accepted files
    const sanitizedAcceptedFiles = files.accepted.map(file => {
      const sanitizedFilename = this.sanitizeFilename(file.name);
      return new File([file], sanitizedFilename, { type: file.type });
    });

    // handle files that have an accepted format
    let filesToBeSent = this.state.filesToBeSent.concat(sanitizedAcceptedFiles);
    filesToBeSent = _.uniqBy(filesToBeSent, 'name');

    // handle files that were not an accepted format
    let filesNotToBeSent = this.state.filesNotToBeSent.concat(files.rejected);
    filesNotToBeSent = _.uniqBy(filesNotToBeSent, 'name');

    let filesAccepted = [];

    // iterate through the files in an accepted format for rendering and sending
    _.map(filesToBeSent, (i) => {
      let fileSplit = i.name.split('.');
      let fileExt = fileSplit[fileSplit.length - 1];

      /* eslint-disable no-unused-vars */
      let fileType;
      /* eslint-enable no-unused-vars */

      if (fileExt === 'pdf') {
        fileType = <i className='far fa-file-pdf'></i>;
      } else if (fileExt === 'xlsx' || fileExt === 'xls') {
        fileType = <i className='far fa-file-excel'></i>;
      } else if (fileExt === 'doc' || fileExt === 'docx') {
        fileType = <i className='far fa-file-alt'></i>;
      } else if (fileExt === 'ppt' || fileExt === 'pptx') {
        fileType = <i className='far fa-file-powerpoint'></i>;
      } else if (fileExt === 'zip') {
        fileType = <i className='far fa-file-archive'></i>;
      } else {
        fileType = <i className='far fa-file-alt'></i>;
      }

      filesAccepted.unshift(
        <p key={i.name}>
          {i.fileType} {i.name}
        </p>
      );
    });

    let filesRejected = [];
    let rejectedFilenames = [];

    // iterate through the files that were rejected rendering
    _.map(filesNotToBeSent, (r) => {
      rejectedFilenames.unshift(r.name);
      filesRejected.unshift(<p key={r.name}>{r.name}</p>);
    });

    this.setState({
      filesToBeSent,
      filesAccepted,
      filesNotToBeSent,
      filesRejected,
      rejectedFilenames,
      disabled: filesAccepted.length < 1
    });
  }

  renderCurrentSurveyName() {
    return _(this.props.surveyList)
      .filter((c) => c.guid === this.props.survey_id)
      .map('name')
      .value();
  }

  renderCurrentSurveyYear() {
    return _(this.props.surveyList)
      .filter((c) => c.guid === this.props.survey_id)
      .map('year')
      .value();
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
    e.preventDefault();
  }

  toggleModal() {
    this.setState({
      modal: false,
    });
  }

  removeInfectedFiles() {
    let { filesAccepted } = this.state;
    const { infected } = this.props.upload.data;

    this.setState({
      ...this.state,
      filesAccepted: filesAccepted.filter(
        (file) => !infected.includes(file.key)
      ),
    });

    let filesInfected = [];

    _.map(infected, (i) => {
      filesInfected.unshift(<p key={i}>{i}</p>);
    });

    this.setState({
      filesInfected,
    });
  }

  render() {
    const {
      filesAccepted,
      filesInfected,
      filesRejected,
      disabled
    } = this.state;
    const { upload, survey_id } = this.props;

    return (
      <div id='submit_documents'>
        <Row>
          <Col lg='5'>
            <h4>Submit Documents</h4>
            <hr />
          </Col>
          <Col lg='7'>
            <form id='upload_area' onSubmit={this.handleSubmit}>
              {survey_id === 0 ? (
                <ul>
                  <li>Nothing at this time.</li>
                </ul>
              ) : (
                <div>
                  <Dropzone
                    accept='
                          image/jpeg, 
                          image/png,
                          image/gif,
                          application/pdf,
                          application/msword,
                          text/csv,
                          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                          application/vnd.ms-excel,
                          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                          application/vnd.ms-powerpoint,
                          application/vnd.openxmlformats-officedocument.presentationml.presentation,
                          application/zip, 
                          application/octet-stream, 
                          application/x-zip-compressed, 
                          multipart/x-zip
                      '
                    className='dropzone'
                    onDrop={(accepted, rejected) => {
                      this.onDrop({ accepted, rejected });
                    }}
                  >
                    <i
                      className='fas fa-cloud-upload-alt'
                      style={{ textAlign: 'center' }}
                    ></i>
                    <h3>
                      Click to add files or drag files here to upload for{' '}
                      {this.renderCurrentSurveyName()}
                    </h3>
                    {(filesAccepted.length || filesRejected.length) < 1 ? (
                      ''
                    ) : (
                      <hr />
                    )}
                    {filesAccepted.length < 1 ? (
                      ''
                    ) : (
                      <div className='file-preview'>
                        <h4>Attached Documents</h4>
                        {filesAccepted}
                      </div>
                    )}
                    {filesRejected.length < 1 ? (
                      ''
                    ) : (
                      <div className='file-preview'>
                        <h4>Not an Accepted Format</h4>
                        {filesRejected}
                      </div>
                    )}
                  </Dropzone>
                  <Row>
                    <Col lg='6'>
                      <Button
                        type='submit'
                        disabled={disabled}
                        className='blue-button'
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col lg='6'>
                      <Button
                        type='button'
                        disabled={disabled}
                        className='red-button'
                        onClick={this.handleClick}
                      >
                        <i className='far fa-trash-alt'></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </form>
            {upload ? (
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                onOpened={this.removeInfectedFiles}
                onClosed={this.handleClick}
                id='upload-modal'
              >
                <ModalBody>
                  {filesAccepted.length > 0 ? (
                    <div className='files-accepted'>
                      <i className='fas fa-check-circle'></i>
                      <p>
                        <b>Successfully uploaded:</b>
                      </p>
                      {filesAccepted}
                    </div>
                  ) : null}
                  {filesRejected.length > 0 ? (
                    <div className='files-rejected'>
                      <i className='fas fa-exclamation-triangle'></i>
                      <p>
                        <b>Files not in an accepted format:</b>
                      </p>
                      {filesRejected}
                    </div>
                  ) : null}
                  {upload.data.infected.length > 0 ? (
                    <div className='files-virus'>
                      <i className='fas fa-times-circle'></i>
                      <p>
                        <b>Virus detected in the following files:</b>
                      </p>
                      {filesInfected}
                    </div>
                  ) : null}
                </ModalBody>
              </Modal>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    survey_id: state.documents.survey_id,
    upload: state.documents.upload,
    surveyList: state.documents.survey_list,
    csrfToken: state.auth.csrfToken,
  };
}

export default connect(mapStateToProps, {
  clearStatus,
  submitDocuments,
  getCSRF,
})(SubmitDocuments);
