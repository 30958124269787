import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearDocuments, downloadDocDocs } from '../../actions/documents';
import { clearMessages } from '../../actions/messages';
import { Col, Row } from 'reactstrap';

import downloading from './../Global/download.gif';

class Questionnaire extends Component {
  // constructor() {
  //   super();

  //   // Set initial state
  //   this.state = {
  //     visible: false
  //   };
  // }

  toggle = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };
  // handleButtonClick(e) {
  //   const id = this.props.survey_id; // Replace with your actual ID
  //   console.log(this.state)

  // };

  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearDocuments();
  }

  // handling click for download
  handleClick(e) {
    // window.location.hash = '#questionnaire';
    this.props.clearMessages();
    e.preventDefault();
    this.props.downloadDocDocs(
      sessionStorage.getItem('survey_id'),
      e.target.name
    );
  }

  handleLoading(e) {
    var element = document.getElementsByName(e.target.name)[0];
    var img = document.createElement('IMG');
    img.src = downloading;
    img.height = '20';
    img.id = 'downloading';
    element.appendChild(img);
  }

  // render the individual documents
  renderDocuments() {
    const { questionnaire } = this.props;

    // see if empty first and if not render the documents
    if (_.isEmpty(questionnaire)) {
      return <li>Nothing at this time.</li>;
    } else {
      return _.map(questionnaire, (pm) => {
        let fileSplit = pm.filename.split('.');
        let fileExt = fileSplit[fileSplit.length - 1];
        let fileType;

        if (fileExt === 'pdf') {
          fileType = <i className='far fa-file-pdf'></i>;
        } else if (fileExt === 'xlsx' || fileExt === 'xls') {
          fileType = <i className='far fa-file-excel'></i>;
        } else if (fileExt === 'doc' || fileExt === 'docx') {
          fileType = <i className='far fa-file-alt'></i>;
        } else if (fileExt === 'ppt' || fileExt === 'pptx') {
          fileType = <i className='far fa-file-powerpoint'></i>;
        } else if (fileExt === 'mp4') {
          fileType = <i className='fa fa-film'></i>;
        } else if (fileExt === 'txt') {
          fileType = <i className='far fa-file-alt'></i>;
        }

        return (
          <li key={pm.guid}>
            <div
              className={this.props.downloadStatus ? 'disable-anchor' : null}
            >
              <Link
                name={pm.guid}
                to={`/documents/${pm.guid}`}
                onClick={(e) => {
                  this.handleClick(e);
                  this.handleLoading(e);
                }}
              >
                {fileType} {pm.filename}
              </Link>
            </div>
          </li>
        );
      });
    }
  }

  // render the main element of the container
  render() {
    if (!this.props.downloadStatus && document.getElementById('downloading')) {
      var element = document.getElementById('downloading');
      element.remove();
    }
    console.log(this.props.questionnaire.length);
    // const {visible} = this.state;
    // const id = this.props.survey_id; // Replace with your actual ID
    return (
      <div id='questionnaire'>
        <Row>
          <Col lg='5'>
            <h4>Questionnaire</h4>
            <hr />
          </Col>
          <Col lg='7'>
            <ul>
              <>
                {this.renderDocuments()}
                {this.props.currentSurveyName === 'Croner CSF' &&
                this.props.questionnaire.length ? (
                  <li>
                    <a
                      href='https://www.croner.com/csf-questionnaire-completion-webinar'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-link'></i> Croner CSF Questionnaire Completion Webinars
                    </a>
                  </li>
                ) : this.props.questionnaire.length ? (
                  <li>
                    <a
                      href='https://www.croner.com/croner-questionnaire-completion-webinar'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-link'></i> Croner Questionnaire Completion Webinar
                    </a>
                  </li>
                ) : undefined}
              </>
            </ul>
          </Col>
        </Row>
        {/* <Modal isOpen={visible} toggle={this.toggle} fullscreen='xxl-down' >
        <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
        <ModalBody>
          <SurveyFormComponent survey_id={id}/>

    
        </ModalBody>
        <ModalFooter>
     
        </ModalFooter>
      </Modal> */}
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    questionnaire: state.documents.questionnaire,
    downloadStatus: state.documents.downloadStatus,
    currentSurveyName: state.documents.currentSurveyName,
  };
}

export default connect(mapStateToProps, {
  clearDocuments,
  clearMessages,
  downloadDocDocs,
})(Questionnaire);
