// import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

class FAQ extends Component {
  // Render the questions
  // renderQuestions() {
  //   const { faq } = this.props;

  //   return _.map(faq, (f) => {
  //     return (
  //       <div key={f.id}>
  //         <p>
  //           <b>{f.question}</b>
  //         </p>
  //         <p dangerouslySetInnerHTML={{ __html: f.answer }}></p>
  //       </div>
  //     );
  //   });
  // }

  // Render the component
  render() {
    return (
      <div id='faq'>
        <Row>
          <Col lg='5'>
            <h4>Frequently Asked Questions</h4>
            <hr style={{ height: '2px' }} />
          </Col>
          <Col lg='7'>
            <p>
              <strong>
                Can I share my website access or Survey results with others?{' '}
              </strong>
            </p>
            <p>
              The Croner Company guards and ensures the confidentiality of each
              sponsor's data as well as that of the Survey report. This Survey
              is entirely confidential and is distributed to Survey participants
              only. Survey reports, data, special analyses or results will not
              be sold or otherwise distributed to non-sponsoring participants by
              The Croner Company or any third party. New participants may obtain
              this report only by agreeing, in writing, to participate in the
              following year's Survey. All Survey participants are required to
              sign a statement that confirms that they will abide by the same
              confidentiality standards and not make copies, allow copies to be
              made, distribute electronic files, or otherwise reproduce or share
              any part of the Survey report results or data with
              non-participating organizations, entities or individuals.
            </p>
            <p>
              <strong>
                How can we add access for another person or remove access for an
                employee at our company?{' '}
              </strong>
            </p>
            <p>
              If you need help managing the access of employees at your company,
              please contact Laurie Krashanoff at The Croner Company at
              415-485-5521 or{' '}
              <a href='mailto:laurie@croner.com'>laurie@croner.com</a> for help.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    faq: state.contact.questions.faq,
  };
}

export default connect(mapStateToProps)(FAQ);
