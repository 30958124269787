import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

// imports for the basic query when selected
import BasicQueryCountryList from './Country';
// import BasicQueryCuts from "./basic_query/bq_cuts";
import BasicQueryPositionList from './PositionList';
// import BasicQueryResults from "./basic_query/bq_results";
import BasicQuerySearch from './Search';

// imports for the advanced query when selected
// .... these are to come
import { clearResults } from '../../../actions/results';

class Query extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   survey: '',
    // };
    this.renderCurrentSurveyName = this.renderCurrentSurveyName.bind(this);
  }

  componentWillUnmount() {
    this.props.clearResults();
  }

  renderCurrentSurveyName() {
    return _(this.props.survey_list)
      .filter((c) => c.guid === this.props.survey_id)
      .map('name')
      .value();
  }

  renderCurrentSurveyYear() {
    return _(this.props.survey_list)
      .filter((c) => c.guid === this.props.survey_id)
      .map('year')
      .value();
  }

  // render the main element of the container
  render() {
    const { pos_list } = this.props;

    return (
      <div id='query'>
        <Row>
          <Col lg='5'>
            {window.location.pathname === '/demo/query/' ? (
              <h4>SAMPLE POSITION DATA QUERY</h4>
            ) : (
              <h4>
                Query
                <br />
                {this.renderCurrentSurveyName()}
                <br />
                Results{' '}
              </h4>
            )}

            <hr style={{ height: '2px' }} />
          </Col>
          <Col lg='7'>
            {pos_list.length === 1 ? (
              <ul>
                <li>Not available at this time.</li>
              </ul>
            ) : (
              <div id='query-parameters'>
                {pos_list[0].country || pos_list[0].foundationlabel ? (
                  <BasicQueryCountryList />
                ) : null}
                <BasicQuerySearch />
                <BasicQueryPositionList />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    survey_id: state.results.survey_id,
    pos_list: state.results.pos_list,
    survey_list: state.results.survey_list,
  };
}

export default connect(mapStateToProps, { clearResults })(Query);
