import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import moment from 'moment';

import { Button, Col, Row, Table } from 'reactstrap';

import { openSections } from '../../actions/home';

class SurveyList extends Component {
  // Renders the list of surveys
  renderSurveys(surveys) {
    return _.map(surveys, (s) => {
      let pm_y,
        pm_m,
        pm_d,
        pm_dw,
        qd_y,
        qd_m,
        // qd_d,
        // qd_dw,
        de_y,
        de_m,
        de_d,
        // de_dw,
        qDue_y,
        qDue_m,
        qDue_d,
        // qDue_dw,
        fr_y,
        fr_m,
        // fr_d,
        // fr_dw,
        rw_y,
        rw_m =
          // rw_d,
          // rw_dw
          '';

      if (s.planning_meeting_date) {
        pm_y = moment(s.planning_meeting_date).format('YYYY');
        pm_m = moment(s.planning_meeting_date).format('MMMM');
        pm_d = moment(s.planning_meeting_date).format('Do');
        pm_dw = moment(s.planning_meeting_date).format('dddd');
      }

      if (s.questionnaire_distribution_date) {
        qd_y = moment(s.questionnaire_distribution_date).format('YYYY');
        qd_m = moment(s.questionnaire_distribution_date).format('MMMM');
        // qd_d = moment(s.questionnaire_distribution_date).format("Do");
        // qd_dw = moment(s.questionnaire_distribution_date).format("dddd");
      }

      if (s.data_effective_date) {
        de_y = moment(s.data_effective_date).format('YYYY');
        de_m = moment(s.data_effective_date).format('MMMM');
        de_d = moment(s.data_effective_date).format('Do');
        // de_dw = moment(s.data_effective_date).format("dddd");
      }

      if (s.questionnaire_due_date) {
        qDue_y = moment(s.questionnaire_due_date).format('YYYY');
        qDue_m = moment(s.questionnaire_due_date).format('MMMM');
        qDue_d = moment(s.questionnaire_due_date).format('Do');
        // qDue_dw = moment(s.questionnaire_due_date).format("dddd");
      }

      if (s.report_delivery_date) {
        fr_y = moment(s.report_delivery_date).format('YYYY');
        fr_m = moment(s.report_delivery_date).format('MMMM');
        // fr_d = moment(s.report_delivery_date).format("Do");
        // fr_dw = moment(s.report_delivery_date).format("dddd");
      }

      if (s.results_webinar_date) {
        rw_y = moment(s.results_webinar_date).format('YYYY');
        rw_m = moment(s.results_webinar_date).format('MMMM');
        // rw_d = moment(s.results_webinar_date).format("Do");
        // rw_dw = moment(s.results_webinar_date).format("dddd");
      }

      return (
        <Table key={s.guid} size='sm' bordered>
          <thead>
            <tr>
              <th colSpan='2'>
                <h3>{s.name}</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Row>
                  <Col
                    xxl='4'
                    style={{
                      padding: '0',
                      paddingRight: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    {/* <a href='/documents#submit_documents' target='_self'> */}
                      {' '}
                      <Link
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                        to='/documents#submit_documents'
                      >
                        <Button
                          className='red-button'
                          style={{ width: '100%' }}
                          onClick={(e) =>
                            sessionStorage.setItem('survey_id', s.guid)
                          }
                        >
                          Submit Documents
                        </Button>
                      </Link>
                    {/* </a> */}
                  </Col>
                  <Col
                    xxl='4'
                    style={{
                      padding: '0',
                      paddingRight: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    <a href='/documents' target='_self'>
                      <Button
                        className='red-button'
                        style={{ width: '100%' }}
                        onClick={(e) =>
                          sessionStorage.setItem('survey_id', s.guid)
                        }
                      >
                        Survey Materials
                      </Button>
                    </a>
                  </Col>

                  <Col
                    xxl='4'
                    style={{
                      padding: '0',
                      paddingRight: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    <a href='/results' target='_self'>
                      <Button
                        className='red-button'
                        style={{ width: '100%' }}
                        onClick={(e) =>
                          sessionStorage.setItem('survey_id', s.guid)
                        }
                        to={'/results'}
                      >
                        Survey Results
                      </Button>
                    </a>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                {!s.planning_meeting_date &&
                !s.questionnaire_distribution_date &&
                !s.data_effective_date &&
                !s.questionnaire_due_date &&
                !s.report_delivery_date &&
                !s.results_webinar_date ? (
                  <p>There are no dates to display for this survey.</p>
                ) : (
                  <ul>
                    {s.planning_meeting_date ? (
                      <li>
                        {fr_y} Survey Planning Meeting: {pm_dw}, {pm_m} {pm_d},{' '}
                        {pm_y} {s.planning_meeting_date_location ? 'at' : undefined} <em>{s.planning_meeting_date_location}</em>
                      </li>
                    ) : null}
                    {s.questionnaire_distribution_date ? (
                      <li>
                        {fr_y} Questionnaire Distribution: {qd_m} {qd_y}
                      </li>
                    ) : null}
                    {s.data_effective_date ? (
                      <li>
                        {fr_y} Date Effective Date: {de_m} {de_d}, {de_y}
                      </li>
                    ) : null}
                    {s.questionnaire_due_date ? (
                      <li>
                        {fr_y} Questionnaire Due Date: {qDue_m} {qDue_d},{' '}
                        {qDue_y}
                      </li>
                    ) : null}
                    {s.report_delivery_date ? (
                      <li>
                        {fr_y} Full Report of Results Published: {fr_m} {fr_y}
                      </li>
                    ) : null}
                    {s.results_webinar_date ? (
                      <li>
                        {fr_y} Report of Results Webinar: {rw_m} {rw_y}
                      </li>
                    ) : null}
                  </ul>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      );
    });
  }

  render() {
    const { surveys } = this.props;

    return (
      <div id='my_surveys'>
        <Row>
          <Col lg='5'>
            <h4>My Surveys</h4>
            <hr style={{ marginLeft: 'auto' }} />
          </Col>
          <Col lg='7'>
            {surveys.length === 0 ? (
              <p>There are currently no surveys assigned to your account.</p>
            ) : (
              this.renderSurveys(surveys)
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    surveys: state.home.surveys,
  };
}

export default connect(mapStateToProps, { openSections })(SurveyList);
