import axios from 'axios';

// import { URL } from "../index";

// Establish the different types
export const CONTACT_QUESTIONS = 'contact_questions';
export const MESSAGE_SUBMITTED = 'message_submitted';

// Creates the error state to be used in components
export function getFAQ() {
  return function (dispatch) {
    axios
      .get(`/api/client/contact/content/`, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        dispatch({
          type: CONTACT_QUESTIONS,
          payload: response.data,
        });
      });
  };
}

export function submitMessage(submission, csrfToken) {
  return function (dispatch) {
    axios
      .post(`/api/client/contact/submit/`, submission, {
        headers: {
          'content-type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      })
      .then((response) => {
        dispatch({
          type: MESSAGE_SUBMITTED,
          payload: { success: ['Message submitted successfully!'] },
        });
      });
  };
}
