import _ from "lodash";
import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
  Row
} from "reactstrap";

export default class BasicQueryPercentiles extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      options: [
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        "mean",
        55,
        60,
        65,
        70,
        75,
        80,
        85,
        90,
        95
      ],
      optionsCSF: [
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        "mean",
        55,
        60,
        62.5,
        65,
        70,
        75,
        80,
        85,
        90,
        95
      ],
      changePercentileVal: "",
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  render() {
    const { csf, k, v } = this.props;

    const { options, optionsCSF, popoverOpen } = this.state;

    // assign the correct option list if CSF
    let opt;
    if (csf) {
      opt = optionsCSF;
    } else {
      opt = options;
    }

    return (
      <th
        id={k}
        onClick={
          Number.isInteger(v) || v === 62.5 || v === "mean" ? this.toggle : null
        }
      >
        {Number.isInteger(v) || v === 62.5 ? v + "th" : v}
        {v === "n" ? null : <i className="fas fa-sort"></i>}
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target={k}
          trigger="legacy"
          toggle={this.toggle}
        >
          <PopoverHeader>Select New Percentile</PopoverHeader>
          <PopoverBody>
            <Row>
              <Col>
                <ButtonGroup size="sm">
                  {_.map(opt, o => {
                    if (o < 50) {
                      return (
                        <Button
                          key={o}
                          value={o}
                          id={k}
                          className="w-100"
                          onClick={this.props.handleClick}
                        >
                          {o + "th"}
                        </Button>
                      );
                    }
                  })}
                </ButtonGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <ButtonGroup size="sm">
                  {_.map(opt, o => {
                    if (o === 50 || o === "mean") {
                      return (
                        <Button
                          key={o}
                          value={o}
                          id={k}
                          className="w-100"
                          onClick={this.props.handleClick}
                        >
                          {Number.isInteger(o) ? o + "th" : o.charAt(0).toUpperCase() + o.slice(1)}
                        </Button>
                      );
                    }
                  })}
                </ButtonGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <ButtonGroup size="sm">
                  {_.map(opt, o => {
                    if (o > 50) {
                      return (
                        <Button
                          key={o}
                          value={o}
                          id={k}
                          className="w-100"
                          onClick={this.props.handleClick}
                        >
                          {o + "th"}
                        </Button>
                      );
                    }
                  })}
                </ButtonGroup>
              </Col>
            </Row>
          </PopoverBody>
        </Popover>
      </th>
    );
  }
}
