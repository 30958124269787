import axios from 'axios';

// Establish the different types
export const HOME = 'home';
export const SURVEYS_OPEN = 'surveys_open';
export const UPDATES_OPEN = 'updates_open';
export const DATES_OPEN = 'dates_open';

// Creates the error state to be used in components
export function getHome() {
  return function (dispatch) {
    axios
      .get('/api/client/home/', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch({
          type: HOME,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log('An error has occured.');
      });
  };
}

export function openSections(surveys, updates, dates) {
  return function (dispatch) {
    dispatch(
      {
        type: SURVEYS_OPEN,
        payload: surveys,
      },
      {
        type: UPDATES_OPEN,
        payload: updates,
      },
      {
        type: DATES_OPEN,
        payload: dates,
      }
    );
  };
}
