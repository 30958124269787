import './/Cookies.scss';

import React, { Component } from 'react';

export default class Cookies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookieBar: false,
      cookieName: 'viewed_cookie_policy',
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // check if the cookie exists
    let getCookie = (name) => {
      let pattern = RegExp(name + '=.[^;]*');
      let matched = document.cookie.match(pattern);
      if (matched) {
        // let cookie = matched[0].split("=");
        return true;
      }
      return false;
    };
    // if it does exist, don't display the bar
    // getCookie(this.state.cookieName) ? '' : this.setState({ showCookieBar: true });
    setTimeout(
      () =>
        getCookie(this.state.cookieName)
          ? ''
          : this.setState({ showCookieBar: true }),
      1000
    );
    // setTimeout(() => console.log('test delay'), 2000);
  }

  handleClick(e) {
    // set the cookie
    let days = 365;
    let name = this.state.cookieName;
    let value = 'yes';
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    let expires = '; expires=' + date.toGMTString();
    document.cookie = name + '=' + value + expires + '; path=/';

    // update the state to update the component
    this.setState({ showCookieBar: false });

    // prevent default behavior like screen moving
    e.preventDefault();
  }

  render() {
    const { showCookieBar } = this.state;

    return (
      <div
        id={showCookieBar ? 'cookie-law-info-bar' : 'cookie-law-info-bar'}
        className={showCookieBar ? 'show' : 'hide'}
      >
        <span>
          This site uses cookies to provide and improve your experience. By
          continuing to use this site, you agree to accept these cookies.
          <a
            href='/#'
            id='cookie_action_close_header'
            className='medium cli-plugin-button cli-plugin-main-button'
            onClick={this.handleClick}
          >
            Accept
          </a>
          <a
            href='https://www.croner.com/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
            className='cli-plugin-main-link'
          >
            Read More
          </a>
        </span>
      </div>
    );
  }
}
