// styles for this component
import './Results.scss';

// Global React-Redux imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Col, Container, Row } from 'reactstrap';

// Containers related to this component
import Loading from '../../components/Global/Loading';
import Query from './BasicQuery';
import ResultsDocuments from './ResultsDocuments';
import ResultsHeader from './ResultsHeader';
import SelectSurvey from '../Global/SurveySelect/SurveySelect';

// Actions related to this component
import { getResults } from '../../actions/results';

class Results extends Component {
  // Grab all of the data from the server related to this component
  UNSAFE_componentWillMount() {
    if (sessionStorage.getItem('survey_id') && sessionStorage.getItem('survey_id') !== 'undefined') {
      this.props.getResults(sessionStorage.getItem('survey_id'));
    } else {
      this.props.getResults('00000000-0000-0000-0000-000000000000');
    }
  }

  render() {
    const { results, survey_id } = this.props;

    if (Object.keys(results).length === 0) {
      return <Loading />;
    } else {
      if (survey_id != null) {
        return (
          <div id='results'>
            <ResultsHeader />
            <SelectSurvey />
            <hr style={{ margin: '0 80px' }} />
            <ResultsDocuments hash={window.location.hash} />
            <hr style={{ margin: '0 80px' }} />
            <Query hash={window.location.hash} />
          </div>
        );
      } else {
        return <Loading />;
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    results: state.results,
    survey_id: state.results.survey_id,
  };
}

export default connect(mapStateToProps, { getResults })(Results);
