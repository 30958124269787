import React, { Component } from 'react';

export default class ContactHeader extends Component {
  render() {
    return (
      <div id='title'>
        <h1>Contact</h1>
      </div>
    );
  }
}
