import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Products from '../Global/Products/Products';

import { Card, Col, Row } from 'reactstrap';

import { securityQuestions, verifyKey } from '../../actions/authentication';
import { clearMessages } from '../../actions/messages';

import Loading from '../../components/Global/Loading';


class SecurityQuestions extends Component {
  UNSAFE_componentWillMount() {
    this.props.verifyKey(this.props.match.params);
  }

  // Where the user input is sent over to the action to be validated by server
  onSubmit(values) {
    this.props.securityQuestions(values, this.props.match.params);
    this.props.clearMessages();
  }

  // This renders errors regarding the form inputs
  renderField(field) {
    const {
      label,
      type,
      // name,
      fieldname,
      meta: { touched, error },
    } = field;

    return (
      <div className='form-group'>
        <label>{label}</label>
        <input
          className='form-control'
          type={type}
          placeholder={fieldname}
          {...field.input}
        />
        <div className='text-danger'>{touched ? error : ''}</div>
      </div>
    );
  }

  // The main body to be rendered
  render() {
    const { handleSubmit, permitRender, questions } = this.props;

    if (permitRender) {
      return (
        <>
          <div className='security-questions'>
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <Card className='custom-field-spacing'>
                <h3
                  style={{
                    fontSize: '18px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  Security Questions
                </h3>
                <p
                  style={{
                    fontSize: '18px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  Please answer the two security questions you set when your
                  account was made.
                </p>

                <p
                  style={{
                    fontSize: '18px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                >
                  If you do not know the answers to these security questions,
                  please contact The Croner Company.
                </p>

                <Field
                  label={questions.sq1}
                  name='sa1'
                  type='text'
                  fieldname='Security Answer 1'
                  component={this.renderField}
                />
                <Field
                  label={questions.sq2}
                  name='sa2'
                  type='text'
                  fieldname='Security Answer 2'
                  component={this.renderField}
                />
                <Row>
                  <Col>
                    <button
                      type='submit'
                      className='blue-button'
                      style={{ marginBottom: '30px' }}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Card>
            </form>
          </div>
          <Products />
        </>
      );
    } else if (!permitRender) {
      return <Loading />;
    }
  }
}

// General form validation to make sure fields have entries
function validate(values) {
  const errors = {};

  if (!values.sa1) {
    errors.sa1 = 'This question must be answered.';
  }
  if (!values.sa2) {
    errors.sa2 = 'This question must be answered.';
  }
  return errors;
}

// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
function mapStateToProps(state) {
  return {
    permitRender: state.auth.permitRender,
    questions: state.auth.acctVal,
  };
}

SecurityQuestions = connect(mapStateToProps, {
  clearMessages,
  securityQuestions,
  verifyKey,
})(SecurityQuestions);
SecurityQuestions = reduxForm({
  form: 'security_questions',
  validate,
})(SecurityQuestions);
export default SecurityQuestions;
