import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  authRequired,
  refreshToken,
  unpermitRender,
  getSession
} from '../actions/authentication';
import { getFeatures } from '../actions/features';
import Loading from '../components/Global/Loading/Loading';

// This HOC not only protects certain routes by checking for existence of a token,
// but if the token exists it make sure it is current
// if it is current, it will refresh the token
export default function (ComposedComponent) {
  class AuthenticationRequired extends Component {
    // Check if the user has a token when protected route component is about to mount
    // Route them to / if they do not
    UNSAFE_componentWillMount() {
      this.props.getSession();
      if (this.props.isAuthenticated === false) {
        this.props.authRequired();
      } else {
        this.props.refreshToken();
        this.props.getFeatures();
      }
    }

    componentWillUnmount() {
      this.props.unpermitRender();
    }

    // Render the component if passing these checks
    render() {
      // console.log(this.props.isAuthenticated);
      const { permitRender } = this.props;
      if (permitRender) {
        return <ComposedComponent {...this.props} />;
      } else if (!permitRender) {
        return <Loading />;
      }
    }
  }

  //
  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.authenticated,
      permitRender: state.auth.permitRender
    };
  }

  // Conenct to the authRequired action
  return connect(mapStateToProps, {
    authRequired,
    refreshToken,
    unpermitRender,
    getSession,
    getFeatures
  })(AuthenticationRequired);
}
