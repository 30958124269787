import axios from 'axios';
import { push } from 'connected-react-router';

// Establish the different types
export const ACCT_VALUES = 'acct_values';
export const AUTH_ERROR = 'auth_error';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_USER = 'auth_user';
export const COMPANY_GUID = 'company_guid';
export const USER_GUID = 'user_guid';
export const CSRF_TOKEN = 'csrf_token';
export const PERMIT_RENDER = 'permit_render';
export const UNAUTH_USER = 'unauth_user';
export const PRODUCT_BUYER = 'product_buyer';

// Creates the error state to be used in components
export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

// Creates the success state to be used in components
export function authSuccess(success) {
  return {
    type: AUTH_SUCCESS,
    payload: success,
  };
}

// If no token, not authorized, and send them to login
export function authRequired() {
  return function (dispatch) {
    sessionStorage.clear();
    dispatch(push('/'));
    dispatch(
      authError({
        error: ['Please log in to access this section of the website.'],
      })
    );
    dispatch({ type: UNAUTH_USER, payload: false });
  };
}

export function accountAssist(email) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/account_assistance/`, email)
      .then((response) => {
        if (response.data === 'Resend.') {
          dispatch(push('/'));
          dispatch(
            authSuccess({
              success: [`A new account activation email is being sent to you.`],
            })
          );
        } else if (response.data === 'Active') {
          dispatch(push('/'));
          dispatch(
            authSuccess({
              success: [
                `You have an active account. Please use the Username Recovery and Password Reset tools if you are having difficulty accessing your account.`,
              ],
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          authError({
            error: [
              'This email is not associated with an account. Please contact The Croner Company to setup a new account or if this is in error.',
            ],
          })
        );
      });
  };
}

// If no token, not authorized, and send them to login
export function checkUsername(username) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/verify_username/`, { username: username })
      .then((response) => {
        dispatch(
          authError({
            error: [
              'This username is in use. Please select a different username.',
            ],
          })
        );
      })
      .catch((error) => {
        dispatch(
          authSuccess({ success: ['This username is available to use.'] })
        );
      });
  };
}

// If no token, not authorized, and send them to login
export function checkPassword({ password1, password2 }) {
  return function (dispatch) {
    axios.post(`/api/client/auth/verify_password/`, { password1, password2 });
  };
}

// Signing out the user: clear sessionStorage and reroute to login
export function idleUser() {
  return function (dispatch) {
    // sessionStorage.clear();
    // dispatch({ type: UNAUTH_USER });
    // dispatch(push('/'));
    // dispatch(
    //   authError({
    //     error: ['You have been logged out due to inactivity.'],
    //   })
    // );

    axios
    .get(`/api/client/auth/signout/`, {
      withCredentials: true,
      credentials: 'same-origin',
    })
    .then((response) => {
      console.log(response);
      dispatch({ type: UNAUTH_USER, payload: false });
      dispatch(push('/'));
      window.scrollTo(0, 0);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: UNAUTH_USER, payload: false });
      dispatch(push('/'));
      window.scrollTo(0, 0);
      window.location.reload();
    });

  };
}

// If there is a token, they are authorized, so send to /home
export function isAuthenticated() {
  return function (dispatch) {
    dispatch(push('/home/'));
    dispatch({ type: AUTH_USER });
  };
}

// Matches email to DB and if matched, it sends verification email
export function recoverUsername({ email }) {
  // console.log(URL);
  return function (dispatch) {
    axios
      .post(`/api/client/auth/recover_username/`, { email })
      .then((response) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: [
              `If a valid email was entered, an email with reset instructions will been sent to the associated account.`,
            ],
          })
        );
      })
      .catch((error) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: [
              `If a valid email was entered, an email with reset instructions will been sent to the associated account.`,
            ],
          })
        );
      });
  };
}

// Matches email to DB and if matched, it sends verification email
export function resendEmail({ id, f }) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/expired_key/`, { id, f })
      .then((response) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({ success: [`A new email has been sent to you.`] })
        );
      })
      .catch((error) => {
        // dispatch(authError(error.response.data));
      });
  };
}

// Matches email to DB and if matched, it sends verification email
export function resetPassword({ username }) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/reset_password/`, { username })
      .then((response) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: [
              `If a valid username was entered, an email with reset instructions will been sent to the associated account.`,
            ],
          })
        );
      })
      .catch((error) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: [
              `If a valid username was entered, an email with reset instructions will been sent to the associated account.`,
            ],
          })
        );
      });
  };
}

// Authenticating the user: send creds to API, if good store the token, if not
// Then the creds are wrong and send to authError
export function securityQuestions({ sa1, sa2 }, { f, id, key }) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/security_questions/`, { sa1, sa2, f, id })
      .then((response) => {
        if (response.data === 'ru') {
          dispatch(push('/'));
          dispatch(
            authSuccess({
              success: [
                'The username has been sent to the email on your account.',
              ],
            })
          );
        } else if (response.data === 'rp') {
          dispatch(push(`/auth/set_password/f=${f}&i=${id}&k=${key}`));
        }
      })
      .catch((error) => {
        dispatch(authError(error.response.data));
      });
  };
}

export function setPassword({ password1, password2 }, { id }) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/set_password/`, { password1, password2, id })
      .then((response) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: [
              'The password has been updated. Please login with the new credentials.',
            ],
          })
        );
      })
      .catch((error) => {
        dispatch(authError(error.response.data));
      });
  };
}

export function setupUser(
  { username, q1, a1, q2, a2, firstname, lastname, phone },
  { password1, password2 },
  { id, key },
  csrfToken
) {
  return function (dispatch) {
    axios
      .post(`/api/client/auth/setup_user/`, {
        username,
        password1,
        password2,
        q1,
        a1,
        q2,
        a2,
        firstname,
        lastname,
        phone,
        id,
        key,
      }, {
        headers: {
          'content-type': 'application/json',
          'X-CSRFToken': csrfToken
        },
      })
      .then((response) => {
        dispatch(push('/'));
        dispatch(
          authSuccess({
            success: ['This account is now activated. Please login.'],
          })
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((error) => {
        //console.log('Failed')
      });
  };
}

// Signing out the user: clear sessionStorage and reroute to login
export function signoutInactive() {
  return function (dispatch) {
    // sessionStorage.clear();
    dispatch({ type: UNAUTH_USER, payload: false });
    dispatch(push('/'));
    dispatch(
      authError({
        error: [
          'You have been signed out due to being inactive for 15 minutes.',
        ],
      })
    );
  };
}

// Signing out the user: clear sessionStorage and reroute to login
export function unpermitRender() {
  return function (dispatch) {
    dispatch({
      type: PERMIT_RENDER,
      payload: false,
    });
  };
}

// Matches email to DB and if matched, it sends verification email
export function verifyKey({ f, id, key }) {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/validate_key/?f=${f}&i=${id}&k=${key}`)
      .then((response) => {
        dispatch({
          type: ACCT_VALUES,
          payload: {
            data: response.data,
          },
        });
        dispatch({
          type: PERMIT_RENDER,
          payload: true,
        });
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(authError(error.response.data));
        dispatch(push(`/auth/expired_key/f=${f}&i=${id}&k=${key}`));
      });
  };
}

// Authenticating the user: send creds to API, if good store the token, if not
// Then the creds are wrong and send to authError
export function signin({ username, password }) {
  return function (dispatch) {
    axios
      .post(
        `/api/client/auth/signin/`,
        { username, password }
        // {
        //   withCredentials: true,
        //   credentials: 'same-origin',
        //   // headers: {
        //   //   'X-CSRFToken': 123,
        //   // },
        // }
      )
      .then((res) => {
        dispatch({
          type: AUTH_USER,
          payload: true,
        });
        dispatch({
          type: PERMIT_RENDER,
          payload: true,
        });
        // console.log(res);
        // sessionStorage.setItem('isAuthenticated', true);
        dispatch(push('/home'));
      })
      .catch((err) => {
        dispatch(authError(err.response.data));
      });
  };
}

export function getCSRF() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/csrf/`, {
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then((res) => {
        dispatch({
          type: CSRF_TOKEN,
          payload: res.headers['x-csrftoken'],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getSessionSignin() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/session/`)
      .then((res) => {
        if (res.data.isAuthenticated) {
          dispatch({
            type: AUTH_USER,
            payload: res.data.isAuthenticated,
          });
          dispatch({
            type: PERMIT_RENDER,
            payload: true,
          });
          dispatch(push('/home'));
        } else {
          dispatch({
            type: UNAUTH_USER,
            payload: res.data.isAuthenticated,
          });
          dispatch({
            type: PERMIT_RENDER,
            payload: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UNAUTH_USER,
          payload: false,
        });
        dispatch({
          type: PERMIT_RENDER,
          payload: false,
        });
        console.log(err);
      });
  };
}
export function getSession() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/session/`)
      .then((res) => {
        if (res.data.isAuthenticated) {
          dispatch({
            type: AUTH_USER,
            payload: res.data.isAuthenticated,
          });
          dispatch({
            type: PERMIT_RENDER,
            payload: true,
          });
        } else {
          dispatch({
            type: UNAUTH_USER,
            payload: res.data.isAuthenticated,
          });
          dispatch({
            type: PERMIT_RENDER,
            payload: false,
          });
        }
        dispatch({
          type: PRODUCT_BUYER,
          payload: res.data.buyer
        })
        dispatch({
          type: COMPANY_GUID,
          payload: res.data.company_guid
        })
        dispatch({
          type: USER_GUID,
          payload: res.data.user_guid
        })
      })
      .catch((err) => {
        dispatch({
          type: UNAUTH_USER,
          payload: false,
        });
        dispatch({
          type: PERMIT_RENDER,
          payload: false,
        });
        console.log(err);
      });
  };
}
// Signing out the user: clear sessionStorage and reroute to login
export function signoutUser() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/signout/`, {
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then((response) => {
        console.log(response);
        dispatch({ type: UNAUTH_USER, payload: false });
        dispatch(push('/'));
        window.scrollTo(0, 0);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: UNAUTH_USER, payload: false });
        dispatch(push('/'));
        window.scrollTo(0, 0);
        window.location.reload();
      });
  };
}

// Refresh the token based on the users activity
export function refreshToken() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/session/`)
      .then((res) => {
        dispatch({ 
          type: AUTH_USER, 
          payload: true 
        });
        dispatch({
          type: PERMIT_RENDER,
          payload: true,
        });
      })
      .catch((err) => {
        sessionStorage.clear();
        dispatch({ 
          type: UNAUTH_USER, 
          payload: false 
        });
        dispatch(push('/'));
        dispatch(
          authError({
            error: [
              'Please log in to access this section of the website.',
            ],
          })
        );
      });
  };
}
