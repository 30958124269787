// import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { searchTerm } from '../../../actions/results';

class BasicQuerySearch extends Component {
  // Declare the state "term"
  constructor(props) {
    super(props);

    this.state = {
      term: '',
    };
  }

  // When someone enters into the search box, it narrows down
  // the list of positions; also filters out speciality chars
  onInputChange(term) {
    var cleanString = term.replace(/([`~!@#$%^&*()_|+?;:''".<>]+)/g, '');
    term = cleanString.toLowerCase();
    if (!term) {
      term = '';
    }
    this.setState({ term });
    this.props.searchTerm(term);
  }

  // renders the search box that filters down the list of positions
  render() {
    const { pos_count } = this.props;

    return (
      <div className='results-search'>
        {window.location.pathname === '/demo/query/' ? (
          <h2 style={{ marginTop: '-15px', marginBottom: '15px' }}>Survey Data Query by Position</h2>
        ) : (
          <input
            className='form-control'
            placeholder='Enter Keyword or Position Code'
            onInput={(event) => this.onInputChange(event.target.value)}
          />
        )}
        <h5>
          <b>{pos_count}</b> {pos_count === 1 ? 'Position' : 'Positions'} Found{' '}
          {pos_count >= 1 ? ' - Select One Below' : null}
        </h5>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pos_count: state.results.pos_count,
  };
}

export default connect(mapStateToProps, { searchTerm })(BasicQuerySearch);
