import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { submitUserInfo } from '../../actions/accountSettings';

import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

class UpdateSecurityQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      sq1: '',
      sq2: '',
      sa1: '',
      sa2: '',
      error1: '',
      error2: '',
      method: 'sq',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    // Make sure "select a question:" is not selected
    if (e.name === 'sq1' && e.value === 'Select a question:') {
      this.setState({ error1: 'Invalid security question selected.' });
    } else {
      this.setState({ error1: '' });
    }
    if (e.name === 'sq2' && e.value === 'Select a question:') {
      this.setState({ error2: 'Invalid security question selected.' });
    } else {
      this.setState({ error2: '' });
    }

    this.props.submitUserInfo(this.state, this.props.csrfToken);
    this.setState({
      sq1: '',
      sq2: '',
      sa1: '',
      sa2: '',
      error1: '',
      error2: '',
      method: 'sq',
    });
  }

  handleChange(e) {
    const { name, value } = e.target;

    // set state for questions and answers
    this.setState(
      {
        [name]: value,
      },
      () => {
        // Make sure "select a question:" is not selected
        if (this.state.sq1 === 'Select a question:') {
          this.setState({
            error1:
              'Security Question 1: "Select a question:" is not a valid option.',
          });
        } else if (this.state.sq1) {
          this.setState({ error1: '' });
        }
        if (this.state.sq2 === 'Select a question:') {
          this.setState({
            error2:
              'Security Question 2: "Select a question:" is not a valid option.',
          });
        } else if (this.state.sq2) {
          this.setState({ error2: '' });
        }

        // make sure they are not duplicates
        if (this.state.sq1 === this.state.sq2) {
          this.setState({
            error1: 'Security Question 1: Cannot be the same as Question 1.',
            error2: 'Security Question 2: Cannot be the same as Question 2.',
          });
        }
      }
    );
  }

  renderOptions() {
    const securityQuestions = [
      'In what city or town did you meet your spouse / partner?',
      'In what city or town did your mother and father meet?',
      'In what town or city was your first full time job?',
      "What is the first name of your spouse's father?",
      'What is the make of your first car?',
      'What is the name of the place your wedding reception was held?',
      'What is the name of your favorite childhood friend?',
      'What is the name of your first pet?',
      'What is the name of your first school?',
      "What is your oldest child's nickname?",
      "What is your youngest child's nickname?",
      'What school did you attend for sixth grade?',
      'What time of the day was your first child born? (hh:mm)',
      'What time of the day were you born? (hh:mm)',
      'What was the house number and street name you lived in as a child?',
      'What was the last name of your third grade teacher?',
      'What was the make and model of your first car?',
      'What was the name of the company where you had your first job?',
      'What was the name of the hospital where you were born?',
      'What was the name of your elementary / primary school?',
      'What was the name of your first stuffed animal or doll or action figure?',
      'What was your High School Mascot?',
      'What was your childhood nickname?',
      'What was your favorite place to visit as a child?',
      'What was your favorite sport in high school?',
      'What were the last four digits of your childhood telephone number?',
      "Where were you New Year's 2000?",
      'Who is your childhood sports hero?',
      'Who was your childhood hero?',
    ];

    return _.map(securityQuestions, (sq) => {
      return (
        <option value={sq} key={sq}>
          {sq}
        </option>
      );
    });
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
    e.preventDefault();
  }

  render() {
    const { sa1, sa2, error1, error2 } = this.state;

    return (
      <div id='update_sq'>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Row>
              <Col lg='5'>
                <h4>Update Security Questions & Answers</h4>
                <hr />
                <h5 className={error1 ? 'error_sq show' : 'error_sq'}>
                  {error1 ? error1 : null}
                </h5>
                <h5 className={error2 ? 'error_sq show' : 'error_sq'}>
                  {error2 ? error2 : null}
                </h5>
              </Col>
              <Col lg='7'>
                <Input onChange={this.handleChange} name='sq1' type='select'>
                  <option>Select a question:</option>
                  {this.renderOptions()}
                </Input>

                <Input
                  value={this.state.sa1}
                  onChange={this.handleChange}
                  name='sa1'
                  placeholder='Security Answer 1'
                />

                <Input onChange={this.handleChange} name='sq2' type='select'>
                  <option>Select a question:</option>
                  {this.renderOptions()}
                </Input>

                <Input
                  required
                  value={this.state.sa2}
                  onChange={this.handleChange}
                  name='sa2'
                  placeholder='Security Answer 2'
                />
                <Button
                  type='submit'
                  className='red-button'
                  disabled={!error1 && !error2 && sa1 && sa2 ? false : true}
                >
                  Update Security Questions & Answers
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.accountSettings.user,
  };
}

export default connect(mapStateToProps, { submitUserInfo })(
  UpdateSecurityQuestions
);
