import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearResults, downloadResultsDocs } from '../../actions/results';
import { clearMessages } from '../../actions/messages';
import { Col, Row } from 'reactstrap';

import downloading from './../Global/download.gif';

class ResultsDocuments extends Component {
  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearResults();
  }

  // handling click for download
  handleClick(e) {
    e.preventDefault();
    // window.location.hash = '#results_documents';
    this.props.clearMessages();
    this.props.downloadResultsDocs(
      sessionStorage.getItem('survey_id'),
      e.target.name
    );
  }

  handleLoading(e) {
    var element = document.getElementsByName(e.target.name)[0];
    var img = document.createElement('IMG');
    img.src = downloading;
    img.height = '20';
    img.id = 'downloading';
    element.appendChild(img);
  }

  // render the individual documents
  renderDocuments() {
    const { results } = this.props;

    // see if empty first and if not render the documents
    if (_.isEmpty(results)) {
      return <li>Nothing at this time.</li>;
    } else {
      return _.map(results, (rd) => {
        let fileSplit = rd.filename.split('.');
        let fileExt = fileSplit[fileSplit.length - 1];
        let fileType;

        if (fileExt === 'pdf') {
          fileType = <i className='far fa-file-pdf'></i>;
        } else if (fileExt === 'xlsx' || fileExt === 'xls') {
          fileType = <i className='far fa-file-excel'></i>;
        } else if (fileExt === 'doc' || fileExt === 'docx') {
          fileType = <i className='far fa-file-alt'></i>;
        } else if (fileExt === 'ppt' || fileExt === 'pptx') {
          fileType = <i className='far fa-file-powerpoint'></i>;
        } else if (fileExt === 'mp4') {
          fileType = <i className='fa fa-film'></i>;
        } else if (fileExt === 'txt') {
          fileType = <i className='far fa-file-alt'></i>;
        }

        return (
          <li key={rd.guid}>
            <div
              className={
                this.props.resultsDownloadStatus ? 'disable-anchor' : null
              }
            >
              <Link
                name={rd.guid}
                to={`/results/${rd.guid}`}
                onClick={(e) => {
                  this.handleClick(e);
                  this.handleLoading(e);
                }}
              >
                {fileType} {rd.filename}
              </Link>
            </div>
          </li>
        );
      });
    }
  }

  // render the main element of the container
  render() {

    if (
      !this.props.resultsDownloadStatus &&
      document.getElementById('downloading')
    ) {
      var element = document.getElementById('downloading');
      element.remove();
    }

    return (
      <div id='results_documents'>
        <Row>
          <Col lg='5'>
            <h4>Results Documents</h4>
            <hr />
          </Col>
          <Col lg='7'>
            <ul>{this.renderDocuments()}</ul>
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    results: state.results.results,
    resultsDownloadStatus: state.results.resultsDownloadStatus,
  };
}

export default connect(mapStateToProps, {
  clearMessages,
  clearResults,
  downloadResultsDocs,
})(ResultsDocuments);
