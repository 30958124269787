// Styles for this component
import "./ErrorPage.scss";

// Global React-Redux imports
import React, { Component } from "react";

import { Card, Container } from "reactstrap";

export default class ErrorPage extends Component {
  render() {
    return (
      <div id="error-page">
        <Container
          fluid
          className="error-custom-background error-custom-spacing"
        >
          <Container className="error-header align-middle">
            <h1>Error Encountered</h1>
          </Container>
        </Container>
        <br />
        <Container>
          <Card id="error" className="error-sections">
            <a className="error-section-headers" href="/#">
              <h4>Error Message - 404 Not Found</h4>
            </a>
            <hr />
            <p>
              <b>
                The page you are trying to view, or file you are trying to
                download, has encountered an error. Please try the following to
                resolve the issue:
              </b>
            </p>
            <ul>
              <li>Press CTRL + F5 to force a refresh in the browser</li>
              <li>
                Use another browser temporarily to see if the issue still occurs
              </li>
            </ul>
            <p>
              If the issue persists, please contact us at 415-485-5530 or{" "}
              <a href="mailto:surveyadmin@croner.com?Subject=Client Portal Issue">
                surveyadmin@croner.com
              </a>
              .
            </p>
          </Card>
        </Container>
      </div>
    );
  }
}
