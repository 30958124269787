import React, { Component } from 'react';
import { connect } from 'react-redux';

import { submitUserInfo } from '../../actions/accountSettings';

import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      method: 'info',
      first_name: '',
      last_name: '',
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(e) {
    if (e.target.name === 'first_name') {
      this.setState({ first_name: e.target.value });
    }
    if (e.target.name === 'last_name') {
      this.setState({ last_name: e.target.value });
    }
    if (e.target.name === 'email') {
      this.setState({ email: e.target.value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.submitUserInfo(this.state, this.props.csrfToken);
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
    e.preventDefault();
  }

  render() {
    const { email, first_name, last_name } = this.state;
    const { user } = this.props;

    return (
      <div id='general_info'>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Row>
              <Col lg='5'>
                <h4>General Information</h4>
                <hr />
              </Col>
              <Col lg='7'>
                <Input
                  onChange={this.handleChange}
                  placeholder={user.first_name ? user.first_name : 'First Name'}
                  required
                  name='first_name'
                />
                <Input
                  onChange={this.handleChange}
                  placeholder={user.last_name ? user.last_name : 'Last Name'}
                  required
                  name='last_name'
                />
                {/* <Input
                  value={phone}
                  onChange={this.handleChange}
                  placeholder={user.phone ? user.phone : 'Phone #'}
                  required
                  name='phone'
                /> */}
                <Input
                  type='email'
                  onChange={this.handleChange}
                  placeholder={user.email ? user.email : 'Email Address'}
                  required
                  name='email'
                />
                <Button
                  disabled={
                    first_name && last_name&& email
                      ? false
                      : true
                  }
                  type='submit'
                  className='red-button'
                >
                  Request Information Update
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.accountSettings.user,
  };
}

export default connect(mapStateToProps, { submitUserInfo })(GeneralInfo);
