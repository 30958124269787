import './SurveySelect.scss';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDocuments, clearDocuments } from '../../../actions/documents';
import { getResults, clearResults } from '../../../actions/results';
import { Input } from 'reactstrap';

class SelectSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      survey: event.target.value,
    });
    let path = window.location.pathname;
    if (path === '/documents/' || path === '/documents') {
      this.props.getDocuments(event.target.value);
      this.props.clearDocuments();
      // window.location.href = path.split('#')[0];
    } else if (path === '/results/' || path === '/results') {
      this.props.getResults(event.target.value);
      this.props.clearResults();
    }
  }

  renderCurrentSurveyName() {
    return _(this.props.survey_list)
      .filter((c) => c.guid === this.props.survey_id)
      .map('name')
      .value();
  }

  renderCurrentSurveyYear() {
    return _(this.props.survey_list)
      .filter((c) => c.guid === this.props.survey_id)
      .map('year')
      .value();
  }

  renderSurveySelect() {
    const { survey_list } = this.props;

    return _.map(survey_list, (s) => {
      return (
        <option key={s.guid} value={s.guid}>
          {s.name}
        </option>
      );
    });
  }

  render() {
    const { survey_id, survey_list } = this.props;

    return (
      <div id='survey-select'>
        {survey_list && survey_list.length !== 0 ? (
          <div id='survey-select-body'>
            <h3>Select Survey:</h3>
            <hr />

            <Input type='select' onChange={this.handleChange} value={survey_id}>
              {this.renderSurveySelect()}
            </Input>
            <div id='survey-select-header'>
              <h1>
                {survey_list
                  ? this.renderCurrentSurveyName()
                  : 'No surveys are assigned to this account.'}
              </h1>
            </div>
          </div>
        ) : (
          <div id='survey-select-header'>
            <h1>No surveys available at this time.</h1>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let path = window.location.pathname;
  if (path === '/documents/' || path === '/documents') {
    return {
      survey_id: state.documents.survey_id,
      survey_list: state.documents.survey_list,
    };
  } else if (path === '/results/' || path === '/results') {
    return {
      survey_id: state.results.survey_id,
      survey_list: state.results.survey_list,
    };
  }
}

export default connect(mapStateToProps, {
  getDocuments,
  getResults,
  clearDocuments,
  clearResults,
})(SelectSurvey);
