// React Imports
import React from 'react';

// Third-party Imports

// Croner Imports

const ParticipationHeader = () => {
  return (
    <div id='title'>
      <h1>2024 <em>Croner Compensation Survey of Foundations</em></h1>
    </div>
  );
};

export default ParticipationHeader;
