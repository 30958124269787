// Styles for /documents
import './Documents.scss';

// Global React-Redux imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Col, Row } from 'reactstrap';

// Containers related to this component
import DocumentsHeader from './DocumentsHeader';
import PlanningMeeting from './PlanningMeeting';
import Questionnaire from './Questionnaire';
import SelectSurvey from '../Global/SurveySelect/SurveySelect';
import SubmitDocuments from './SubmitDocuments';
import SurveyResources from './SurveyResources';
import Loading from '../../components/Global/Loading';

// Actions related to this component
import { getDocuments } from '../../actions/documents';

class Documents extends Component {
  // Grab all of the data from the server related to this component
  UNSAFE_componentWillMount() {
    if (sessionStorage.getItem('survey_id') && sessionStorage.getItem('survey_id') !== 'undefined') {
      this.props.getDocuments(sessionStorage.getItem('survey_id'));
    } else {
      this.props.getDocuments('00000000-0000-0000-0000-000000000000');
    }
  }

  render() {
    const { documents, survey_id } = this.props;

    if (Object.keys(documents).length === 0) {
      return <Loading />;
    } else {
      if (survey_id != null) {
        return (
          <div id='documents'>
            <DocumentsHeader />
            <SelectSurvey />
            <hr style={{ margin: '0 80px' }} />
            <PlanningMeeting hash={window.location.hash} />
            <hr style={{ margin: '0 80px' }} />
            <Questionnaire hash={window.location.hash} survey_id={survey_id} />
            <hr style={{ margin: '0 80px' }} />
            <SubmitDocuments hash={window.location.hash} />
            <hr style={{ margin: '0 80px' }} />
            <SurveyResources hash={window.location.hash} />
          </div>
        );
      } else {
        return <Loading />;
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents,
    survey_id: state.documents.survey_id,
  };
}

export default connect(mapStateToProps, { getDocuments })(Documents);
