import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signoutUser } from '../../actions/authentication';

class Signout extends Component {

	// Intermediate location since the user is automatically sent to /
	UNSAFE_componentWillMount() {
		this.props.signoutUser();
	}

	// Really nothing to render
	render() {
		return <div></div>;
	}
}

// Connect component to the signoutUser action
export default connect(null, { signoutUser })(Signout);
