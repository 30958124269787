import axios from 'axios';

// Establish the different types
export const PARTICIPATION_SUBMITTED = 'participation_submitted';
export const DUPLICATE_SUBMITTED = 'duplicate_submitted';


export function submitParticipation(body, csrfToken) {
  return function (dispatch) {
    axios
      .post(`/api/client/surveys/participation/`, body, {
        headers: {
          'content-type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      })
      .then((response) => {
        dispatch({
          type: PARTICIPATION_SUBMITTED,
          payload: { success: ['Participation submitted successfully.'] },
        });
      })
      .catch((error) => {
        dispatch({
          type: DUPLICATE_SUBMITTED,
          payload: {
            error: ['You have already submitted a response.'],
          },
        });
      });
  };
}
