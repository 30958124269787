import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearDocuments, downloadDocDocs } from '../../actions/documents';
import { clearMessages } from '../../actions/messages';
import { Col, Row } from 'reactstrap';

import downloading from './../Global/download.gif';

class SurveyResources extends Component {
  // clear out the related Redux store when navigating away
  componentWillUnmount() {
    this.props.clearDocuments();
  }

  // handling click for download
  handleClick(e) {
    // window.location.hash = '#survey_resources';
    this.props.clearMessages();
    e.preventDefault();
    this.props.downloadDocDocs(
      sessionStorage.getItem('survey_id'),
      e.target.name
    );
  }

  handleLoading(e) {
    var element = document.getElementsByName(e.target.name)[0];
    var img = document.createElement('IMG');
    img.src = downloading;
    img.height = '20';
    img.id = 'downloading';
    element.appendChild(img);
  }

  // render the individual documents
  renderDocuments() {
    const { survey_resources } = this.props;

    // see if empty first and if not render the documents
    if (_.isEmpty(survey_resources)) {
      return <li>Nothing at this time.</li>;
    } else {
      return _.map(survey_resources, (pm) => {
        let fileSplit = pm.filename.split('.');
        let fileExt = fileSplit[fileSplit.length - 1];
        let fileType;

        if (fileExt === 'pdf') {
          fileType = <i className='far fa-file-pdf'></i>;
        } else if (fileExt === 'xlsx' || fileExt === 'xls') {
          fileType = <i className='far fa-file-excel'></i>;
        } else if (fileExt === 'doc' || fileExt === 'docx') {
          fileType = <i className='far fa-file-alt'></i>;
        } else if (fileExt === 'ppt' || fileExt === 'pptx') {
          fileType = <i className='far fa-file-powerpoint'></i>;
        } else if (fileExt === 'mp4') {
          fileType = <i className='fa fa-film'></i>;
        } else if (fileExt === 'txt') {
          fileType = <i className='far fa-file-alt'></i>;
        }

        return (
          <li key={pm.guid}>
            <div
              className={this.props.downloadStatus ? 'disable-anchor' : null}
            >
              <Link
                name={pm.guid}
                to={`/documents/${pm.guid}`}
                onClick={(e) => {
                  this.handleClick(e);
                  this.handleLoading(e);
                }}
              >
                {fileType} {pm.filename}
              </Link>
            </div>
          </li>
        );
      });
    }
  }

  // render the main element of the container
  render() {
    if (!this.props.downloadStatus && document.getElementById('downloading')) {
      var element = document.getElementById('downloading');
      element.remove();
    }

    return (
      <div id='survey_resources'>
        <Row>
          <Col lg='5'>
            <h4>Survey Resources</h4>
            <hr />
          </Col>
          <Col lg='7'>
            <ul>{this.renderDocuments()}</ul>
          </Col>
        </Row>
      </div>
    );
  }
}

// map the state in the Redux store to props
function mapStateToProps(state) {
  return {
    survey_resources: state.documents.survey_resources,
    downloadStatus: state.documents.downloadStatus,
  };
}

export default connect(mapStateToProps, {
  clearDocuments,
  clearMessages,
  downloadDocDocs,
})(SurveyResources);
