import './Account.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import AccountHeader from './AccountHeader';
import ActiveSessions from './ActiveSessions';
import GeneralInfo from './GeneralInformation';
import KeyContacts from './KeyContacts';
import UpdatePassword from './UpdatePassword';
import UpdateSecurityQuestions from './UpdateSecurityQuestions';
import Loading from '../../components/Global/Loading';
import { getCSRF } from '../../actions/authentication';

import { getUser } from '../../actions/accountSettings';
import { clearMessages } from '../../actions/messages';

class AccountSettings extends Component {
  UNSAFE_componentWillMount() {
    this.props.getUser();
    this.props.getCSRF();
  }

  componentWillUnmount() {
    this.props.clearMessages();
  }

  render() {
    const { user, csrfToken, productBuyer, companyGuid } = this.props;
    const { features } = this.props.features;

    if (companyGuid === '3b9f4fdd-e6df-ee11-904c-6045bd0a1358') {
      return (
        <h1 style={{ padding: '50px', textAlign: 'center' }}>
          You are not authorized to view this page.
        </h1>
      );
    } else if (!user) {
      return <Loading />;
    } else {
      return (
        <div id='account'>
          <AccountHeader />
          <GeneralInfo csrfToken={csrfToken} />
          <hr style={{ margin: '0 80px' }} />
          <UpdatePassword csrfToken={csrfToken} />
          <hr style={{ margin: '0 80px' }} />
          <UpdateSecurityQuestions csrfToken={csrfToken} />
          <hr style={{ margin: '0 80px' }} />
          {productBuyer &&
          features.find((obj) => obj.id === 1 && obj.render) ? (
            <>
              <KeyContacts
                csrfToken={csrfToken}
                companyGuid={companyGuid}
                user={user}
              />
              <hr style={{ margin: '0 80px' }} />
            </>
          ) : null}
          <ActiveSessions csrfToken={csrfToken} />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.accountSettings.user,
    csrfToken: state.auth.csrfToken,
    features: state.features,
    productBuyer: state.auth.productBuyer,
    companyGuid: state.auth.companyGuid,
  };
}

export default connect(mapStateToProps, { clearMessages, getUser, getCSRF })(
  AccountSettings
);
