import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { clearQuery, setCountry } from '../../../actions/results';

class BasicQueryCountryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevSelected: undefined,
    };
  }

  componentWillUnmount() {
    this.props.setCountry('');
  }

  onCountrySelect(val) {
    // console.log(val);
    let { prevSelected } = this.state;

    this.setState({
      prevSelected: val,
    });

    let changed =
      prevSelected === '' && val === ''
        ? true
        : prevSelected === ''
        ? false
        : prevSelected !== val
        ? true
        : false;

    // let changed = prevSelected === '' && event === '' ?
    //                 true :
    //                 prevSelected !== event ?
    //                     true :
    //                     false;

    this.props.clearQuery();
    this.props.setCountry(val, changed);
  }

  // renders the options and also filters down the results
  renderCountryOptions(country) {
    // generate the <option> list
    if (country[0].country) {
      return _.map(_.uniqBy(country, 'country'), (c) => {
        return (
          <option key={c.country} value={c.country}>
            {c.country}
          </option>
        );
      });
    } else {
      return _.map(_.uniqBy(country, 'foundationlabel'), (fl) => {
        return (
          <option key={fl.foundationlabel} value={fl.foundationlabel}>
            {fl.foundationlabel}
          </option>
        );
      });
    }
  }

  render() {
    const { countryList } = this.props;
    return (
      <div className='results-country-list'>
        <select
          className='form-control'
          onChange={(e) => this.onCountrySelect(e.target.value)}
        >
          {countryList[0].country ? (
            <option>Select Country...</option>
          ) : (
            <option>Select Foundation Type...</option>
          )}
          {this.renderCountryOptions(countryList)}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countryList: state.results.pos_list,
  };
}

export default connect(mapStateToProps, { clearQuery, setCountry })(
  BasicQueryCountryList
);
