// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Button, Col, Form, FormGroup, Input, Row, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';

// Croner imports
import { submitMessage } from '../../actions/contact';

const KeyContacts = ({ csrfToken, companyGuid, user }) => {
  const [contacts, setContacts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getContacts = async () => {
      try {
        const response = await fetch(
          `/api/client/companies/${companyGuid}/users/`,
          {
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              'X-CSRFToken': csrfToken,
            },
          }
        );
        if (response.ok) {
          setContacts(await response.json());
        } else {
          console.error('Failed.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (!Object.keys(contacts).length) {
      getContacts();
    }
  }, [csrfToken, companyGuid, contacts]);

  const handleSubmit = (e) => {
    const { message } = e.target;
    const submission = {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      category: 'Survey Support',
      comments: message.value,
    };
    dispatch(submitMessage(submission, csrfToken));
    e.preventDefault();
  };

  const renderKeyContacts = () => {
    return contacts.map((contact) => {
      return (
        <tr key={contact.id}>
          <td>
            {contact.first_name} {contact.last_name}
          </td>
        </tr>
      );
    });
  };

  return (
    <div id='org_contacts'>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Row>
            <Col lg='5'>
              <h4>Organization Contacts</h4>
              <hr />
            </Col>
            <Col lg='7'>
              <Table>
                <thead>
                  <tr>
                    <th>Users</th>
                  </tr>
                </thead>
                <tbody>{renderKeyContacts()}</tbody>
              </Table>
              <Input
                placeholder='If you would like to make changes, enter your message to Croner here.'
                rows='4'
                type='textarea'
                name='message'
                id='message'
                style={{ marginBottom: '16px' }}
              />
              <Button type='submit' className='red-button'>
                Submit Message
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </div>
  );
};

export default KeyContacts;
