import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  Col,
  Row
} from "reactstrap";

// Related actions
import {
  signin,
  authError,
  // isAuthenticated,
  getCSRF,
  getSessionSignin
} from "../../../actions/authentication";
import { clearMessages } from "../../../actions/messages";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      cookieName: "viewed_new_portal_instructions",
    };
    this.toggle = this.toggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  // Check to see if the user already has a token
  // If they do, then send them to /home
  UNSAFE_componentWillMount() {
    this.props.getSessionSignin();
  }

  componentDidMount() {

    // this.props.getSessionSignin();
    let getCookie = name => {
      let pattern = RegExp(name + "=.[^;]*");
      let matched = document.cookie.match(pattern);
      if (matched) {
        // let cookie = matched[0].split('=')
        return true;
      }
      return false;
    };
    // if it does exist, don't display the bar
    // eslint-disable-next-line no-unused-expressions
    getCookie(this.state.cookieName) ? "" : this.setState({ modal: true });
  }

  handleClick(e) {
    // set the cookie
    let days = 365;
    let name = this.state.cookieName;
    let value = "yes";
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    let expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + value + expires + "; path=/";

    // update the state to update the component
    this.setState({ modal: false });

    // prevent default behavior like screen moving
    e.preventDefault();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  UNSAFE_componentWillUpdate() {
    sessionStorage.clear();
  }

  componentWillUnmount() {
    this.props.clearMessages();
  }

  // Where the user input is sent over to the action to be validated by server
  onSubmit(values) {
    // this.props.getCSRF();
    // this.props.getSessionSignin();
    this.props.signin(values);
    this.props.clearMessages();
  }

  // This renders errors regarding the form inputs
  renderField(field) {
    const {
      label,
      type,
      // name,
      meta: { touched, error }
    } = field;

    return (
      <div className="form-group">
        <input
          className="form-control"
          type={type}
          placeholder={label}
          {...field.input}
        />
        <div data-testid='message' className="text-danger">{touched ? error : ""}</div>
      </div>
    );
  }

  // The main body to be rendered
  render() {
    // console.log(this.props.isAuthenticated);
    const { handleSubmit } = this.props;

    return (
      <div className="login">
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Card className="custom-field-spacing">
            <Field
              label="Username"
              name="username"
              type="username"
              component={this.renderField}
            />
            <Field
              label="Password"
              name="password"
              type="password"
              component={this.renderField}
            />
            <Row>
              <Col>
                <Link to="/auth/account_assistance/">
                  <button type="button" color="danger" className="float-left red-button">
                    Account Assistance
                  </button>
                </Link>
              </Col>
              <Col>
                <button type="submit"  className="float-right blue-button">
                  Sign in
                </button>
              </Col>
            </Row>
            <Row>
              <div style={{ marginTop: "20px" }}></div>
            </Row>
            <Row>
              <ul className="signin">
                <li>
                  <Link to="/auth/username/">Username Recovery</Link>
                </li>
                <li>
                  <Link to="/auth/password/">Password Reset</Link>
                </li>
              </ul>
            </Row>
          </Card>
        </form>
      </div>
    );
  }
}

// General form validation to make sure fields have entries
function validate(values) {
  const errors = {};

  if (!values.username) {
    errors.username = "The username is a required field.";
  }

  if (!values.password) {
    errors.password = "The password is a required field.";
  }

  return errors;
}


// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
function mapStateToProps(state) {
  return { 
    isAuthenticated: state.auth.authenticated,
    csrfToken: state.auth.csrfToken,
    errorMessage: state.auth.error 
  };
}

SignIn = connect(mapStateToProps, {
  authError,
  clearMessages,
  // isAuthenticated,
  signin,
  getCSRF,
  getSessionSignin
})(SignIn);
SignIn = reduxForm({
  form: "signin",
  validate
})(SignIn);
export default SignIn;
