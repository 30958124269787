// Establish the different types
export const CLEAR_MESSAGES = 'clear_messages';

// Clear out the store when the navigates away from /documents
export function clearMessages() {
    return function(dispatch) {
        dispatch({
            type: CLEAR_MESSAGES,
            payload: ''
        })
    }
}