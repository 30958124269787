import React, { Component } from 'react';
import { connect } from 'react-redux';
import Products from '../Global/Products/Products';

import { Card, Col, Row } from 'reactstrap';

import { resendEmail } from '../../actions/authentication';
import { clearMessages } from '../../actions/messages';

class ExpiredKey extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Where the user input is sent over to the action to be validated by server
  handleSubmit(e) {
    this.props.resendEmail(this.props.match.params);
    this.props.clearMessages();
    e.preventDefault();
  }

  // The main body to be rendered
  render() {
    const { f } = this.props.match.params;

    return (
      <>
        <div className='security-questions'>
          <form onSubmit={this.handleSubmit}>
            <Card className='custom-field-spacing' style={{ paddingBottom: '95px' }}>
              <h3
                style={{
                  fontSize: '18px',
                  marginLeft: '15px',
                  marginRight: '15px',
                }}
              >
                Expired Security Token
              </h3>
              {f === 'ru' ? (
                <div className='expired-statement'>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    The security token used for username recovery has expired.
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    If you still need to recover your username, click "Submit"
                    below to receive a new username recovery email.
                  </p>
                </div>
              ) : f === 'rp' ? (
                <div className='expired-statement'>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    The security token used for password reset has expired.
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    If you still need to reset your password, click "Submit"
                    below to receive a new password reset email.
                  </p>
                </div>
              ) : f === 'su' ? (
                <div className='expired-statement'>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    The security token used for account activation has expired.
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    <strong>If you have not yet activated your account</strong>,
                    click "Submit" below to have a new activation email sent to
                    your email. Please follow the link and instructions to setup
                    the account.
                  </p>
                </div>
              ) : null}
              <Col>
                <Row>
                  <button type='submit' className='blue-button'>
                    Submit
                  </button>
                </Row>
              </Col>
            </Card>
          </form>
        </div>
        <Products />
      </>
    );
  }
}

// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
export default connect(null, { clearMessages, resendEmail })(ExpiredKey);
