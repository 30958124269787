import './Messages.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearMessages } from '../../../actions/messages';

// import { Container } from 'reactstrap';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.closeMessage = this.closeMessage.bind(this);
  }

  closeMessage() {
    this.props.clearMessages();
  }

  renderMessage() {
    const { errors, successes } = this.props;

    return (
      <div className='message'>
        <i onClick={this.closeMessage} className='float-right fas fa-times'></i>
        <div
          className='message-text'
          dangerouslySetInnerHTML={{
            __html: errors ? errors.error[0] : successes.success[0],
          }}
        ></div>
      </div>
    );
  }

  render() {
    const { errors, successes } = this.props;

    // return (
    //   <div id='messages' style={{ display: 'flex' }}>Test</div>
    // )
    if (successes) {
      return (
        <div id='messages'>
          <div className='hide-messages-bar show-success-messages-bar'>
            {this.renderMessage()}
          </div>
        </div>
      );
    } else if (errors) {
      return (
        <div id='messages'>
          <div className='hide-messages-bar show-error-messages-bar'>
            {this.renderMessage()}
          </div>
        </div>
      );
    } else {
      return (
        <div id='messages'>
          <div className='hide-messages-bar'></div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    errors: state.messages.errors,
    successes: state.messages.successes,
  };
}

export default connect(mapStateToProps, { clearMessages })(Messages);
