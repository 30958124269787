import React, { Component } from 'react';
import { connect } from 'react-redux';

import { submitUserInfo } from '../../actions/accountSettings';
import { signoutUser } from '../../actions/authentication';

import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

// import { clearMessages } from "../../actions/messages";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      oldPassword: '',
      password1: '',
      password2: '',
      touched: false,
      touched2: false,
      eightChar: false,
      upperCase: false,
      specChar: false,
      number: false,
      match: false,
      method: 'pwd',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleChange(e) {
    // update after onChange() to render errors if true
    this.setState({ touched: true });

    // set oldPassword to state
    if (e.target.name === 'oldPassword') {
      this.setState({ oldPassword: e.target.value });
    }

    // set password1 to state
    if (e.target.name === 'password1') {
      this.setState({ password1: e.target.value });
    }
    // set password2 to state
    if (e.target.name === 'password2') {
      this.setState({ password2: e.target.value });
    }

    // password1 length test
    if (e.target.name === 'password1') {
      if (e.target.value.length >= 8) {
        this.setState({ eightChar: true });
      } else {
        this.setState({ eightChar: false });
      }

      // password1 uppercase test
      if (/([A-Z]+)/g.test(e.target.value)) {
        this.setState({ upperCase: true });
      } else {
        this.setState({ upperCase: false });
      }

      // password1 special character test
      if (/([`~!@#$%^&*()_|+\-=?;:'',.<>\]\\]+)/g.test(e.target.value)) {
        this.setState({ specChar: true });
      } else {
        this.setState({ specChar: false });
      }

      // password1 has number
      if (/([0-9]+)/g.test(e.target.value)) {
        this.setState({ number: true });
      } else {
        this.setState({ number: false });
      }
    }

    // check if the passwords match
    if (e.target.name === 'password2') {
      this.setState({ touched2: true });
      if (e.target.value === this.state.password1) {
        this.setState({ match: true });
      } else {
        this.setState({ match: false });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.submitUserInfo(this.state, this.props.csrfToken);
    this.setState({
      oldPassword: '',
      password1: '',
      password2: '',
      touched: false,
      touched2: false,
      eightChar: false,
      upperCase: false,
      specChar: false,
      number: false,
      match: false,
      method: 'pwd',
    });
    this.props.signoutUser();
  }

  toggle(e) {
    this.setState({ collapse: !this.state.collapse });
    e.preventDefault();
  }

  render() {
    const {
      eightChar,
      match,
      number,
      oldPassword,
      password1,
      password2,
      specChar,
      touched,
      touched2,
      upperCase,
    } = this.state;
    // const { user } = this.props;

    return (
      <div id='update_password'>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup style={{ marginBottom: '0' }}>
            <Row>
              <Col lg='5'>
                <h4>Update Password</h4>
                <hr />
                <div id='password-criteria'>
                  <p>The new password must meet the following criteria:</p>
                  <ul>
                    <li
                      className={
                        touched && eightChar
                          ? 'success'
                          : touched && !eightChar
                          ? 'error'
                          : 'neutral'
                      }
                    >
                      <i
                        className={
                          touched && eightChar
                            ? 'fas fa-check'
                            : touched && !eightChar
                            ? 'fas fa-times'
                            : 'fas fa-minus'
                        }
                      ></i>{' '}
                      At least eight characters in length
                    </li>
                    <li
                      className={
                        touched && upperCase
                          ? 'success'
                          : touched && !upperCase
                          ? 'error'
                          : 'neutral'
                      }
                    >
                      <i
                        className={
                          touched && upperCase
                            ? 'fas fa-check'
                            : touched && !upperCase
                            ? 'fas fa-times'
                            : 'fas fa-minus'
                        }
                      ></i>{' '}
                      One uppercase character
                    </li>
                    <li
                      className={
                        touched && specChar
                          ? 'success'
                          : touched && !specChar
                          ? 'error'
                          : 'neutral'
                      }
                    >
                      <i
                        className={
                          touched && specChar
                            ? 'fas fa-check'
                            : touched && !specChar
                            ? 'fas fa-times'
                            : 'fas fa-minus'
                        }
                      ></i>{' '}
                      One special character
                    </li>
                    <li
                      className={
                        touched && number
                          ? 'success'
                          : touched && !number
                          ? 'error'
                          : 'neutral'
                      }
                    >
                      <i
                        className={
                          touched && number
                            ? 'fas fa-check'
                            : touched && !number
                            ? 'fas fa-times'
                            : 'fas fa-minus'
                        }
                      ></i>{' '}
                      One number
                    </li>
                    <li
                      className={
                        touched2 && match
                          ? 'success'
                          : touched2 && !match
                          ? 'error'
                          : 'neutral'
                      }
                    >
                      <i
                        className={
                          touched2 && match
                            ? 'fas fa-check'
                            : touched2 && !match
                            ? 'fas fa-times'
                            : 'fas fa-minus'
                        }
                      ></i>{' '}
                      Passwords match
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg='7'>
                <Input
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                  type='password'
                  name='oldPassword'
                  placeholder='Confirm Old Password'
                />
                <Input
                  value={this.state.password1}
                  onChange={this.handleChange}
                  type='password'
                  name='password1'
                  placeholder='Enter New Password'
                />
                <Input
                  disabled={
                    eightChar && number && specChar && upperCase ? false : true
                  }
                  value={this.state.password2}
                  onChange={this.handleChange}
                  type='password'
                  name='password2'
                  placeholder='Re-enter New Password'
                />
                <Button
                  className='red-button'
                  type='submit'
                  disabled={
                    oldPassword &&
                    password1 &&
                    password2 &&
                    eightChar &&
                    number &&
                    specChar &&
                    upperCase &&
                    match
                      ? false
                      : true
                  }
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default connect(null, { submitUserInfo, signoutUser })(UpdatePassword);
