import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  deactivateSessions,
  getUserSessions,
} from '../../actions/accountSettings';
import { signoutUser } from '../../actions/authentication';

import { Button, Col, Form, FormGroup, Row, Table } from 'reactstrap';

class ActiveSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: 'info',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.renderSessions = this.renderSessions.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getUserSessions();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.deactivateSessions(this.props.csrfToken);
    this.props.signoutUser();
  }
  
  renderSessions(sessions) {
    return sessions.map((session) => {
      return (
        <tr key={session.id}>
          <td>
            {session.session_id}
          </td>
          <td>
            {session.created}
          </td>
        </tr>
      )
    });
  }

  render() {
    if (this.props.sessions) {
      return (
        <div id='active_sessions'>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Row>
                <Col lg='5'>
                  <h4>My Active Sessions</h4>
                  <hr />
                </Col>
                <Col lg='7'>
                  <Table>
                    <thead>
                      <tr>
                        <th>Session ID</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderSessions(this.props.sessions)}
                    </tbody>
                  </Table>
                  <Button type='submit' className='red-button'>
                    Deactivate All Sessions
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </div>
      );
    } else {
      return (<div>Loading...</div>);
    }
  }
}

function mapStateToProps(state) {
  return {
    sessions: state.accountSettings.sessions  };
}

export default connect(mapStateToProps, {
  deactivateSessions,
  getUserSessions,
  signoutUser
})(ActiveSessions);
