import { CONTACT_QUESTIONS } from "../actions/contact";

export default function(state = {}, action) {
  switch (action.type) {
    case CONTACT_QUESTIONS:
      return { ...state, questions: action.payload };
    default:
      return state;
  }
  // return state;
}
