import axios from 'axios';
// import { push } from 'connected-react-router';

// import { URL } from "../index";

export const USER_INFO = 'user_info';
export const INFO_SUCCESS = 'info_success';
export const INFO_ERROR = 'info_error';
export const SESSIONS = 'sessions';

export function getUser() {
  return function (dispatch) {
    axios
      .get(
        `/api/client/account/detail/`
      )
      .then((response) => {
        dispatch({
          type: USER_INFO,
          payload: response.data,
        });
      });
  };
}

export function submitUserInfo(data, csrfToken) {
  // console.log(data);
  return function (dispatch) {
    axios
      .post(`/api/client/account/update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        },
      })
      .then((response) => {
        // sessionStorage.clear();
        // dispatch(push('/'));
        // window.scrollTo(0, 0);
        dispatch({
          type: INFO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFO_ERROR,
          payload: error.response.data,
        });
      });
  };
}

export function deactivateSessions(csrfToken) {
  console.log(csrfToken);
  return function (dispatch) {
    axios
      .delete(`/api/client/auth/deactivate_sessions/`, {
        headers: {
          'X-CSRFToken': csrfToken
        }
      })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function getUserSessions() {
  return function (dispatch) {
    axios
      .get(`/api/client/auth/sessions/`)
      .then(res => {
        dispatch({
          type: SESSIONS,
          payload: res.data.sessions
        })
      })
      .catch(err => {
        console.log(err);
      });
  };
}
