export const sweQueryData = [
  {
    id: 2941,
    finalcode: 50025,
    cutcat: 'AllInc',
    cut: 1,
    pairedcon: 51,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 51,
    pairedtdccon: 50,
    surveycode: 'D_23',
    supvtotalcon: 61,
    actvtgtcon: 49,
    stelig0con: 9,
    stelig0copct: '░░.░',
    stelig1con: 53,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 51,
    havttgt1copct: '░░.░',
    ltielig0con: 41,
    ltielig0copct: '░░.░',
    ltielig1con: 20,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 53,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 62,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 51,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 49,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 62,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 16,
    ltaddlco5: '░░.░',
    ltaddlco10: '░░.░',
    ltaddlco15: '░░.░',
    ltaddlco20: '░░.░',
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: '░░.░',
    ltaddlco85: '░░.░',
    ltaddlco90: '░░.░',
    ltaddlco95: '░░.░',
    tdccon: 60,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 15,
    ltaddlongco5: '░░.░',
    ltaddlongco10: '░░.░',
    ltaddlongco15: '░░.░',
    ltaddlongco20: '░░.░',
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: '░░.░',
    ltaddlongco85: '░░.░',
    ltaddlongco90: '░░.░',
    ltaddlongco95: '░░.░',
    tdcongcon: 60,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 44,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 51,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 51,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 17,
    tgttdcco5: '░░.░',
    tgttdcco10: '░░.░',
    tgttdcco15: '░░.░',
    tgttdcco20: '░░.░',
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: '░░.░',
    tgttdcco85: '░░.░',
    tgttdcco90: '░░.░',
    tgttdcco95: '░░.░',
    ltitgtvaluecon: 14,
    ltitgtvalueco5: '░░.░',
    ltitgtvalueco10: '░░.░',
    ltitgtvalueco15: '░░.░',
    ltitgtvalueco20: '░░.░',
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: '░░.░',
    ltitgtvalueco85: '░░.░',
    ltitgtvalueco90: '░░.░',
    ltitgtvalueco95: '░░.░',
    ltipctbasecon: 16,
    ltipctbaseco5: '░░.░',
    ltipctbaseco10: '░░.░',
    ltipctbaseco15: '░░.░',
    ltipctbaseco20: '░░.░',
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: '░░.░',
    ltipctbaseco85: '░░.░',
    ltipctbaseco90: '░░.░',
    ltipctbaseco95: '░░.░',
    ltipctbaseongcon: 15,
    ltipctbaseongco5: '░░.░',
    ltipctbaseongco10: '░░.░',
    ltipctbaseongco15: '░░.░',
    ltipctbaseongco20: '░░.░',
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: '░░.░',
    ltipctbaseongco85: '░░.░',
    ltipctbaseongco90: '░░.░',
    ltipctbaseongco95: '░░.░',
    nummatchcon: 62,
    supvtotalincn: 4953,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 5795,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 1251,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 406,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 1506,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 134,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 515,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 1975,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 5553,
    flsa1incpct: '░░.░',
    flsa2incn: 242,
    flsa2incpct: '░░.░',
    stelig0incn: 385,
    stelig0incpct: '░░.░',
    stelig1incn: 5410,
    stelig1incpct: '░░.░',
    anystirecd0incn: 1480,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 3930,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 3801,
    actvtgtincmean: '░░.░',
    ltielig0incn: 3457,
    ltielig0incpct: '░░.░',
    ltielig1incn: 2325,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 235,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2083,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 5410,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 5410,
    slsplan24incpct: '░░.░',
    baseincn: 5795,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 5208,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 3872,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 5795,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 1928,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 5678,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 1667,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 5678,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 4908,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1529,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 1928,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 1667,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 1980,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 5795,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'All Incumbents',
    rptcut: 'All Incumbents',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2941,
  },
  {
    id: 2942,
    finalcode: 50025,
    cutcat: 'OwnershipCode',
    cut: 3,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 2,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 3,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 2,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 3,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 2,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 2,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 0,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 2,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 2,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 0,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 0,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 20,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 20,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 9,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 3,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 2,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 1,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 5,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 20,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 9,
    stelig0incpct: '░░.░',
    stelig1incn: 11,
    stelig1incpct: '░░.░',
    anystirecd0incn: 7,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 4,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 4,
    actvtgtincmean: null,
    ltielig0incn: 10,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 4,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 11,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 11,
    slsplan24incpct: '░░.░',
    baseincn: 20,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 6,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 4,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 20,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 0,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 14,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 14,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 20,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 0,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 0,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 20,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Ownership',
    rptcut: 'Digital Company - Private',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2942,
  },
  {
    id: 2943,
    finalcode: 50025,
    cutcat: 'OwnershipCode',
    cut: 1,
    pairedcon: 10,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 10,
    pairedtdccon: 9,
    surveycode: 'D_23',
    supvtotalcon: 11,
    actvtgtcon: 8,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 9,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 8,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 9,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 11,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 9,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 11,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 7,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 6,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 11,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 8,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 7,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 7,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 6,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 11,
    supvtotalincn: 949,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 949,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 235,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 30,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 38,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 6,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 39,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 601,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 773,
    flsa1incpct: '░░.░',
    flsa2incn: 176,
    flsa2incpct: '░░.░',
    stelig0incn: 82,
    stelig0incpct: '░░.░',
    stelig1incn: 867,
    stelig1incpct: '░░.░',
    anystirecd0incn: 209,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 658,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 538,
    actvtgtincmean: '░░.░',
    ltielig0incn: 92,
    ltielig0incpct: '░░.░',
    ltielig1incn: 857,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 172,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 679,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 867,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 867,
    slsplan24incpct: '░░.░',
    baseincn: 949,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 723,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 657,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 949,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 582,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 846,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 403,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 846,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 949,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 516,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 582,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 403,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 596,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 949,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Ownership',
    rptcut: 'Digital Company - Public',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2943,
  },
  {
    id: 2944,
    finalcode: 50025,
    cutcat: 'OwnershipCode',
    cut: 2,
    pairedcon: 37,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 37,
    pairedtdccon: 37,
    surveycode: 'D_23',
    supvtotalcon: 44,
    actvtgtcon: 39,
    stelig0con: 4,
    stelig0copct: '░░.░',
    stelig1con: 41,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 41,
    havttgt1copct: '░░.░',
    ltielig0con: 36,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 41,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 45,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 41,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 38,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 45,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 8,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 45,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 45,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 27,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 41,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 41,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 8,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 8,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 45,
    supvtotalincn: 3199,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 4041,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 892,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 358,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 1447,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 125,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 468,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 743,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 4021,
    flsa1incpct: '░░.░',
    flsa2incn: 20,
    flsa2incpct: '░░.░',
    stelig0incn: 287,
    stelig0incpct: '░░.░',
    stelig1incn: 3754,
    stelig1incpct: '░░.░',
    anystirecd0incn: 987,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 2767,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 2767,
    actvtgtincmean: '░░.░',
    ltielig0incn: 3283,
    ltielig0incpct: '░░.░',
    ltielig1incn: 757,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 33,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 724,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 3754,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 3754,
    slsplan24incpct: '░░.░',
    baseincn: 4041,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 3751,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 2716,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 4041,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 711,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 4040,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 711,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 4040,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 3154,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 488,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 711,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 711,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 756,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 4041,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Ownership',
    rptcut: 'Digital Unit / Positions',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2944,
  },
  {
    id: 2945,
    finalcode: 50025,
    cutcat: 'IndustryCode',
    cut: 3,
    pairedcon: 13,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 13,
    pairedtdccon: 13,
    surveycode: 'D_23',
    supvtotalcon: 13,
    actvtgtcon: 12,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 13,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 13,
    havttgt1copct: '░░.░',
    ltielig0con: 11,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 13,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 14,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 13,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 11,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 14,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 14,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 14,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 11,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 13,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 13,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 14,
    supvtotalincn: 727,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 969,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 135,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 43,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 396,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 35,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 115,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 245,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 969,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 16,
    stelig0incpct: '░░.░',
    stelig1incn: 953,
    stelig1incpct: '░░.░',
    anystirecd0incn: 368,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 585,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 542,
    actvtgtincmean: '░░.░',
    ltielig0incn: 789,
    ltielig0incpct: '░░.░',
    ltielig1incn: 180,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 74,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 106,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 953,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 953,
    slsplan24incpct: '░░.░',
    baseincn: 969,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 910,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 566,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 969,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 93,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 969,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 93,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 969,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 801,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 129,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 93,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 93,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 137,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 969,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Industry',
    rptcut: 'E-Commerce Retail',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2945,
  },
  {
    id: 2946,
    finalcode: 50025,
    cutcat: 'IndustryCode',
    cut: 6,
    pairedcon: 17,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 17,
    pairedtdccon: 17,
    surveycode: 'D_23',
    supvtotalcon: 21,
    actvtgtcon: 19,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 19,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 19,
    havttgt1copct: '░░.░',
    ltielig0con: 12,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 19,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 21,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 19,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 19,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 21,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 8,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 21,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 21,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 13,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 19,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 19,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 7,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 8,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 21,
    supvtotalincn: 1242,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 1242,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 433,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 208,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 93,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 52,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 123,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 329,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 1228,
    flsa1incpct: '░░.░',
    flsa2incn: 14,
    flsa2incpct: '░░.░',
    stelig0incn: 197,
    stelig0incpct: '░░.░',
    stelig1incn: 1045,
    stelig1incpct: '░░.░',
    anystirecd0incn: 328,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 717,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 717,
    actvtgtincmean: '░░.░',
    ltielig0incn: 644,
    ltielig0incpct: '░░.░',
    ltielig1incn: 598,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 32,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 566,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1045,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1045,
    slsplan24incpct: '░░.░',
    baseincn: 1242,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 1042,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 713,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 1242,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 566,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 1242,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 546,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 1242,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 1013,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 457,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 566,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 546,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 514,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 1242,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Industry',
    rptcut: 'Media / Entertainment',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2946,
  },
  {
    id: 2947,
    finalcode: 50025,
    cutcat: 'IndustryCode',
    cut: 7,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 4,
    stelig0con: 5,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 0,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 0,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 9,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 9,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 7,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 0,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 0,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 310,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 310,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 220,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 17,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 47,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 3,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 9,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 13,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 303,
    flsa1incpct: '░░.░',
    flsa2incn: 7,
    flsa2incpct: '░░.░',
    stelig0incn: 166,
    stelig0incpct: '░░.░',
    stelig1incn: 144,
    stelig1incpct: '░░.░',
    anystirecd0incn: 27,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 117,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 117,
    actvtgtincmean: null,
    ltielig0incn: 298,
    ltielig0incpct: '░░.░',
    ltielig1incn: 0,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 0,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 144,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 144,
    slsplan24incpct: '░░.░',
    baseincn: 310,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 144,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 117,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 310,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 0,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 298,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 298,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 155,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 0,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 0,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 310,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Industry',
    rptcut: 'Publishing',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2947,
  },
  {
    id: 2948,
    finalcode: 50025,
    cutcat: 'IndustryCode',
    cut: 9,
    pairedcon: 6,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 6,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 4,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 6,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 4,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 264,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 264,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 33,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 21,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 11,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 10,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 188,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 205,
    flsa1incpct: '░░.░',
    flsa2incn: 59,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 264,
    stelig1incpct: '░░.░',
    anystirecd0incn: 93,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 171,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 133,
    actvtgtincmean: null,
    ltielig0incn: 88,
    ltielig0incpct: '░░.░',
    ltielig1incn: 176,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 26,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 149,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 264,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 264,
    slsplan24incpct: '░░.░',
    baseincn: 264,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 176,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 171,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 264,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 43,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 197,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 3,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 197,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 264,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 65,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 43,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 3,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 98,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 264,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Industry',
    rptcut: 'Software Games',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2948,
  },
  {
    id: 2949,
    finalcode: 50025,
    cutcat: 'PlatformCode',
    cut: 1,
    pairedcon: 18,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 18,
    pairedtdccon: 18,
    surveycode: 'D_23',
    supvtotalcon: 28,
    actvtgtcon: 22,
    stelig0con: 4,
    stelig0copct: '░░.░',
    stelig1con: 24,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 23,
    havttgt1copct: '░░.░',
    ltielig0con: 20,
    ltielig0copct: '░░.░',
    ltielig1con: 7,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 24,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 28,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 23,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 22,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 28,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 5,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 27,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 27,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 19,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 23,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 23,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 5,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 28,
    supvtotalincn: 1649,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 1649,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 267,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 80,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 293,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 48,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 65,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 888,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 1593,
    flsa1incpct: '░░.░',
    flsa2incn: 56,
    flsa2incpct: '░░.░',
    stelig0incn: 88,
    stelig0incpct: '░░.░',
    stelig1incn: 1561,
    stelig1incpct: '░░.░',
    anystirecd0incn: 645,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 916,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 908,
    actvtgtincmean: '░░.░',
    ltielig0incn: 818,
    ltielig0incpct: '░░.░',
    ltielig1incn: 819,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 68,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 751,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1561,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1561,
    slsplan24incpct: '░░.░',
    baseincn: 1649,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 1537,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 915,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 1649,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 732,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 1637,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 667,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 1637,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 1330,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 725,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 732,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 667,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 771,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 1649,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Platform',
    rptcut: 'Online',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2949,
  },
  {
    id: 2950,
    finalcode: 50025,
    cutcat: 'PlatformCode',
    cut: 2,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 6,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 6,
    havttgt1copct: '░░.░',
    ltielig0con: 1,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 6,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 5,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 6,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 5,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 5,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 184,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 184,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 48,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 30,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 7,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 14,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 13,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 72,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 154,
    flsa1incpct: '░░.░',
    flsa2incn: 30,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 184,
    stelig1incpct: '░░.░',
    anystirecd0incn: 37,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 147,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 140,
    actvtgtincmean: '░░.░',
    ltielig0incn: 14,
    ltielig0incpct: '░░.░',
    ltielig1incn: 170,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 24,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 146,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 184,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 184,
    slsplan24incpct: '░░.░',
    baseincn: 184,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 172,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 147,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 184,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 146,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 184,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 111,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 184,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 162,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 78,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 146,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 111,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 143,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 184,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Platform',
    rptcut: 'Mobile',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2950,
  },
  {
    id: 2951,
    finalcode: 50025,
    cutcat: 'PlatformCode',
    cut: 4,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 12,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 12,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 2,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 2,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 2,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 5,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 10,
    flsa1incpct: '░░.░',
    flsa2incn: 2,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 12,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 10,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 10,
    actvtgtincmean: '░░.░',
    ltielig0incn: 6,
    ltielig0incpct: '░░.░',
    ltielig1incn: 6,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 3,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 3,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 12,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 12,
    slsplan24incpct: '░░.░',
    baseincn: 12,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 10,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 10,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 12,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 3,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 12,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 3,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 12,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 11,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 3,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 3,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 3,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 3,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 12,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Platform',
    rptcut: 'Cloud',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2951,
  },
  {
    id: 2952,
    finalcode: 50025,
    cutcat: 'PlatformCode',
    cut: 6,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 3,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 40,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 40,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 10,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 6,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 1,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 7,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 15,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 37,
    flsa1incpct: '░░.░',
    flsa2incn: 3,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 40,
    stelig1incpct: '░░.░',
    anystirecd0incn: 5,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 35,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 30,
    actvtgtincmean: '░░.░',
    ltielig0incn: 31,
    ltielig0incpct: '░░.░',
    ltielig1incn: 9,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 3,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 40,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 40,
    slsplan24incpct: '░░.░',
    baseincn: 40,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 35,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 34,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 40,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 3,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 34,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 34,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 28,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 9,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 3,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 9,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 40,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Platform',
    rptcut: 'Multiple Media / Platforms',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2952,
  },
  {
    id: 2953,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 1,
    pairedcon: 34,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 34,
    pairedtdccon: 34,
    surveycode: 'D_23',
    supvtotalcon: 41,
    actvtgtcon: 28,
    stelig0con: 9,
    stelig0copct: '░░.░',
    stelig1con: 33,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 32,
    havttgt1copct: '░░.░',
    ltielig0con: 28,
    ltielig0copct: '░░.░',
    ltielig1con: 13,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 33,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 42,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 32,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 28,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 42,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 11,
    ltaddlco5: '░░.░',
    ltaddlco10: '░░.░',
    ltaddlco15: '░░.░',
    ltaddlco20: '░░.░',
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: '░░.░',
    ltaddlco85: '░░.░',
    ltaddlco90: '░░.░',
    ltaddlco95: '░░.░',
    tdccon: 41,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 10,
    ltaddlongco5: '░░.░',
    ltaddlongco10: '░░.░',
    ltaddlongco15: '░░.░',
    ltaddlongco20: '░░.░',
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: '░░.░',
    ltaddlongco85: '░░.░',
    ltaddlongco90: '░░.░',
    ltaddlongco95: '░░.░',
    tdcongcon: 41,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 32,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 32,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 32,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 10,
    tgttdcco5: '░░.░',
    tgttdcco10: '░░.░',
    tgttdcco15: '░░.░',
    tgttdcco20: '░░.░',
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: '░░.░',
    tgttdcco85: '░░.░',
    tgttdcco90: '░░.░',
    tgttdcco95: '░░.░',
    ltitgtvaluecon: 7,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 11,
    ltipctbaseco5: '░░.░',
    ltipctbaseco10: '░░.░',
    ltipctbaseco15: '░░.░',
    ltipctbaseco20: '░░.░',
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: '░░.░',
    ltipctbaseco85: '░░.░',
    ltipctbaseco90: '░░.░',
    ltipctbaseco95: '░░.░',
    ltipctbaseongcon: 10,
    ltipctbaseongco5: '░░.░',
    ltipctbaseongco10: '░░.░',
    ltipctbaseongco15: '░░.░',
    ltipctbaseongco20: '░░.░',
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: '░░.░',
    ltipctbaseongco85: '░░.░',
    ltipctbaseongco90: '░░.░',
    ltipctbaseongco95: '░░.░',
    nummatchcon: 42,
    supvtotalincn: 1241,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 1251,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 1251,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 1227,
    flsa1incpct: '░░.░',
    flsa2incn: 24,
    flsa2incpct: '░░.░',
    stelig0incn: 235,
    stelig0incpct: '░░.░',
    stelig1incn: 1016,
    stelig1incpct: '░░.░',
    anystirecd0incn: 226,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 790,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 743,
    actvtgtincmean: '░░.░',
    ltielig0incn: 747,
    ltielig0incpct: '░░.░',
    ltielig1incn: 496,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 62,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 433,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1016,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1016,
    slsplan24incpct: '░░.░',
    baseincn: 1251,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 959,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 782,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 1251,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 425,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 1242,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 404,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 1242,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 1072,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 392,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 425,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 404,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 373,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 1251,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'East',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2953,
  },
  {
    id: 2954,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 2,
    pairedcon: 26,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 26,
    pairedtdccon: 25,
    surveycode: 'D_23',
    supvtotalcon: 30,
    actvtgtcon: 24,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 28,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 27,
    havttgt1copct: '░░.░',
    ltielig0con: 21,
    ltielig0copct: '░░.░',
    ltielig1con: 10,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 28,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 31,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 27,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 23,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 31,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 8,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 31,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 7,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 31,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 24,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 27,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 27,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 8,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 7,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 31,
    supvtotalincn: 399,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 406,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 406,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 400,
    flsa1incpct: '░░.░',
    flsa2incn: 6,
    flsa2incpct: '░░.░',
    stelig0incn: 35,
    stelig0incpct: '░░.░',
    stelig1incn: 371,
    stelig1incpct: '░░.░',
    anystirecd0incn: 157,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 214,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 208,
    actvtgtincmean: '░░.░',
    ltielig0incn: 294,
    ltielig0incpct: '░░.░',
    ltielig1incn: 112,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 18,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 94,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 371,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 371,
    slsplan24incpct: '░░.░',
    baseincn: 406,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 350,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 212,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 406,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 72,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 406,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 63,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 406,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 340,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 76,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 72,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 63,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 86,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 406,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'South',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2954,
  },
  {
    id: 2955,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 3,
    pairedcon: 28,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 28,
    pairedtdccon: 26,
    surveycode: 'D_23',
    supvtotalcon: 33,
    actvtgtcon: 24,
    stelig0con: 6,
    stelig0copct: '░░.░',
    stelig1con: 28,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 27,
    havttgt1copct: '░░.░',
    ltielig0con: 20,
    ltielig0copct: '░░.░',
    ltielig1con: 13,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 28,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 34,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 27,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 24,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 34,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 9,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 32,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 32,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 26,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 27,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 27,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 11,
    tgttdcco5: '░░.░',
    tgttdcco10: '░░.░',
    tgttdcco15: '░░.░',
    tgttdcco20: '░░.░',
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: '░░.░',
    tgttdcco85: '░░.░',
    tgttdcco90: '░░.░',
    tgttdcco95: '░░.░',
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 9,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 34,
    supvtotalincn: 728,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 970,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 970,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 965,
    flsa1incpct: '░░.░',
    flsa2incn: 5,
    flsa2incpct: '░░.░',
    stelig0incn: 46,
    stelig0incpct: '░░.░',
    stelig1incn: 924,
    stelig1incpct: '░░.░',
    anystirecd0incn: 180,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 744,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 726,
    actvtgtincmean: '░░.░',
    ltielig0incn: 838,
    ltielig0incpct: '░░.░',
    ltielig1incn: 131,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 17,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 114,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 924,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 924,
    slsplan24incpct: '░░.░',
    baseincn: 970,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 903,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 732,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 970,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 109,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 968,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 96,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 968,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 646,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 44,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 109,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 96,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 108,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 970,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'Midwest',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2955,
  },
  {
    id: 2956,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 4,
    pairedcon: 17,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 17,
    pairedtdccon: 16,
    surveycode: 'D_23',
    supvtotalcon: 19,
    actvtgtcon: 15,
    stelig0con: 4,
    stelig0copct: '░░.░',
    stelig1con: 16,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 16,
    havttgt1copct: '░░.░',
    ltielig0con: 14,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 16,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 20,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 16,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 15,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 20,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 4,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 18,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 18,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 14,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 16,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 16,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 5,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 4,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 20,
    supvtotalincn: 122,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 134,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 134,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 131,
    flsa1incpct: '░░.░',
    flsa2incn: 3,
    flsa2incpct: '░░.░',
    stelig0incn: 20,
    stelig0incpct: '░░.░',
    stelig1incn: 114,
    stelig1incpct: '░░.░',
    anystirecd0incn: 38,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 76,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 76,
    actvtgtincmean: '░░.░',
    ltielig0incn: 112,
    ltielig0incpct: '░░.░',
    ltielig1incn: 21,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 19,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 114,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 114,
    slsplan24incpct: '░░.░',
    baseincn: 134,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 114,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 76,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 134,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 19,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 132,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 14,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 132,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 97,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 6,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 19,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 14,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 20,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 134,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'Mountain',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2956,
  },
  {
    id: 2957,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 5,
    pairedcon: 25,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 25,
    pairedtdccon: 25,
    surveycode: 'D_23',
    supvtotalcon: 34,
    actvtgtcon: 28,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 32,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 32,
    havttgt1copct: '░░.░',
    ltielig0con: 25,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 32,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 35,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 32,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 29,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 35,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 9,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 34,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 34,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 24,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 32,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 32,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 9,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 35,
    supvtotalincn: 432,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 438,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 438,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 419,
    flsa1incpct: '░░.░',
    flsa2incn: 19,
    flsa2incpct: '░░.░',
    stelig0incn: 24,
    stelig0incpct: '░░.░',
    stelig1incn: 414,
    stelig1incpct: '░░.░',
    anystirecd0incn: 123,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 291,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 288,
    actvtgtincmean: '░░.░',
    ltielig0incn: 377,
    ltielig0incpct: '░░.░',
    ltielig1incn: 60,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 12,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 48,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 414,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 414,
    slsplan24incpct: '░░.░',
    baseincn: 438,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 406,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 289,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 438,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 48,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 437,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 32,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 437,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 274,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 21,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 48,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 32,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 49,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 438,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'Southwest',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2957,
  },
  {
    id: 2958,
    finalcode: 50025,
    cutcat: 'TCC_RegionCode',
    cut: 6,
    pairedcon: 35,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 35,
    pairedtdccon: 34,
    surveycode: 'D_23',
    supvtotalcon: 43,
    actvtgtcon: 36,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 42,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 40,
    havttgt1copct: '░░.░',
    ltielig0con: 27,
    ltielig0copct: '░░.░',
    ltielig1con: 16,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 42,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 44,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 40,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 37,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 44,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 13,
    ltaddlco5: '░░.░',
    ltaddlco10: '░░.░',
    ltaddlco15: '░░.░',
    ltaddlco20: '░░.░',
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: '░░.░',
    ltaddlco85: '░░.░',
    ltaddlco90: '░░.░',
    ltaddlco95: '░░.░',
    tdccon: 42,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 12,
    ltaddlongco5: '░░.░',
    ltaddlongco10: '░░.░',
    ltaddlongco15: '░░.░',
    ltaddlongco20: '░░.░',
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: '░░.░',
    ltaddlongco85: '░░.░',
    ltaddlongco90: '░░.░',
    ltaddlongco95: '░░.░',
    tdcongcon: 42,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 34,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 40,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 40,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 15,
    tgttdcco5: '░░.░',
    tgttdcco10: '░░.░',
    tgttdcco15: '░░.░',
    tgttdcco20: '░░.░',
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: '░░.░',
    tgttdcco85: '░░.░',
    tgttdcco90: '░░.░',
    tgttdcco95: '░░.░',
    ltitgtvaluecon: 10,
    ltitgtvalueco5: '░░.░',
    ltitgtvalueco10: '░░.░',
    ltitgtvalueco15: '░░.░',
    ltitgtvalueco20: '░░.░',
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: '░░.░',
    ltitgtvalueco85: '░░.░',
    ltitgtvalueco90: '░░.░',
    ltitgtvalueco95: '░░.░',
    ltipctbasecon: 13,
    ltipctbaseco5: '░░.░',
    ltipctbaseco10: '░░.░',
    ltipctbaseco15: '░░.░',
    ltipctbaseco20: '░░.░',
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: '░░.░',
    ltipctbaseco85: '░░.░',
    ltipctbaseco90: '░░.░',
    ltipctbaseco95: '░░.░',
    ltipctbaseongcon: 12,
    ltipctbaseongco5: '░░.░',
    ltipctbaseongco10: '░░.░',
    ltipctbaseongco15: '░░.░',
    ltipctbaseongco20: '░░.░',
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: '░░.░',
    ltipctbaseongco85: '░░.░',
    ltipctbaseongco90: '░░.░',
    ltipctbaseongco95: '░░.░',
    nummatchcon: 44,
    supvtotalincn: 1655,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 1684,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 1684,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 1499,
    flsa1incpct: '░░.░',
    flsa2incn: 185,
    flsa2incpct: '░░.░',
    stelig0incn: 24,
    stelig0incpct: '░░.░',
    stelig1incn: 1660,
    stelig1incpct: '░░.░',
    anystirecd0incn: 608,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 1052,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 997,
    actvtgtincmean: '░░.░',
    ltielig0incn: 624,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1059,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 115,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 939,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1660,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1660,
    slsplan24incpct: '░░.░',
    baseincn: 1684,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 1565,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 1049,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 1684,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 820,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 1582,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 642,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 1582,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 1571,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 710,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 820,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 642,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 908,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 1684,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'West',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2958,
  },
  {
    id: 2959,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1100,
    pairedcon: 11,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 11,
    pairedtdccon: 10,
    surveycode: 'D_23',
    supvtotalcon: 19,
    actvtgtcon: 11,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 16,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 14,
    havttgt1copct: '░░.░',
    ltielig0con: 12,
    ltielig0copct: '░░.░',
    ltielig1con: 7,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 16,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 19,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 14,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 12,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 19,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 19,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 19,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 17,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 14,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 14,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 5,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 4,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 19,
    supvtotalincn: 98,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 98,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 98,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 95,
    flsa1incpct: '░░.░',
    flsa2incn: 3,
    flsa2incpct: '░░.░',
    stelig0incn: 7,
    stelig0incpct: '░░.░',
    stelig1incn: 91,
    stelig1incpct: '░░.░',
    anystirecd0incn: 22,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 69,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 58,
    actvtgtincmean: '░░.░',
    ltielig0incn: 57,
    ltielig0incpct: '░░.░',
    ltielig1incn: 41,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 19,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 22,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 91,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 91,
    slsplan24incpct: '░░.░',
    baseincn: 98,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 76,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 69,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 98,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 18,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 98,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 15,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 98,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 86,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 33,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 18,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 15,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 30,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 98,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Boston-Cambridge-Newton',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2959,
  },
  {
    id: 2960,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1101,
    pairedcon: 1,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 1,
    pairedtdccon: 1,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 5,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 5,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 5,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 4,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 5,
    stelig1incpct: '░░.░',
    anystirecd0incn: 1,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 4,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 4,
    actvtgtincmean: null,
    ltielig0incn: 4,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 1,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 5,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 5,
    slsplan24incpct: '░░.░',
    baseincn: 5,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: null,
    baseinc30: null,
    baseinc35: null,
    baseinc40: null,
    baseinc45: null,
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: null,
    baseinc60: null,
    baseinc65: null,
    baseinc70: null,
    baseinc75: null,
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 5,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 4,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 5,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: null,
    tccinc30: null,
    tccinc35: null,
    tccinc40: null,
    tccinc45: null,
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: null,
    tccinc60: null,
    tccinc65: null,
    tccinc70: null,
    tccinc75: null,
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 1,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 5,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: null,
    tdcinc30: null,
    tdcinc35: null,
    tdcinc40: null,
    tdcinc45: null,
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: null,
    tdcinc60: null,
    tdcinc65: null,
    tdcinc70: null,
    tdcinc75: null,
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 5,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: null,
    tdconginc30: null,
    tdconginc35: null,
    tdconginc40: null,
    tdconginc45: null,
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: null,
    tdconginc60: null,
    tdconginc65: null,
    tdconginc70: null,
    tdconginc75: null,
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 4,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 1,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 5,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Providence-Warwick',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2960,
  },
  {
    id: 2961,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1203,
    pairedcon: 24,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 24,
    pairedtdccon: 24,
    surveycode: 'D_23',
    supvtotalcon: 35,
    actvtgtcon: 18,
    stelig0con: 10,
    stelig0copct: '░░.░',
    stelig1con: 26,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 25,
    havttgt1copct: '░░.░',
    ltielig0con: 23,
    ltielig0copct: '░░.░',
    ltielig1con: 12,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 26,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 36,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 25,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 19,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 36,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 9,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 35,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 35,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 29,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 25,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 25,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 7,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 9,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 36,
    supvtotalincn: 526,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 527,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 527,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 515,
    flsa1incpct: '░░.░',
    flsa2incn: 12,
    flsa2incpct: '░░.░',
    stelig0incn: 155,
    stelig0incpct: '░░.░',
    stelig1incn: 372,
    stelig1incpct: '░░.░',
    anystirecd0incn: 95,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 277,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 262,
    actvtgtincmean: '░░.░',
    ltielig0incn: 234,
    ltielig0incpct: '░░.░',
    ltielig1incn: 290,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 14,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 276,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 372,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 372,
    slsplan24incpct: '░░.░',
    baseincn: 527,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 353,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 276,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 527,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 273,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 524,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 266,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 524,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 471,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 265,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 273,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 266,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 204,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 527,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'New York-Newark-Jersey City',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2961,
  },
  {
    id: 2962,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1204,
    pairedcon: 7,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 7,
    pairedtdccon: 7,
    surveycode: 'D_23',
    supvtotalcon: 12,
    actvtgtcon: 10,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 12,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 12,
    havttgt1copct: '░░.░',
    ltielig0con: 8,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 12,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 13,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 12,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 10,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 13,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 4,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 12,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 4,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 12,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 12,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 12,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 12,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 4,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 4,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 13,
    supvtotalincn: 29,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 32,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 32,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 32,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 30,
    stelig1incpct: '░░.░',
    anystirecd0incn: 11,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 19,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 19,
    actvtgtincmean: '░░.░',
    ltielig0incn: 21,
    ltielig0incpct: '░░.░',
    ltielig1incn: 10,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 10,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 30,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 30,
    slsplan24incpct: '░░.░',
    baseincn: 32,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 30,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 18,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 32,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 10,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 31,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 10,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 31,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 31,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 4,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 10,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 10,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 10,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 32,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Philadelphia-Camden-Wilmington',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2962,
  },
  {
    id: 2963,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1205,
    pairedcon: 13,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 13,
    pairedtdccon: 13,
    surveycode: 'D_23',
    supvtotalcon: 22,
    actvtgtcon: 15,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 20,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 19,
    havttgt1copct: '░░.░',
    ltielig0con: 14,
    ltielig0copct: '░░.░',
    ltielig1con: 8,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 20,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 23,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 18,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 16,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 23,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 7,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 21,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 7,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 21,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 18,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 18,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 18,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 7,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 7,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 23,
    supvtotalincn: 90,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 91,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 91,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 91,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 19,
    stelig0incpct: '░░.░',
    stelig1incn: 72,
    stelig1incpct: '░░.░',
    anystirecd0incn: 19,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 53,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 52,
    actvtgtincmean: '░░.░',
    ltielig0incn: 70,
    ltielig0incpct: '░░.░',
    ltielig1incn: 18,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 16,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 72,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 72,
    slsplan24incpct: '░░.░',
    baseincn: 91,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 70,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 51,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 91,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 16,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 87,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 15,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 87,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 53,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 9,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 16,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 15,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 16,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 91,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Washington-Arlington-Alexandria',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2963,
  },
  {
    id: 2964,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1207,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 3,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 3,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 12,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 13,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 13,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 11,
    flsa1incpct: '░░.░',
    flsa2incn: 2,
    flsa2incpct: '░░.░',
    stelig0incn: 3,
    stelig0incpct: '░░.░',
    stelig1incn: 10,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 7,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 7,
    actvtgtincmean: null,
    ltielig0incn: 10,
    ltielig0incpct: '░░.░',
    ltielig1incn: 3,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 10,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 10,
    slsplan24incpct: '░░.░',
    baseincn: 13,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 10,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 7,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 13,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 13,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 13,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 10,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 2,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 13,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Albany-Schenectady-Troy',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2964,
  },
  {
    id: 2965,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1224,
    pairedcon: 2,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 2,
    pairedtdccon: 2,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 6,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 9,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 9,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 6,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 6,
    actvtgtincmean: null,
    ltielig0incn: 7,
    ltielig0incpct: '░░.░',
    ltielig1incn: 2,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 9,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 9,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 6,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 1,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 9,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 1,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Pittsburgh',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2965,
  },
  {
    id: 2966,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1232,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 3,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 3,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 3,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 3,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 3,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 3,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 3,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 3,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 9,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 8,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 4,
    stelig0incpct: '░░.░',
    stelig1incn: 5,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 3,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 3,
    actvtgtincmean: null,
    ltielig0incn: 4,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 3,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 5,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 5,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 5,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 3,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 3,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 7,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 3,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 3,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Trenton-Princeton',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2966,
  },
  {
    id: 2967,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 1235,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 7,
    actvtgtcon: 3,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 4,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 7,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 3,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 7,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 7,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 7,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 7,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 4,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 7,
    supvtotalincn: 10,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 10,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 10,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 3,
    stelig0incpct: '░░.░',
    stelig1incn: 7,
    stelig1incpct: '░░.░',
    anystirecd0incn: 1,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 6,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 5,
    actvtgtincmean: null,
    ltielig0incn: 6,
    ltielig0incpct: '░░.░',
    ltielig1incn: 4,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 3,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 7,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 7,
    slsplan24incpct: '░░.░',
    baseincn: 10,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 6,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 5,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 10,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 3,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 10,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 3,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 10,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 10,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 3,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 3,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 3,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 10,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Baltimore-Columbia-Towson',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2967,
  },
  {
    id: 2968,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2101,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 11,
    actvtgtcon: 6,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 10,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 10,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 11,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 10,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 11,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 11,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 11,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 9,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 10,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 10,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 11,
    supvtotalincn: 19,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 19,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 19,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 16,
    flsa1incpct: '░░.░',
    flsa2incn: 3,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 18,
    stelig1incpct: '░░.░',
    anystirecd0incn: 10,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 8,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 8,
    actvtgtincmean: '░░.░',
    ltielig0incn: 13,
    ltielig0incpct: '░░.░',
    ltielig1incn: 6,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 4,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 18,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 18,
    slsplan24incpct: '░░.░',
    baseincn: 19,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 17,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 6,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 19,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 4,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 19,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 2,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 19,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 16,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 2,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 4,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 2,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 4,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 19,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Charlotte-Concord-Gastonia',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2968,
  },
  {
    id: 2969,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2113,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 7,
    actvtgtcon: 3,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 4,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 7,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 3,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 7,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 7,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 7,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 3,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 3,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 7,
    supvtotalincn: 20,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 20,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 20,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 20,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 6,
    stelig0incpct: '░░.░',
    stelig1incn: 14,
    stelig1incpct: '░░.░',
    anystirecd0incn: 6,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 8,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 7,
    actvtgtincmean: null,
    ltielig0incn: 9,
    ltielig0incpct: '░░.░',
    ltielig1incn: 11,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 10,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 14,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 14,
    slsplan24incpct: '░░.░',
    baseincn: 20,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 8,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 20,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 5,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 20,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 5,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 20,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 18,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 6,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 5,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 5,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 5,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 20,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Raleigh-Cary',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2969,
  },
  {
    id: 2970,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2127,
    pairedcon: 10,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 10,
    pairedtdccon: 10,
    surveycode: 'D_23',
    supvtotalcon: 13,
    actvtgtcon: 10,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 12,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 12,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 7,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 12,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 13,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 12,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 11,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 13,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 13,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 13,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 10,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 12,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 12,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 13,
    supvtotalincn: 113,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 113,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 113,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 112,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 111,
    stelig1incpct: '░░.░',
    anystirecd0incn: 52,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 59,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 58,
    actvtgtincmean: '░░.░',
    ltielig0incn: 80,
    ltielig0incpct: '░░.░',
    ltielig1incn: 33,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 31,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 111,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 111,
    slsplan24incpct: '░░.░',
    baseincn: 113,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 110,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 59,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 113,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 31,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 113,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 29,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 113,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 81,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 26,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 31,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 29,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 31,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 113,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Atlanta-Sandy Springs-Alpharetta',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2970,
  },
  {
    id: 2971,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2143,
    pairedcon: 8,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 8,
    pairedtdccon: 8,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 8,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 9,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 9,
    havttgt1copct: '░░.░',
    ltielig0con: 7,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 9,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 9,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 8,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 8,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 9,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 9,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 26,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 26,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 26,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 26,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 3,
    stelig0incpct: '░░.░',
    stelig1incn: 23,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 21,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 20,
    actvtgtincmean: '░░.░',
    ltielig0incn: 15,
    ltielig0incpct: '░░.░',
    ltielig1incn: 11,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 5,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 6,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 23,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 23,
    slsplan24incpct: '░░.░',
    baseincn: 26,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 22,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 21,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 26,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 6,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 26,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 5,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 26,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 21,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 9,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 6,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 5,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 10,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 26,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Miami-Fort Lauderdale-Pompano Beach',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2971,
  },
  {
    id: 2972,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2146,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 8,
    actvtgtcon: 5,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 7,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 7,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 7,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 8,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 8,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 8,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 8,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 6,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 8,
    supvtotalincn: 13,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 13,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 13,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 13,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 3,
    stelig0incpct: '░░.░',
    stelig1incn: 10,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 7,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 6,
    actvtgtincmean: '░░.░',
    ltielig0incn: 8,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 4,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 10,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 10,
    slsplan24incpct: '░░.░',
    baseincn: 13,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 9,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 7,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 13,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 4,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 13,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 4,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 13,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 9,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 5,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 4,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 4,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 4,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 13,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Orlando-Kissimmee-Sanford',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2972,
  },
  {
    id: 2973,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 2154,
    pairedcon: 2,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 2,
    pairedtdccon: 2,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 4,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 9,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 8,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 6,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 6,
    actvtgtincmean: null,
    ltielig0incn: 5,
    ltielig0incpct: '░░.░',
    ltielig1incn: 4,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 4,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 8,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 8,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 6,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 2,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 8,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 4,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 2,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 4,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Tampa-St. Petersburg-Clearwater',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2973,
  },
  {
    id: 2974,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3100,
    pairedcon: 11,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 11,
    pairedtdccon: 11,
    surveycode: 'D_23',
    supvtotalcon: 17,
    actvtgtcon: 12,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 17,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 15,
    havttgt1copct: '░░.░',
    ltielig0con: 13,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 17,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 18,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 15,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 12,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 18,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 18,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 18,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 13,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 15,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 15,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 18,
    supvtotalincn: 117,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 121,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 121,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 121,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 120,
    stelig1incpct: '░░.░',
    anystirecd0incn: 54,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 66,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 66,
    actvtgtincmean: '░░.░',
    ltielig0incn: 110,
    ltielig0incpct: '░░.░',
    ltielig1incn: 11,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 4,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 7,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 120,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 120,
    slsplan24incpct: '░░.░',
    baseincn: 121,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 118,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 66,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 121,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 6,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 121,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 6,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 121,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 66,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 8,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 6,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 6,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 9,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 121,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Chicago-Naperville-Elgin',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2974,
  },
  {
    id: 2975,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3105,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 6,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 6,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 6,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 9,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 9,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 6,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 6,
    actvtgtincmean: null,
    ltielig0incn: 7,
    ltielig0incpct: '░░.░',
    ltielig1incn: 2,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 9,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 9,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 9,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 6,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 2,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 9,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 2,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 2,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 2,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Kansas City',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2975,
  },
  {
    id: 2976,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3107,
    pairedcon: 7,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 7,
    pairedtdccon: 7,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 6,
    stelig0con: 3,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 8,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 6,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 8,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 11,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 11,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 4,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 11,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 4,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 11,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 10,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 8,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 5,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 4,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 4,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 11,
    supvtotalincn: 40,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 53,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 53,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 50,
    flsa1incpct: '░░.░',
    flsa2incn: 3,
    flsa2incpct: '░░.░',
    stelig0incn: 12,
    stelig0incpct: '░░.░',
    stelig1incn: 41,
    stelig1incpct: '░░.░',
    anystirecd0incn: 5,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 36,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 29,
    actvtgtincmean: '░░.░',
    ltielig0incn: 27,
    ltielig0incpct: '░░.░',
    ltielig1incn: 26,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 10,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 16,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 41,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 41,
    slsplan24incpct: '░░.░',
    baseincn: 53,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 33,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 35,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 53,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 15,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 53,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 15,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 53,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 47,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 18,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 15,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 15,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 16,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 53,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Minneapolis-St. Paul-Bloomington',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2976,
  },
  {
    id: 2977,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3128,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 4,
    actvtgtcon: 1,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 3,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 3,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 3,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 3,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 1,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 0,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 4,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: null,
    tdccomean: null,
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 4,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: null,
    tdcongcomean: null,
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 3,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 3,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 0,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 0,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 4,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 5,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 5,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 4,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 3,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 1,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 1,
    actvtgtincmean: null,
    ltielig0incn: 3,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 0,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 3,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 3,
    slsplan24incpct: '░░.░',
    baseincn: 5,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: null,
    baseinc30: null,
    baseinc35: null,
    baseinc40: null,
    baseinc45: null,
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: null,
    baseinc60: null,
    baseinc65: null,
    baseinc70: null,
    baseinc75: null,
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 3,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 1,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 5,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: null,
    tccinc30: null,
    tccinc35: null,
    tccinc40: null,
    tccinc45: null,
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: null,
    tccinc60: null,
    tccinc65: null,
    tccinc70: null,
    tccinc75: null,
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 0,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 4,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: null,
    tdcinc30: null,
    tdcinc35: null,
    tdcinc40: null,
    tdcinc45: null,
    tdcincmedian: null,
    tdcincmean: null,
    tdcinc55: null,
    tdcinc60: null,
    tdcinc65: null,
    tdcinc70: null,
    tdcinc75: null,
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 4,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: null,
    tdconginc30: null,
    tdconginc35: null,
    tdconginc40: null,
    tdconginc45: null,
    tdcongincmedian: null,
    tdcongincmean: null,
    tdconginc55: null,
    tdconginc60: null,
    tdconginc65: null,
    tdconginc70: null,
    tdconginc75: null,
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 5,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 0,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 0,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 5,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Indianapolis-Carmel-Anderson',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2977,
  },
  {
    id: 2978,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3137,
    pairedcon: 1,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 1,
    pairedtdccon: 1,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 4,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 4,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 4,
    havttgt1copct: '░░.░',
    ltielig0con: 1,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 4,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 4,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 8,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 8,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 8,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 7,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 6,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 4,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 4,
    actvtgtincmean: null,
    ltielig0incn: 2,
    ltielig0incpct: '░░.░',
    ltielig1incn: 6,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 5,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 6,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 6,
    slsplan24incpct: '░░.░',
    baseincn: 8,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 6,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 4,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 8,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 4,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 8,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 8,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 8,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 3,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 4,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 6,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 8,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Detroit-Warren-Dearborn',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2978,
  },
  {
    id: 2979,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 3161,
    pairedcon: 1,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 1,
    pairedtdccon: 1,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 1,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 3,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 2,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 3,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 2,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 2,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 2,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 2,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 0,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 8,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 8,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 8,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 8,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 3,
    stelig0incpct: '░░.░',
    stelig1incn: 5,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 3,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 1,
    actvtgtincmean: null,
    ltielig0incn: 6,
    ltielig0incpct: '░░.░',
    ltielig1incn: 2,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 5,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 5,
    slsplan24incpct: '░░.░',
    baseincn: 8,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 3,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 3,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 8,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 8,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 8,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 6,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 0,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 8,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Madison',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2979,
  },
  {
    id: 2980,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 4105,
    pairedcon: 8,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 8,
    pairedtdccon: 7,
    surveycode: 'D_23',
    supvtotalcon: 11,
    actvtgtcon: 8,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 10,
    havttgt1copct: '░░.░',
    ltielig0con: 7,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 10,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 11,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 10,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 8,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 11,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 10,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 10,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 10,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 11,
    supvtotalincn: 44,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 44,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 44,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 44,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 42,
    stelig1incpct: '░░.░',
    anystirecd0incn: 17,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 25,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 25,
    actvtgtincmean: '░░.░',
    ltielig0incn: 34,
    ltielig0incpct: '░░.░',
    ltielig1incn: 10,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 9,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 42,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 42,
    slsplan24incpct: '░░.░',
    baseincn: 44,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 42,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 25,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 44,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 9,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 43,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 8,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 43,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 33,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 2,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 9,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 8,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 10,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 44,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Denver-Aurora-Lakewood',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2980,
  },
  {
    id: 2981,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 4112,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 4,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 4,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 4,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 0,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 4,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 4,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 0,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 4,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 0,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 0,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 16,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 21,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 21,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 21,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 6,
    stelig0incpct: '░░.░',
    stelig1incn: 15,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 12,
    actvtgtincmean: null,
    ltielig0incn: 20,
    ltielig0incpct: '░░.░',
    ltielig1incn: 0,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 0,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 15,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 15,
    slsplan24incpct: '░░.░',
    baseincn: 21,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 15,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 12,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 21,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 0,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 20,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 20,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 11,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 0,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 0,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 21,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Salt Lake City',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2981,
  },
  {
    id: 2982,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 5106,
    pairedcon: 10,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 10,
    pairedtdccon: 10,
    surveycode: 'D_23',
    supvtotalcon: 18,
    actvtgtcon: 12,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 16,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 15,
    havttgt1copct: '░░.░',
    ltielig0con: 13,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 16,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 18,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 15,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 12,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 18,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 5,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 18,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 4,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 18,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 15,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 15,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 15,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 5,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 5,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 4,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 18,
    supvtotalincn: 80,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 80,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 80,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 61,
    flsa1incpct: '░░.░',
    flsa2incn: 19,
    flsa2incpct: '░░.░',
    stelig0incn: 9,
    stelig0incpct: '░░.░',
    stelig1incn: 71,
    stelig1incpct: '░░.░',
    anystirecd0incn: 38,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 33,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 32,
    actvtgtincmean: '░░.░',
    ltielig0incn: 51,
    ltielig0incpct: '░░.░',
    ltielig1incn: 29,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 8,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 21,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 71,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 71,
    slsplan24incpct: '░░.░',
    baseincn: 80,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 67,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 33,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 80,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 21,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 80,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 7,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 80,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 74,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 5,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 21,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 7,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 21,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 80,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Austin-Round Rock-Georgetown',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2982,
  },
  {
    id: 2983,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 5111,
    pairedcon: 14,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 14,
    pairedtdccon: 14,
    surveycode: 'D_23',
    supvtotalcon: 20,
    actvtgtcon: 15,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 19,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 19,
    havttgt1copct: '░░.░',
    ltielig0con: 17,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 19,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 21,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 19,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 16,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 21,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 21,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 21,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 16,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 19,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 19,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 21,
    supvtotalincn: 158,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 161,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 161,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 161,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 6,
    stelig0incpct: '░░.░',
    stelig1incn: 155,
    stelig1incpct: '░░.░',
    anystirecd0incn: 45,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 110,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 109,
    actvtgtincmean: '░░.░',
    ltielig0incn: 146,
    ltielig0incpct: '░░.░',
    ltielig1incn: 15,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 3,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 12,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 155,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 155,
    slsplan24incpct: '░░.░',
    baseincn: 161,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 154,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 110,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 161,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 12,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 161,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 11,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 161,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 82,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 4,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 12,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 11,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 13,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 161,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Dallas-Fort Worth-Arlington',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2983,
  },
  {
    id: 2984,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 5113,
    pairedcon: 2,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 2,
    pairedtdccon: 2,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 6,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 8,
    havttgt1copct: '░░.░',
    ltielig0con: 8,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 8,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 9,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 9,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 7,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 8,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 11,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 11,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 11,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 11,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 9,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 7,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 7,
    actvtgtincmean: '░░.░',
    ltielig0incn: 9,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 1,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 9,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 9,
    slsplan24incpct: '░░.░',
    baseincn: 11,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 9,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 7,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 11,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 1,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 10,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 10,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 7,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 1,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 11,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Houston-The Woodlands-Sugar Land',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2984,
  },
  {
    id: 2985,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 5205,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 9,
    actvtgtcon: 7,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 10,
    havttgt1copct: '░░.░',
    ltielig0con: 8,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 10,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 10,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 7,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 8,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 10,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 10,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 23,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 24,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 24,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 24,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 5,
    stelig0incpct: '░░.░',
    stelig1incn: 19,
    stelig1incpct: '░░.░',
    anystirecd0incn: 7,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 12,
    actvtgtincmean: '░░.░',
    ltielig0incn: 20,
    ltielig0incpct: '░░.░',
    ltielig1incn: 4,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 4,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 19,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 19,
    slsplan24incpct: '░░.░',
    baseincn: 24,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 19,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 12,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 24,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 4,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 24,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 4,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 24,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 21,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 4,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 4,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 4,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 4,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 24,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Phoenix-Mesa-Chandler',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2985,
  },
  {
    id: 2986,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6101,
    pairedcon: 0,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 0,
    pairedtdccon: 0,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 3,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 3,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 5,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 5,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 5,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 5,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 5,
    stelig1incpct: '░░.░',
    anystirecd0incn: 2,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 3,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 3,
    actvtgtincmean: null,
    ltielig0incn: 4,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 1,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 5,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 5,
    slsplan24incpct: '░░.░',
    baseincn: 5,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: null,
    baseinc30: null,
    baseinc35: null,
    baseinc40: null,
    baseinc45: null,
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: null,
    baseinc60: null,
    baseinc65: null,
    baseinc70: null,
    baseinc75: null,
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 5,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 3,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 5,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: null,
    tccinc30: null,
    tccinc35: null,
    tccinc40: null,
    tccinc45: null,
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: null,
    tccinc60: null,
    tccinc65: null,
    tccinc70: null,
    tccinc75: null,
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 1,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 5,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: null,
    tdcinc30: null,
    tdcinc35: null,
    tdcinc40: null,
    tdcinc45: null,
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: null,
    tdcinc60: null,
    tdcinc65: null,
    tdcinc70: null,
    tdcinc75: null,
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 5,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: null,
    tdconginc30: null,
    tdconginc35: null,
    tdconginc40: null,
    tdconginc45: null,
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: null,
    tdconginc60: null,
    tdconginc65: null,
    tdconginc70: null,
    tdconginc75: null,
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 5,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 1,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 5,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Las Vegas-Henderson-Paradise',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2986,
  },
  {
    id: 2987,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6115,
    pairedcon: 2,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 2,
    pairedtdccon: 2,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 2,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 5,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 2,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: null,
    pctaddlcomean: null,
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 0,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 0,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 5,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 0,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 0,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 7,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 9,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 8,
    stelig1incpct: '░░.░',
    anystirecd0incn: 5,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 3,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 3,
    actvtgtincmean: null,
    ltielig0incn: 8,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 0,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 8,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 8,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 3,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: null,
    pctaddlincmean: null,
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 0,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 0,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 9,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 0,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 0,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Riverside-San Bernardino-Ontario',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2987,
  },
  {
    id: 2988,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6116,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 9,
    actvtgtcon: 6,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 8,
    havttgt1copct: '░░.░',
    ltielig0con: 7,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 8,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 9,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 9,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 10,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 8,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 20,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 21,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 21,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 21,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 2,
    stelig0incpct: '░░.░',
    stelig1incn: 19,
    stelig1incpct: '░░.░',
    anystirecd0incn: 7,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 12,
    actvtgtincmean: '░░.░',
    ltielig0incn: 14,
    ltielig0incpct: '░░.░',
    ltielig1incn: 6,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 6,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 19,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 19,
    slsplan24incpct: '░░.░',
    baseincn: 21,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 19,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 12,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 21,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 6,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 20,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 6,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 20,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 21,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 5,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 6,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 6,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 6,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 21,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Sacramento-Roseville-Folsom',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2988,
  },
  {
    id: 2989,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6118,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 3,
    havttgt0copct: '░░.░',
    havttgt1con: 7,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 10,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 7,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 9,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 7,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 7,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 25,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 25,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 25,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 25,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 25,
    stelig1incpct: '░░.░',
    anystirecd0incn: 12,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 13,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 7,
    actvtgtincmean: null,
    ltielig0incn: 11,
    ltielig0incpct: '░░.░',
    ltielig1incn: 14,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 12,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 25,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 25,
    slsplan24incpct: '░░.░',
    baseincn: 25,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 11,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 13,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 25,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 6,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 25,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 5,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 25,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 24,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 6,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 6,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 5,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 6,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 25,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'San Diego-Chula Vista-Carlsbad',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2989,
  },
  {
    id: 2990,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6119,
    pairedcon: 14,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 14,
    pairedtdccon: 13,
    surveycode: 'D_23',
    supvtotalcon: 18,
    actvtgtcon: 14,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 19,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 18,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 10,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 19,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 19,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 18,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 14,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 19,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 7,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 18,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 18,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 16,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 18,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 18,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 7,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 19,
    supvtotalincn: 141,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 152,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 152,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 146,
    flsa1incpct: '░░.░',
    flsa2incn: 6,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 151,
    stelig1incpct: '░░.░',
    anystirecd0incn: 45,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 106,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 99,
    actvtgtincmean: '░░.░',
    ltielig0incn: 49,
    ltielig0incpct: '░░.░',
    ltielig1incn: 103,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 101,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 151,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 151,
    slsplan24incpct: '░░.░',
    baseincn: 152,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 141,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 105,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 152,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 93,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 148,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 78,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 148,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 146,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 61,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 93,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 78,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 92,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 152,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'San Francisco-Oakland-Berkeley',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2990,
  },
  {
    id: 2991,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6120,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 11,
    actvtgtcon: 9,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 11,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 11,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 6,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 11,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 12,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 11,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 9,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 12,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 5,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 12,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 5,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 12,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 12,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 11,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 11,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 5,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 5,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 12,
    supvtotalincn: 43,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 49,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 49,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 49,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 48,
    stelig1incpct: '░░.░',
    anystirecd0incn: 10,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 38,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 38,
    actvtgtincmean: '░░.░',
    ltielig0incn: 24,
    ltielig0incpct: '░░.░',
    ltielig1incn: 25,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 25,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 48,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 48,
    slsplan24incpct: '░░.░',
    baseincn: 49,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 48,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 38,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 49,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 24,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 49,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 22,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 49,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 49,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 14,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 24,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 22,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 25,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 49,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'San Jose-Sunnyvale-Santa Clara',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2991,
  },
  {
    id: 2992,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6132,
    pairedcon: 22,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 22,
    pairedtdccon: 21,
    surveycode: 'D_23',
    supvtotalcon: 27,
    actvtgtcon: 21,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 26,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 25,
    havttgt1copct: '░░.░',
    ltielig0con: 17,
    ltielig0copct: '░░.░',
    ltielig1con: 10,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 26,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 27,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 25,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 21,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 27,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 8,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 26,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 7,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 26,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 20,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 25,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 25,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 6,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 8,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 7,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 27,
    supvtotalincn: 444,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 444,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 444,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 353,
    flsa1incpct: '░░.░',
    flsa2incn: 91,
    flsa2incpct: '░░.░',
    stelig0incn: 14,
    stelig0incpct: '░░.░',
    stelig1incn: 430,
    stelig1incpct: '░░.░',
    anystirecd0incn: 93,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 337,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 322,
    actvtgtincmean: '░░.░',
    ltielig0incn: 151,
    ltielig0incpct: '░░.░',
    ltielig1incn: 293,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 51,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 239,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 430,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 430,
    slsplan24incpct: '░░.░',
    baseincn: 444,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 401,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 335,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 444,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 151,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 354,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 87,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 354,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 380,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 164,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 151,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 87,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 228,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 444,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Los Angeles-Long Beach-Anaheim',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2992,
  },
  {
    id: 2993,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6200,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 6,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 6,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 6,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 9,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 9,
    stelig1incpct: '░░.░',
    anystirecd0incn: 3,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 6,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 6,
    actvtgtincmean: '░░.░',
    ltielig0incn: 6,
    ltielig0incpct: '░░.░',
    ltielig1incn: 3,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 9,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 9,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 9,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 6,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 9,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 2,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 9,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 9,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 2,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 2,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 3,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Portland-Vancouver-Hillsboro',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2993,
  },
  {
    id: 2994,
    finalcode: 50025,
    cutcat: 'Tcc_MetroCode',
    cut: 6212,
    pairedcon: 16,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 16,
    pairedtdccon: 13,
    surveycode: 'D_23',
    supvtotalcon: 21,
    actvtgtcon: 15,
    stelig0con: 1,
    stelig0copct: '░░.░',
    stelig1con: 20,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 18,
    havttgt1copct: '░░.░',
    ltielig0con: 10,
    ltielig0copct: '░░.░',
    ltielig1con: 11,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 20,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 21,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 18,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 16,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 21,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 8,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 19,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 19,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 18,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 18,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 18,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 10,
    tgttdcco5: '░░.░',
    tgttdcco10: '░░.░',
    tgttdcco15: '░░.░',
    tgttdcco20: '░░.░',
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: '░░.░',
    tgttdcco85: '░░.░',
    tgttdcco90: '░░.░',
    tgttdcco95: '░░.░',
    ltitgtvaluecon: 7,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 8,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 21,
    supvtotalincn: 226,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 226,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 226,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 218,
    flsa1incpct: '░░.░',
    flsa2incn: 8,
    flsa2incpct: '░░.░',
    stelig0incn: 1,
    stelig0incpct: '░░.░',
    stelig1incn: 225,
    stelig1incpct: '░░.░',
    anystirecd0incn: 97,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 128,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 120,
    actvtgtincmean: '░░.░',
    ltielig0incn: 98,
    ltielig0incpct: '░░.░',
    ltielig1incn: 128,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 21,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 105,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 225,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 225,
    slsplan24incpct: '░░.░',
    baseincn: 226,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 209,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 128,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 226,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 94,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 220,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 85,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 220,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 204,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 105,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 94,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 85,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 111,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 226,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Seattle-Tacoma-Bellevue',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2994,
  },
  {
    id: 2995,
    finalcode: 50025,
    cutcat: 'SpecPremiumCode',
    cut: 300,
    pairedcon: 20,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 20,
    pairedtdccon: 20,
    surveycode: 'D_23',
    supvtotalcon: 32,
    actvtgtcon: 25,
    stelig0con: 5,
    stelig0copct: '░░.░',
    stelig1con: 27,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 26,
    havttgt1copct: '░░.░',
    ltielig0con: 23,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 27,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 32,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 26,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 26,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 32,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 9,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 32,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 32,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 20,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 26,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 26,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 8,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 9,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 32,
    supvtotalincn: 1962,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 1962,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 492,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 97,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 102,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 61,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 176,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 1029,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 1901,
    flsa1incpct: '░░.░',
    flsa2incn: 61,
    flsa2incpct: '░░.░',
    stelig0incn: 128,
    stelig0incpct: '░░.░',
    stelig1incn: 1834,
    stelig1incpct: '░░.░',
    anystirecd0incn: 731,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 1103,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 1022,
    actvtgtincmean: '░░.░',
    ltielig0incn: 895,
    ltielig0incpct: '░░.░',
    ltielig1incn: 1067,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 140,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 921,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1834,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1834,
    slsplan24incpct: '░░.░',
    baseincn: 1962,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 1739,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 1100,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 1962,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 918,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 1956,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 863,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 1956,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 1637,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 993,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 918,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 863,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 984,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 1962,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Specialty / Scope',
    rptcut: 'Standard Specialty',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2995,
  },
  {
    id: 2996,
    finalcode: 50025,
    cutcat: 'SpecDataAnalyticsSWECode',
    cut: 600,
    pairedcon: 6,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 6,
    pairedtdccon: 6,
    surveycode: 'D_23',
    supvtotalcon: 12,
    actvtgtcon: 9,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 10,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 10,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 12,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 10,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 9,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 12,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 12,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 12,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 10,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 10,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 10,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 12,
    supvtotalincn: 981,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 981,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 182,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 60,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 41,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 49,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 47,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 598,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 968,
    flsa1incpct: '░░.░',
    flsa2incn: 13,
    flsa2incpct: '░░.░',
    stelig0incn: 112,
    stelig0incpct: '░░.░',
    stelig1incn: 869,
    stelig1incpct: '░░.░',
    anystirecd0incn: 389,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 480,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 480,
    actvtgtincmean: '░░.░',
    ltielig0incn: 422,
    ltielig0incpct: '░░.░',
    ltielig1incn: 559,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 30,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 529,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 869,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 869,
    slsplan24incpct: '░░.░',
    baseincn: 981,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 869,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 480,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 981,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 527,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 981,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 484,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 981,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 951,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 467,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 527,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 484,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 551,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 981,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Specialty / Scope',
    rptcut: 'Big Data',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2996,
  },
  {
    id: 2997,
    finalcode: 50025,
    cutcat: 'SpecDataAnalyticsSWECode',
    cut: 601,
    pairedcon: 12,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 12,
    pairedtdccon: 12,
    surveycode: 'D_23',
    supvtotalcon: 23,
    actvtgtcon: 20,
    stelig0con: 2,
    stelig0copct: '░░.░',
    stelig1con: 21,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 20,
    havttgt1copct: '░░.░',
    ltielig0con: 14,
    ltielig0copct: '░░.░',
    ltielig1con: 9,
    ltielig1copct: '░░.░',
    slsplan1con: 0,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 21,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 23,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 20,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 21,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 23,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 9,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 23,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 8,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 23,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 15,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 20,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 0,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 0,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 20,
    slsplantgt4co5: '░░.░',
    slsplantgt4co10: '░░.░',
    slsplantgt4co15: '░░.░',
    slsplantgt4co20: '░░.░',
    slsplantgt4co25: '░░.░',
    slsplantgt4co30: '░░.░',
    slsplantgt4co35: '░░.░',
    slsplantgt4co40: '░░.░',
    slsplantgt4co45: '░░.░',
    slsplantgt4comedian: '░░.░',
    slsplantgt4comean: '░░.░',
    slsplantgt4co55: '░░.░',
    slsplantgt4co60: '░░.░',
    slsplantgt4co65: '░░.░',
    slsplantgt4co70: '░░.░',
    slsplantgt4co75: '░░.░',
    slsplantgt4co80: '░░.░',
    slsplantgt4co85: '░░.░',
    slsplantgt4co90: '░░.░',
    slsplantgt4co95: '░░.░',
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 0,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 9,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 7,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: '░░.░',
    ltitgtvalueco30: '░░.░',
    ltitgtvalueco35: '░░.░',
    ltitgtvalueco40: '░░.░',
    ltitgtvalueco45: '░░.░',
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: '░░.░',
    ltitgtvalueco60: '░░.░',
    ltitgtvalueco65: '░░.░',
    ltitgtvalueco70: '░░.░',
    ltitgtvalueco75: '░░.░',
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 9,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 8,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 23,
    supvtotalincn: 868,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 868,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 229,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 55,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 51,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 11,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 167,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 354,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 760,
    flsa1incpct: '░░.░',
    flsa2incn: 108,
    flsa2incpct: '░░.░',
    stelig0incn: 44,
    stelig0incpct: '░░.░',
    stelig1incn: 824,
    stelig1incpct: '░░.░',
    anystirecd0incn: 224,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 600,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 550,
    actvtgtincmean: '░░.░',
    ltielig0incn: 437,
    ltielig0incpct: '░░.░',
    ltielig1incn: 431,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 74,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 351,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 0,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 824,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 0,
    slsplan21incpct: '░░.░',
    slsplan22incn: 0,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 824,
    slsplan24incpct: '░░.░',
    baseincn: 868,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 754,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 597,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 868,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 351,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 862,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 279,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 862,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 648,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 0,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 290,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 351,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 279,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 354,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 868,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Specialty / Scope',
    rptcut: 'Other than Big Data',
    surveydataview: 'By Position',
    postitle: 'Software Engineer',
    sortorder: 2997,
  },
];
