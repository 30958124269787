import React, { Component } from "react";
import { connect } from "react-redux";
import { signoutUser, idleUser } from "../actions/authentication";

export default function(ComposedComponent) {
  class Timers extends Component {
    // This function is meant to determine if the user
    // navigated away from the website
    timerLeft = () => {
      // If the current time is 2s > session, they have left the site so log them out
      // Otherwise just store current time in session storage
      if (
        sessionStorage.getItem("timerLeft") > 0 &&
        new Date().getTime() - sessionStorage.getItem("timerLeft") > 10000
      ) {
        this.props.signoutUser();
      } else {
        sessionStorage.setItem("timerLeft", new Date().getTime());
      }
    };

    // This function is meant to detect if the user is idle
    // and will boot them if so
    timerIdle = () => {
      document.onclick = () => {
        sessionStorage.setItem("timerIdle", 0);
      };

      document.onmousemove = () => {
        sessionStorage.setItem("timerIdle", 0);
      };

      document.onkeypress = () => {
        sessionStorage.setItem("timerIdle", 0);
      };

      if (
        sessionStorage.getItem("timerIdle") > 0 &&
        sessionStorage.getItem("timerIdle") >= 900
      ) {
        this.props.idleUser();
      } else if (sessionStorage.getItem("timerIdle") === undefined) {
        sessionStorage.setItem("timerIdle", 0);
      } else {
        sessionStorage.setItem(
          "timerIdle",
          parseInt(sessionStorage.getItem("timerIdle")) + 1
        );
      }
    };

    // Call the function when the component mounts
    UNSAFE_componentWillMount() {
      // this.timerLeft = setInterval(this.timerLeft, 200);
      this.timerIdle = setInterval(this.timerIdle, 1000);
    }

    // Stop the interval when the component unmounts
    componentWillUnmount() {
      // clearInterval(this.timerLeft);
      clearInterval(this.timerIdle);
      sessionStorage.setItem("timerIdle", "0");
    }

    // Render the component
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  // Connect to the signoutUser action
  return connect(null, { signoutUser, idleUser })(Timers);
}
