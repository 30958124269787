import {
  DOCUMENTS,
  DOWNLOAD_STATUS,
  UPLOAD_RESPONSE
} from "../actions/documents";

export default function(state = {}, action) {
  switch (action.type) {
    case DOCUMENTS:
      return {
        ...state,
        currentSurveyName: action.payload.current_survey_name,
        planning_meeting: action.payload.planning_meeting_docs,
        questionnaire: action.payload.questionnaire_docs,
        survey_resources: action.payload.survey_resource_docs,
        survey_list: action.payload.survey_list,
        survey_id: action.payload.survey_guid
      };
    case DOWNLOAD_STATUS:
      return {
        ...state,
        downloadStatus: action.payload
      };
    case UPLOAD_RESPONSE:
      return {
        ...state,
        upload: action.payload
      };
    default:
      return state;
  }
  // return state;
}
