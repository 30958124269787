import React, { Component } from "react";
import loading from "./ajax_loader_blue_512.gif";

import "./Loading.scss";

export default class Loading extends Component {
  render() {
    return (
      <div className='loader-container'>
        <img src={loading} className="loader" alt="Loading Indicator" />
      </div>
    );
  }
}
