// React Imports
import React, { useState } from 'react';

// Third-party Import
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';

// Croner Imports
import { submitParticipation } from '../../actions/participation';

const ParticipationContent = ({
  csrfToken,
  surveyId,
  companyGuid,
  userGuid,
  submitParticipation,
}) => {
  const [modal, setModal] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [confirmationBody, setConfirmationBody] = useState('');
  const toggle = () => setModal(!modal);

  const handleSubmit = () => {
    const body = {
      user_guid: userGuid,
      survey_guid: surveyId,
      company_guid: companyGuid,
      response_id: responseId,
    };
    submitParticipation(body, csrfToken);
    toggle();
  };

  return (
    <div id='participation-form'>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Participation</ModalHeader>
        <ModalBody>{confirmationBody}</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit}>
            Confirm
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Row>
            <Col xl='12'>
              <p>
                Below please find information about the Survey and levels of
                commitment. Once you have reviewed, please confirm your 2024
                Survey Participation so that we can include you in all important
                survey related communications and milestone dates.
              </p>
              <p>
                Now in its 27th year, the{' '}
                <em>Croner Compensation Survey of Foundations</em> is conducted
                annually to provide up-to-date and accurate market compensation
                data to private, independent grantmaking and community
                foundations in the United States.
              </p>
              <p>
                In 2023, 172 foundations participated in the{' '}
                <em>Croner Compensation Survey of Foundations</em>. The complete
                list of participants is below.
              </p>

              <h5>POSITIONS</h5>
              <p>
                The <em>Croner Compensation Survey of Foundations</em> includes
                216 benchmark positions in the following 51 job families:
              </p>
              <ul style={{ columnCount: '3', columnGap: '20px' }}>
                <li>Executives</li>
                <li>Program</li>
                <li>Program Grantmaking and Community Outreach</li>
                <li>Fellowship Programs</li>
                <li>Initiatives</li>
                <li>Program / Project Management</li>
                <li>Grants Management</li>
                <li>Public Policy</li>
                <li>Evaluation</li>
                <li>Research</li>
                <li>Library</li>
                <li>Knowledge Management / Learning</li>
                <li>Data and Analytics</li>
                <li>Communications</li>
                <li>Writing / Editing</li>
                <li>Multimedia Production</li>
                <li>Website</li>
                <li>Digital / Social Media</li>
                <li>Convening / Event Planning</li>
                <li>Audio / Visual Engineering</li>
                <li>Investment Management</li>
                <li>Investment Operations</li>
                <li>Investment Accounting</li>
                <li>Social Investments</li>
                <li>Finance / Accounting</li>
                <li>Financial Analysis</li>
                <li>Tax</li>
                <li>Payroll</li>
                <li>Legal</li>
                <li>Contracts Administration</li>
                <li>Human Resources</li>
                <li>Compensation and Benefits</li>
                <li>Office of the CEO</li>
                <li>Administration</li>
                <li>Facilities</li>
                <li>Project Management</li>
                <li>Operational Strategy and Planning</li>
                <li>Diversity, Equity and Inclusion</li>
                <li>Enterprise Risk Management</li>
                <li>Information Technology</li>
                <li>System Administration</li>
                <li>Business Systems Analysis</li>
                <li>IT Project Management</li>
                <li>CRM Administration</li>
                <li>Development</li>
                <li>Donor Services</li>
                <li>Events</li>
                <li>Scholarship</li>
                <li>Gift Planning</li>
                <li>International Programs</li>
                <li>International Communications</li>
              </ul>
              <h5>SURVEY PARTICIPATION LEVELS</h5>
              <p>
                Three participation levels are offered for the 2024{' '}
                <em>Croner Compensation Survey of Foundations</em>:{' '}
                <b>Network, Partner and Partner Plus</b>.
              </p>
              <h5>PARTICIPATION LEVELS AT-A-GLANCE</h5>
              <table id='participation-tiers'>
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan='3'>PARTICIPATION LEVEL</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className='participation-col-2'>PARTNER PLUS</th>
                    <th className='participation-col-3'>PARTNER</th>
                    <th className='participation-col-4'>NETWORK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className='participation-col-1'>
                      Survey Participation Fee
                    </th>
                    <td className='participation-col-2'>$8,500</td>
                    <td className='participation-col-3'>$7,500</td>
                    <td className='participation-col-4'>$1,500</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Custom Cut of Select Peer Group
                    </th>
                    <td className='participation-col-2'>Included</td>
                    <td className='participation-col-3'>$1,500</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Planning Meeting with Roundtable
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>Trends Presentation</th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results (PDF)
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'>X</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results (Excel)
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Report of Results Webinar
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'></td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Annua lSalary Budget Survey
                    </th>
                    <td className='participation-col-2'>X</td>
                    <td className='participation-col-3'>X</td>
                    <td className='participation-col-4'>X</td>
                  </tr>
                  <tr>
                    <th className='participation-col-1'>
                      Additional Custom Cut
                    </th>
                    <td className='participation-col-2'>$1,500</td>
                    <td className='participation-col-3'>$1,500</td>
                    <td className='participation-col-4'></td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xl='12'>
              <h5>REGISTER TODAY</h5>
            </Col>
          </Row>
          <Row
            style={{ marginTop: '10px', marginBottom: '20px', display: 'flex' }}
          >
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(187, 145, 32)',
                  border: '1px solid rgb(187, 145, 32)',
                }}
              >
                <CardTitle>Partner Plus</CardTitle>
                <CardText>
                  We will participate at the Partner Plus level in the 2024 CSF
                  Survey – $8,500.
                </CardText>
                <CardSubtitle>
                  Partner Plus participants receive the Compensation Data
                  Results in Microsoft Excel and a PDF copy of the Report of
                  Results, and a custom cut report of selected peers, along with
                  invitations to the Survey Planning Meeting for Partners and
                  the Report of the Results Webinar.
                </CardSubtitle>
                <Button
                  onClick={() => {
                    setResponseId('799680006');
                    setConfirmationBody(
                      'Pleae confirm you would like to participate at the Partner Plus level.'
                    );
                    toggle();
                  }}
                  style={{ background: 'rgb(187, 145, 32)' }}
                >
                  Participate as Partner Plus
                </Button>
              </Card>
            </Col>
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(52, 95, 162)',
                  border: '1px solid rgb(52, 95, 162)',
                }}
              >
                <CardTitle>Partner</CardTitle>
                <CardText>
                  We will participate at the Partner level in the 2024 CSF
                  Survey – $7,500.
                </CardText>
                <CardSubtitle>
                  Partners receive the Compensation Data Results in Microsoft
                  Excel and a PDF copy of the Report of Results, along with
                  invitations to the Survey Planning Meeting for Partners and
                  the Report of Results Webinar.
                </CardSubtitle>
                <Button
                  onClick={() => {
                    setResponseId('799680007');
                    setConfirmationBody(
                      'Pleae confirm you would like to participate at the Partner level.'
                    );
                    toggle();
                  }}
                  style={{ background: 'rgb(52, 95, 162)' }}
                >
                  Participate as Partner
                </Button>
              </Card>
            </Col>
            <Col xl='4'>
              <Card
                style={{
                  color: 'rgb(80, 116, 51)',
                  border: '1px solid rgb(80, 116, 51)',
                }}
              >
                <CardTitle>Network</CardTitle>
                <CardText>
                  We will participate at the Network level in the 2024 CSF
                  Survey – $1,500.
                </CardText>
                <CardSubtitle>
                  Network participants receive a PDF copy of the Report of
                  Results.
                </CardSubtitle>
                <Button
                  onClick={() => {
                    setResponseId('799680008');
                    setConfirmationBody(
                      'Pleae confirm you would like to participate at the Network level.'
                    );
                    toggle();
                  }}
                  style={{ background: 'rgb(80, 116, 51)' }}
                >
                  Participate as Network
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>PARTNER AND PARTNER PLUS</h5>{' '}
              <p>
                The fee to participate in the Survey at the Partner level is
                $7,500 and at Partner Plus level is $8,500 (which includes one
                complimentary custom cut report).
              </p>
              <p>Participation at the Partner levels includes:</p>
              <ul>
                <li>
                  The full Report of Results in PDF and the Compensation Data
                  Results in Microsoft Excel with additional resource tabs.
                </li>
                <li>
                  The ability to order Custom Cut reports of selected peer
                  foundations in the Survey for $1,500.{' '}
                  <b>
                    Partner Plus participants receive one complimentary Custom
                    Cut report, a savings of $500.
                  </b>
                </li>
                <li>
                  Attendance at the annual Survey Planning Meeting for Partners.
                  The meeting agenda includes a Trends Presentation by CEO Hali
                  Croner, a Jobs Discussion, a Roundtable Session, and the
                  opportunity to network with colleagues in other foundations.
                </li>
                <li>
                  Invitation to attend the annual Report of Results Webinar with
                  presentation of Survey Highlights.{' '}
                </li>
                <li>
                  Invitations to special Croner Pulse Surveys and Roundtable
                  Sessions.
                </li>
                <li>
                  The <em>Croner Salary Budget Survey – August</em>{' '}
                  (complimentary).
                </li>
                <li>
                  A secure account on the Croner Survey portal, which provides a
                  customized intake questionnaire pre-populated with your
                  organization’s responses from the previous year's survey,
                  Survey reports and materials, and data queries by position
                </li>
              </ul>
              <h5>NETWORK</h5>
              <p>
                The fee to participate in the Survey at the Network level is
                $1,500.
              </p>
              <p>Participation at the Network level includes:</p>
              <ul>
                <li>The full Report of Results in PDF.</li>
                <li>
                  An invitation to the{' '}
                  <em>Croner Salary Budget Survey – August</em> (complimentary).
                </li>
                <li>
                  A secure account on the Croner Survey portal, which provides a
                  customized intake questionnaire pre-populated with your
                  responses from the previous year's survey, Survey reports and
                  materials, and data queries by position.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>KEY FEATURES OF THE SURVEY – All Participation Levels</h5>
              <ul>
                <li>
                  Data reported for all foundations and separately for private
                  and community foundations.{' '}
                </li>
                <li>
                  Croner Survey web portal for secure submissions and results
                  delivery.
                </li>
                <li>
                  More foundation-specific positions covered than any other
                  survey of the sector, including 216 benchmark jobs, 29
                  Executive positions and 187 Non-Executive positions.
                </li>
                <li>
                  Comprehensive reporting of practices including demographics,
                  salary administration, cash incentive / bonuses, benefits
                  offered, Board of Directors compensation and related
                  information.
                </li>
                <li>
                  Proprietary methodology to quantify benefits costs for a more
                  realistic assessment of the foundation’s total compensation
                  investment.
                </li>
                <li>
                  Commitment to ongoing enhancements including regular updates
                  to survey benchmarks to reflect changes and complexities of
                  foundation jobs.
                </li>
              </ul>
              <h5>CONFIDENTIALITY</h5>
              <p>
                The Croner Company conducts this Survey as an independent third
                party to ensure the confidentiality and consistency of the data
                provided. All data are held in strict confidence and are
                reported only in summary form. No attribution is made to any
                specific data from any participating organization. We aggregate
                the data using Safe Harbor Guidelines to ensure full regulatory
                compliance of the Survey report. All participants are required
                to sign an agreement that confirms they will abide by the
                Survey’s confidentiality standards.
              </p>
              <h5>SURVEY SCHEDULE</h5>
              <table id='participation-dates'>
                <tbody>
                  <tr>
                    <td>March 2024</td>
                    <td>CSF Survey Planning Meeting </td>
                  </tr>
                  <tr>
                    <td>April 2024</td>
                    <td>CSF Survey Questionnaires Distributed</td>
                  </tr>
                  <tr>
                    <td>May 1, 2024</td>
                    <td>Data Effective Date</td>
                  </tr>
                  <tr>
                    <td>June 2024</td>
                    <td>Completed Questionnaires Due </td>
                  </tr>
                  <tr>
                    <td>September 2024</td>
                    <td>Report of Results Published (Excel and PDF)</td>
                  </tr>
                  <tr>
                    <td>October 2024</td>
                    <td>Report of Results Webinar</td>
                  </tr>
                </tbody>
              </table>
              <h5 style={{ textAlign: 'center' }}>
                2023 PARTICIPANT FOUNDATIONS
              </h5>
              <ul
                style={{
                  columnCount: '3',
                  columnGap: '20px',
                  listStyle: 'none',
                }}
              >
                <li>James & Alice B. Clark Foundation</li>
                <li>Alfred P. Sloan Foundation</li>
                <li>Annenberg Foundation</li>
                <li>Archstone Foundation</li>
                <li>Arizona Community Foundation</li>
                <li>Arnold Ventures</li>
                <li>Bainum Family Foundation</li>
                <li>Barr Foundation</li>
                <li>Bezos Academy</li>
                <li>Bill & Melinda Gates Foundation</li>
                <li>Blandin Foundation</li>
                <li>Blue Shield of California Foundation</li>
                <li>Bush Foundation</li>
                <li>California Community Foundation</li>
                <li>California Health Care Foundation</li>
                <li>Carnegie Corporation of New York</li>
                <li>Casey Family Programs</li>
                <li>Chan Zuckerberg Initiative</li>
                <li>Charles and Lynn Schusterman Family Philanthropies</li>
                <li>Charles H. Revson Foundation</li>
                <li>Charles Stewart Mott Foundation</li>
                <li>Climate Leadership Initiative</li>
                <li>ClimateWorks Foundation</li>
                <li>College Futures Foundation</li>
                <li>Community Foundation for Greater Atlanta</li>
                <li>Community Foundation of New Jersey</li>
                <li>Connecticut Health Foundation</li>
                <li>Conrad N. Hilton Foundation</li>
                <li>Dana Foundation</li>
                <li>Dogwood Health Trust</li>
                <li>Doris Duke Charitable Foundation</li>
                <li>Draper Richards Kaplan Foundation</li>
                <li>Echidna Giving</li>
                <li>Environmental Defense Fund</li>
                <li>Episcopal Health Foundation</li>
                <li>Evelyn & Walter Haas, Jr. Fund</li>
                <li>Ewing Marion Kauffman Foundation</li>
                <li>Fidelity Foundations</li>
                <li>Ford Foundation</li>
                <li>Foundation for a Just Society</li>
                <li>Foundation for the Carolinas</li>
                <li>Frey Foundation</li>
                <li>Gaylord and Dorothy Donnelley Foundation</li>
                <li>Gerstner Philanthropies</li>
                <li>GHR Foundation</li>
                <li>Gordon and Betty Moore Foundation</li>
                <li>Great Lakes Protection Fund</li>
                <li>Greater Houston Community Foundation</li>
                <li>Greater Kansas City Community Foundation</li>
                <li>Greater Rochester Health Foundation</li>
                <li>Greater Texas Foundation</li>
                <li>Hawaii Community Foundation</li>
                <li>Headwaters Foundation</li>
                <li>Health Forward Foundation</li>
                <li>Healthcare Georgia Foundation</li>
                <li>Hector and Gloria López Foundation</li>
                <li>Heising-Simons Foundation</li>
                <li>Helios Education Foundation</li>
                <li>Houston Endowment</li>
                <li>Hudson-Webber Foundation</li>
                <li>Humanity United</li>
                <li>J. Paul Getty Trust</li>
                <li>Jack Kent Cooke Foundation</li>
                <li>Jewish Community Foundation of Los Angeles</li>
                <li>Jim Joseph Foundation</li>
                <li>John D. and Catherine T. MacArthur Foundation</li>
                <li>John S. and James L. Knight Foundation</li>
                <li>John Templeton Foundation</li>
                <li>Kataly Foundation</li>
                <li>Kate B. Reynolds Charitable Trust</li>
                <li>Kenneth Rainin Foundation</li>
                <li>King Philanthropies</li>
                <li>Koret Foundation</li>
                <li>Latino Community Foundation</li>
                <li>Lisa & Douglas Goldman Fund</li>
                <li>Lumina Foundation</li>
                <li>M.J. Murdock Charitable Foundation</li>
                <li>Margaret A. Cargill Philanthropies</li>
                <li>Marin Community Foundation</li>
                <li>Mastercard Foundation</li>
                <li>May & Stanley Smith Charitable Trust</li>
                <li>McKnight Foundation</li>
                <li>Michael & Susan Dell Foundation</li>
                <li>Montana Healthcare Foundation</li>
                <li>Mother Cabrini Health Foundation</li>
                <li>Muriel McBrien Kauffman Family Foundation</li>
                <li>National Geographic Society</li>
                <li>Natrona Collective Health Trust</li>
                <li>New Hampshire Charitable Foundation</li>
                <li>Noble Research Institute</li>
                <li>Open Philanthropy Project</li>
                <li>Open Society Foundations</li>
                <li>Otto Bremer Trust</li>
                <li>Overdeck Family Foundation</li>
                <li>Paso del Norte Health Foundation</li>
                <li>Paul M. Angell Family Foundation</li>
                <li>Perenchio Foundation</li>
                <li>Pisces Foundation</li>
                <li>Pivotal Ventures</li>
                <li>Pohlad Family Foundation</li>
                <li>Polk Brothers Foundation</li>
                <li>Ralph C. Wilson, Jr. Foundation</li>
                <li>Rasmuson Foundation</li>
                <li>REACH Healthcare Foundation</li>
                <li>Rippleworks</li>
                <li>Robert W. Woodruff Foundation</li>
                <li>Robert Wood Johnson Foundation</li>
                <li>Robertson Foundation</li>
                <li>Robin Hood Foundation</li>
                <li>Rockefeller Brothers Fund</li>
                <li>Russell Sage Foundation</li>
                <li>S.H. Cowell Foundation</li>
                <li>Samis Foundation</li>
                <li>San Diego Foundation</li>
                <li>Sierra Health Foundation</li>
                <li>Simons Foundation</li>
                <li>Skoll Foundation</li>
                <li>Sobrato Family Foundation</li>
                <li>St. David's Foundation</li>
                <li>Stuart Foundation</li>
                <li>Surdna Foundation</li>
                <li>T.L.L. Temple Foundation</li>
                <li>Texas A&M Foundation</li>
                <li>The Ahmanson Foundation</li>
                <li>The Andrew W. Mellon Foundation</li>
                <li>The California Endowment</li>
                <li>The California Wellness Foundation</li>
                <li>The Commonwealth Fund</li>
                <li>The Conrad Prebys Foundation</li>
                <li>The David and Lucile Packard Foundation</li>
                <li>The Duke Endowment</li>
                <li>The Eli and Edythe Broad Foundation</li>
                <li>The Ford Family Foundation</li>
                <li>The Heinz Endowments</li>
                <li>The Henry Luce Foundation</li>
                <li>The J. Willard and Alice S. Marriott Foundation</li>
                <li>The James Irvine Foundation</li>
                <li>The Joyce Foundation</li>
                <li>The JPB Foundation</li>
                <li>The Kahlert Foundation</li>
                <li>The Keith Campbell Foundation for the Environment</li>
                <li>The Kresge Foundation</li>
                <li>The Leona M. and Harry B. Helmsley Charitable Trust</li>
                <li>The Libra Foundation</li>
                <li>The Lynde and Harry Bradley Foundation</li>
                <li>The McConnell Foundation</li>
                <li>The Meadows Foundation</li>
                <li>The Ralph M. Parsons Foundation</li>
                <li>The Rapides Foundation</li>
                <li>The Rockefeller Foundation</li>
                <li>The Rose Hills Foundation</li>
                <li>The Russell Family Foundation</li>
                <li>The Spencer Foundation</li>
                <li>The Starr Foundation</li>
                <li>The Summit Charitable Foundation</li>
                <li>The Susan Thompson Buffett Foundation</li>
                <li>The Taft Foundation</li>
                <li>The Wallace Foundation</li>
                <li>The William and Flora Hewlett Foundation</li>
                <li>Trellis Foundation</li>
                <li>UniHealth Foundation</li>
                <li>Virginia G. Piper Charitable Trust</li>
                <li>Vivo Foundation</li>
                <li>W.K. Kellogg Foundation</li>
                <li>Walter and Elise Haas Fund</li>
                <li>Water Foundation</li>
                <li>Waverley Street Foundation</li>
                <li>Weingart Foundation</li>
                <li>Wellspring Philanthropic Fund</li>
                <li>Wikimedia Foundation</li>
                <li>William Penn Foundation</li>
                <li>Winston Family Foundation</li>
              </ul>
              <h5>THE CRONER COMPANY</h5>
              <p>
                For additional Survey information, please contact Jane Gannon at
                jane@croner.com or (415) 485-5509.
              </p>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  submitParticipation,
};

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipationContent);
