// Styles for this component
import './Contact.scss';

// Global React-Redux imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Col, Container, Row } from 'reactstrap';

// Related containers
import ContactHeader from './ContactHeader';
import ContactForm from './ContactForm';
import FAQ from './FAQ';
import Loading from '../../components/Global/Loading';


// Actions related to component:
import { getFAQ } from '../../actions/contact';

class Contact extends Component {
  UNSAFE_componentWillMount() {
    this.props.getFAQ();
  }

  render() {
    const { questions } = this.props;

    if (!questions) {
      return <Loading />;
    } else {
      return (
        <div id='contact'>
          <ContactHeader />
          <ContactForm hash={window.location.hash} />
          <hr style={{ margin: '0 80px' }} />
          <FAQ hash={window.location.hash} />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    questions: state.contact.questions,
  };
}

export default connect(mapStateToProps, { getFAQ })(Contact);
