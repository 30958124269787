import axios from 'axios';

// Establish the different types
export const FEATURES = 'features';
export const FEATURE = 'feature';


export const getFeatures = () => (dispatch) => {
  axios
    .get('/api/client/features/')
    .then((response) => {
      // console.log(response.data);
      dispatch({
        type: FEATURES,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log('An error has occurred.');
    });
};

export const getFeature = (id) => (dispatch) => {
  axios
    .get(`/api/client/features/${id}/`)
    .then((response) => {
      dispatch({
        type: FEATURE,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log('An error has occurred.');
    });
};