import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { authError, resetPassword } from '../../actions/authentication';
import { Card, Row, Col } from 'reactstrap';
import { clearMessages } from '../../actions/messages';

class ResetPassword extends Component {
  // Where the user input is sent over to the action to be validated by server
  onSubmit(values) {
    this.props.resetPassword(values);
    this.props.clearMessages();
  }

  // This renders errors regarding the form inputs
  renderField(field) {
    const {
      label,
      type,
      // name,
      meta: { touched, error },
    } = field;

    return (
      <div className='form-group'>
        <input
          className='form-control'
          type={type}
          placeholder={label}
          {...field.input}
        />
        <div className='text-danger'>{touched ? error : ''}</div>
      </div>
    );
  }

  // The main body to be rendered
  render() {
    const { handleSubmit } = this.props;

    return (
      <div className='reset-password'>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Card className='custom-field-spacing'>
            <p
              style={{
                fontSize: '18px',
                marginLeft: '15px',
                marginRight: '15px',
              }}
            >
              Please enter the username associated with the account.
            </p>
            <p
              style={{
                fontSize: '18px',
                marginLeft: '15px',
                marginRight: '15px',
              }}
            >
              An email will be sent containing a verification link. You will
              then be prompted to answer the security questions associated with
              the account.
            </p>
            <h3
              className='user-title'
              style={{
                fontSize: '18px',
                marginLeft: '15px',
                marginRight: '15px',
              }}
            >
              Please check your spam / junk folder and allow up to 15-minutes to
              receive the email before submitting another request.
            </h3>
            <Field
              label='Username'
              name='username'
              type='username'
              component={this.renderField}
            />
            <Row>
              <Col>
                <button type='submit' className='blue-button'>
                  Submit
                </button>
              </Col>
            </Row>
            <a
              className='text-center'
              href='/'
              style={{ fontSize: '12px', margin: '15px 0' }}
            >
              Return to log in page
            </a>
          </Card>
        </form>
      </div>
    );
  }
}

// General form validation to make sure fields have entries
function validate(values) {
  const errors = {};

  if (!values.username) {
    errors.username = 'The username / email is a required field.';
  }

  return errors;
}

// This is where the potential error state that was created by invalid credentials
// is turned into a prop so it can be rendered
function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

ResetPassword = connect(mapStateToProps, {
  authError,
  clearMessages,
  resetPassword,
})(ResetPassword);
ResetPassword = reduxForm({
  form: 'username_recovery',
  validate,
})(ResetPassword);
export default ResetPassword;
