import './Footer.scss';

import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div id='footer-content'>
          <Row>
            <Col lg='4'>
              <h4>ABOUT</h4>
              <p>
                Our world-class compensation experts provide startups, emerging
                companies, non-profit organizations, and <em>Fortune 500</em>{' '}
                leaders with deeply focused and unparalleled compensation
                knowledge and benchmarking data. We are committed to providing
                relevant and useful survey data and consulting services that
                provide significant value and ROI.
              </p>
            </Col>
            <Col lg='4' className='middle-col'>
              <h4>QUICKLINKS</h4>
              <p>
                <a
                  href='https://croner.com/compensation-consulting'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compensation Consulting
                </a>
              </p>
              <p>
                <a
                  href='https://croner.com/board-advisory-services'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Board Advisory Services
                </a>
              </p>
              <p>
                <a
                  href='https://croner.com/compensation-surveys'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Compensation Surveys
                </a>
              </p>
              <p>
                <a
                  href='https://croner.com/careers'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Careers
                </a>
              </p>
              <p style={{ marginBottom: '20px' }}>
                <a
                  href='https://croner.com/news'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  News
                </a>
              </p>
              <p style={{ fontSize: '13px', marginBottom: '10px' }}>
                <a
                  href='https://croner.com/privacy-policy'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a
                  href='https://croner.com/terms-of-use'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of Use
                </a>
              </p>
              <p style={{ fontSize: '13px', marginBottom: '0px' }}>
                © 2022 The Croner Company
              </p>
              <p style={{ fontSize: '13px' }}>
                <small>
                  <small>
                    Website by{' '}
                    <a
                      href='https://daordesign.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Daor Design
                    </a>
                  </small>
                </small>
              </p>
            </Col>
            <Col lg='4'>
              <h4>CONTACT</h4>
              <p>
                <strong>The Croner Company</strong>
              </p>
              <p>55 Shaver St Ste 300</p>
              <p style={{ marginBottom: '20px' }}>San Rafael, CA 94901</p>
              <p>+ (415) 485-5530</p>
              <p>
                <a href='mailto:info@croner.com'>info@croner.com</a>
              </p>
              <p>
                <a
                  href='https://www.linkedin.com/company/the-croner-company/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='fab fa-linkedin fa-linkedin-in'
                  style={{ fontSize: '18px' }}
                >{''}</a>
              </p>
            </Col>
          </Row>
        </div>
        <div className='bottom-bar'></div>
      </footer>
    );
  }
}
