import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { connectRouter } from "connected-react-router";
import authReducer from "./authentication";
import contactReducer from "./contact";
import documentReducer from "./documents";
import homeReducer from "./home";
import messagesReducer from "./messages";
import resultsReducer from "./results";
import accountSettingsReducer from "./accountSettings";
import featuresReducer from './features';

const createRootReducer = history =>
  combineReducers({
    form,
    router: connectRouter(history),
    auth: authReducer,
    contact: contactReducer,
    home: homeReducer,
    documents: documentReducer,
    messages: messagesReducer,
    results: resultsReducer,
    accountSettings: accountSettingsReducer,
    features: featuresReducer
  });

export default createRootReducer;
