// React imports
import React, { useEffect } from 'react';

// Third-party imports
import { connect, useDispatch } from 'react-redux';

// Croner Imports
import { demoPosList } from '../../actions/results';
import Loading from '../../components/Global/Loading';
import ResultsHeader from '../Results/ResultsHeader';
import Query from '../Results/BasicQuery';

// styles for this component
import '../Results/Results.scss';

const QueryDemo = ({ results }) => {
  const dispatch = new useDispatch();
  useEffect(() => {
    dispatch(demoPosList());
  }, [dispatch]);

  if (Object.keys(results).length === 0) {
    return <Loading />;
  } else {
    return (
      <div id='results'>
        <ResultsHeader />
        <Query />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    results: state.results,
  };
};

export default connect(mapStateToProps, {})(QueryDemo);
