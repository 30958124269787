import { HOME, SURVEYS_OPEN, UPDATES_OPEN, DATES_OPEN } from "../actions/home";

export default function(state = {}, action) {
  switch (action.type) {
    case HOME:
      return {
        ...state,
        surveys: action.payload.survey_list,
        id: action.payload.id
      };
    case SURVEYS_OPEN:
      return {
        ...state,
        surveys_open: action.payload.surveys_open
      };
    case UPDATES_OPEN:
      return {
        ...state,
        surveys_open: action.payload.updates_open
      };
    case DATES_OPEN:
      return {
        ...state,
        surveys_open: action.payload.dates_open
      };
    default:
      return state;
  }

	// return state;
}
