// Styles for component
import './Authentication.scss';

// Global React-Redux imports
import React, { Component } from 'react';

// Containers related to this component
import AccountAssistance from './AccountAssistance';
import RecoverUsername from './RecoverUsername';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import Signout from './SignOut';

export default class Authentication extends Component {
  render() {
    const path = window.location.pathname;

    return (
      <div data-testid='Authentication' id='auth'>
        {path === '/' || path === '/auth/signin/' ? (
          <SignIn />
        ) : path === '/auth/account_assistance/' ? (
          <AccountAssistance />
        ) : path === '/auth/username/' ? (
          <RecoverUsername />
        ) : path === '/auth/password/' ? (
          <ResetPassword />
        ) : path === '/auth/signout/' ? (
          <Signout />
        ) : (
          ''
        )}
      </div>
    );
  }
}
