export const aeQueryData = [
  {
    id: 6059,
    finalcode: 80024,
    cutcat: 'AllInc',
    cut: 1,
    pairedcon: 11,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 11,
    pairedtdccon: 10,
    surveycode: 'D_23',
    supvtotalcon: 16,
    actvtgtcon: 13,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 18,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 15,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 8,
    ltielig1copct: '░░.░',
    slsplan1con: 16,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 1,
    slsplan99999copct: '░░.░',
    basecon: 18,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 13,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 16,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 17,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 16,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 6,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 16,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 13,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 13,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 9,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: '░░.░',
    slsplantgt1co30: '░░.░',
    slsplantgt1co35: '░░.░',
    slsplantgt1co40: '░░.░',
    slsplantgt1co45: '░░.░',
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: '░░.░',
    slsplantgt1co60: '░░.░',
    slsplantgt1co65: '░░.░',
    slsplantgt1co70: '░░.░',
    slsplantgt1co75: '░░.░',
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 3,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 1,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 5,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: '░░.░',
    revtgtteamcfycomean: '░░.░',
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 6,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: '░░.░',
    revtgtindvcfyco30: '░░.░',
    revtgtindvcfyco35: '░░.░',
    revtgtindvcfyco40: '░░.░',
    revtgtindvcfyco45: '░░.░',
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: '░░.░',
    revtgtindvcfyco60: '░░.░',
    revtgtindvcfyco65: '░░.░',
    revtgtindvcfyco70: '░░.░',
    revtgtindvcfyco75: '░░.░',
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 6,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 18,
    supvtotalincn: 218,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 261,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 135,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 13,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 62,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 2,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 5,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 44,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 259,
    flsa1incpct: '░░.░',
    flsa2incn: 2,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 261,
    stelig1incpct: '░░.░',
    anystirecd0incn: 15,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 243,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 225,
    actvtgtincmean: '░░.░',
    ltielig0incn: 72,
    ltielig0incpct: '░░.░',
    ltielig1incn: 167,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 63,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 104,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 215,
    slsplan1incpct: '░░.░',
    slsplan200incn: 39,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 7,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 122,
    slsplan21incpct: '░░.░',
    slsplan22incn: 93,
    slsplan22incpct: '░░.░',
    slsplan23incn: 39,
    slsplan23incpct: '░░.░',
    slsplan24incn: 7,
    slsplan24incpct: '░░.░',
    baseincn: 261,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 234,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 242,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 258,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 100,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 236,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 99,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 236,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 222,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 12,
    revtgtteamcfyinc5: '░░.░',
    revtgtteamcfyinc10: '░░.░',
    revtgtteamcfyinc15: '░░.░',
    revtgtteamcfyinc20: '░░.░',
    revtgtteamcfyinc25: '░░.░',
    revtgtteamcfyinc30: '░░.░',
    revtgtteamcfyinc35: '░░.░',
    revtgtteamcfyinc40: '░░.░',
    revtgtteamcfyinc45: '░░.░',
    revtgtteamcfyincmedian: '░░.░',
    revtgtteamcfyincmean: '░░.░',
    revtgtteamcfyinc55: '░░.░',
    revtgtteamcfyinc60: '░░.░',
    revtgtteamcfyinc65: '░░.░',
    revtgtteamcfyinc70: '░░.░',
    revtgtteamcfyinc75: '░░.░',
    revtgtteamcfyinc80: '░░.░',
    revtgtteamcfyinc85: '░░.░',
    revtgtteamcfyinc90: '░░.░',
    revtgtteamcfyinc95: '░░.░',
    revtgtindvcfyincn: 124,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 107,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 100,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 99,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 134,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 261,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'All Incumbents',
    rptcut: 'All Incumbents',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6059,
  },
  {
    id: 6060,
    finalcode: 80024,
    cutcat: 'OwnershipCode',
    cut: 1,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 4,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 1,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 6,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 4,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 4,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 3,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 4,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 4,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 56,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 56,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 33,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 3,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 11,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 9,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 56,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 56,
    stelig1incpct: '░░.░',
    anystirecd0incn: 1,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 55,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 50,
    actvtgtincmean: '░░.░',
    ltielig0incn: 5,
    ltielig0incpct: '░░.░',
    ltielig1incn: 51,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 11,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 40,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 56,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 25,
    slsplan21incpct: '░░.░',
    slsplan22incn: 31,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 56,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 51,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 55,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 56,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 40,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 56,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 39,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 56,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 56,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 2,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 32,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 30,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 40,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 39,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 40,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 56,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Ownership',
    rptcut: 'Digital Company - Public',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6060,
  },
  {
    id: 6061,
    finalcode: 80024,
    cutcat: 'OwnershipCode',
    cut: 2,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 7,
    actvtgtcon: 6,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 9,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 8,
    havttgt1copct: '░░.░',
    ltielig0con: 8,
    ltielig0copct: '░░.░',
    ltielig1con: 1,
    ltielig1copct: '░░.░',
    slsplan1con: 8,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 1,
    slsplan99999copct: '░░.░',
    basecon: 9,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 8,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 8,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 8,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 8,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 4,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 1,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 1,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 4,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 3,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 9,
    supvtotalincn: 59,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 80,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 38,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 3,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 23,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 2,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 13,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 78,
    flsa1incpct: '░░.░',
    flsa2incn: 2,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 80,
    stelig1incpct: '░░.░',
    anystirecd0incn: 9,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 69,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 64,
    actvtgtincmean: '░░.░',
    ltielig0incn: 60,
    ltielig0incpct: '░░.░',
    ltielig1incn: 20,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 15,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 5,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 60,
    slsplan1incpct: '░░.░',
    slsplan200incn: 19,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 1,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 53,
    slsplan21incpct: '░░.░',
    slsplan22incn: 7,
    slsplan22incpct: '░░.░',
    slsplan23incn: 19,
    slsplan23incpct: '░░.░',
    slsplan24incn: 1,
    slsplan24incpct: '░░.░',
    baseincn: 80,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 70,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 68,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 78,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 5,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 78,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 5,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 78,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 48,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 9,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 31,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 20,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 5,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 5,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 20,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 80,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Ownership',
    rptcut: 'Digital Unit / Positions',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6061,
  },
  {
    id: 6062,
    finalcode: 80024,
    cutcat: 'IndustryCode',
    cut: 6,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 8,
    actvtgtcon: 6,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 7,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 8,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 8,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 6,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 8,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 8,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 8,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 8,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 6,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 5,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 1,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 5,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: '░░.░',
    revtgtteamcfycomean: '░░.░',
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 5,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 8,
    supvtotalincn: 117,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 117,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 70,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 9,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 19,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 2,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 16,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 117,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 117,
    stelig1incpct: '░░.░',
    anystirecd0incn: 9,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 108,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 97,
    actvtgtincmean: '░░.░',
    ltielig0incn: 57,
    ltielig0incpct: '░░.░',
    ltielig1incn: 60,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 16,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 44,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 117,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 85,
    slsplan21incpct: '░░.░',
    slsplan22incn: 32,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 117,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 103,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 107,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 117,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 44,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 117,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 44,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 117,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 81,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 10,
    revtgtteamcfyinc5: '░░.░',
    revtgtteamcfyinc10: '░░.░',
    revtgtteamcfyinc15: '░░.░',
    revtgtteamcfyinc20: '░░.░',
    revtgtteamcfyinc25: '░░.░',
    revtgtteamcfyinc30: '░░.░',
    revtgtteamcfyinc35: '░░.░',
    revtgtteamcfyinc40: '░░.░',
    revtgtteamcfyinc45: '░░.░',
    revtgtteamcfyincmedian: '░░.░',
    revtgtteamcfyincmean: '░░.░',
    revtgtteamcfyinc55: '░░.░',
    revtgtteamcfyinc60: '░░.░',
    revtgtteamcfyinc65: '░░.░',
    revtgtteamcfyinc70: '░░.░',
    revtgtteamcfyinc75: '░░.░',
    revtgtteamcfyinc80: '░░.░',
    revtgtteamcfyinc85: '░░.░',
    revtgtteamcfyinc90: '░░.░',
    revtgtteamcfyinc95: '░░.░',
    revtgtindvcfyincn: 83,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 27,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 44,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 44,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 44,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 117,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Industry',
    rptcut: 'Media / Entertainment',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6062,
  },
  {
    id: 6063,
    finalcode: 80024,
    cutcat: 'PlatformCode',
    cut: 1,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 7,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 7,
    havttgt1copct: '░░.░',
    ltielig0con: 5,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 7,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 8,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 7,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 8,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 7,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 7,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 7,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 2,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 8,
    supvtotalincn: 75,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 76,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 37,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 3,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 8,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 2,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 25,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 76,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 76,
    stelig1incpct: '░░.░',
    anystirecd0incn: 14,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 61,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 52,
    actvtgtincmean: '░░.░',
    ltielig0incn: 34,
    ltielig0incpct: '░░.░',
    ltielig1incn: 23,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 4,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 19,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 72,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 4,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 46,
    slsplan21incpct: '░░.░',
    slsplan22incn: 26,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 4,
    slsplan24incpct: '░░.░',
    baseincn: 76,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 60,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 60,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 75,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 15,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 56,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 15,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 56,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 75,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 1,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 27,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 15,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 15,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 15,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 15,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 76,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Platform',
    rptcut: 'Online',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6063,
  },
  {
    id: 6064,
    finalcode: 80024,
    cutcat: 'TCC_RegionCode',
    cut: 1,
    pairedcon: 9,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 9,
    pairedtdccon: 8,
    surveycode: 'D_23',
    supvtotalcon: 15,
    actvtgtcon: 12,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 17,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 14,
    havttgt1copct: '░░.░',
    ltielig0con: 8,
    ltielig0copct: '░░.░',
    ltielig1con: 8,
    ltielig1copct: '░░.░',
    slsplan1con: 15,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 1,
    slsplan99999copct: '░░.░',
    basecon: 17,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 12,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 15,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 16,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 15,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 6,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 15,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 12,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 12,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 8,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: '░░.░',
    slsplantgt1co30: '░░.░',
    slsplantgt1co35: '░░.░',
    slsplantgt1co40: '░░.░',
    slsplantgt1co45: '░░.░',
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: '░░.░',
    slsplantgt1co60: '░░.░',
    slsplantgt1co65: '░░.░',
    slsplantgt1co70: '░░.░',
    slsplantgt1co75: '░░.░',
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 3,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 1,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 5,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: '░░.░',
    revtgtteamcfycomean: '░░.░',
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 6,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: '░░.░',
    revtgtindvcfyco30: '░░.░',
    revtgtindvcfyco35: '░░.░',
    revtgtindvcfyco40: '░░.░',
    revtgtindvcfyco45: '░░.░',
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: '░░.░',
    revtgtindvcfyco60: '░░.░',
    revtgtindvcfyco65: '░░.░',
    revtgtindvcfyco70: '░░.░',
    revtgtindvcfyco75: '░░.░',
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 6,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 17,
    supvtotalincn: 122,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 135,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 135,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 134,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 135,
    stelig1incpct: '░░.░',
    anystirecd0incn: 9,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 125,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 117,
    actvtgtincmean: '░░.░',
    ltielig0incn: 39,
    ltielig0incpct: '░░.░',
    ltielig1incn: 82,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 23,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 59,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 121,
    slsplan1incpct: '░░.░',
    slsplan200incn: 10,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 4,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 80,
    slsplan21incpct: '░░.░',
    slsplan22incn: 41,
    slsplan22incpct: '░░.░',
    slsplan23incn: 10,
    slsplan23incpct: '░░.░',
    slsplan24incn: 4,
    slsplan24incpct: '░░.░',
    baseincn: 135,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 122,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 124,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 134,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 57,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 120,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 56,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 120,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 109,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 8,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: '░░.░',
    revtgtteamcfyinc30: '░░.░',
    revtgtteamcfyinc35: '░░.░',
    revtgtteamcfyinc40: '░░.░',
    revtgtteamcfyinc45: '░░.░',
    revtgtteamcfyincmedian: '░░.░',
    revtgtteamcfyincmean: '░░.░',
    revtgtteamcfyinc55: '░░.░',
    revtgtteamcfyinc60: '░░.░',
    revtgtteamcfyinc65: '░░.░',
    revtgtteamcfyinc70: '░░.░',
    revtgtteamcfyinc75: '░░.░',
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 74,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 55,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 57,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 56,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 67,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 135,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'East',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6064,
  },
  {
    id: 6065,
    finalcode: 80024,
    cutcat: 'TCC_RegionCode',
    cut: 2,
    pairedcon: 4,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 4,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 7,
    actvtgtcon: 6,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 7,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 7,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 7,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 7,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 7,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 7,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 6,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 6,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 7,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 5,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 3,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 7,
    supvtotalincn: 9,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 9,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 9,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 9,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 9,
    stelig1incpct: '░░.░',
    anystirecd0incn: 1,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 8,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 7,
    actvtgtincmean: '░░.░',
    ltielig0incn: 3,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 3,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 9,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 6,
    slsplan21incpct: '░░.░',
    slsplan22incn: 3,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 9,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 8,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 9,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 3,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 8,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 3,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 8,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 8,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 4,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 3,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 3,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 3,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 3,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 9,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'South',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6065,
  },
  {
    id: 6066,
    finalcode: 80024,
    cutcat: 'TCC_RegionCode',
    cut: 3,
    pairedcon: 7,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 7,
    pairedtdccon: 7,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 8,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 11,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 9,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 5,
    ltielig1copct: '░░.░',
    slsplan1con: 9,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 1,
    slsplan99999copct: '░░.░',
    basecon: 11,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 8,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 4,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 10,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 4,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 10,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 8,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 5,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 1,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 2,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 5,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 4,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 3,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 4,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 4,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 11,
    supvtotalincn: 31,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 41,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 41,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 40,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 41,
    stelig1incpct: '░░.░',
    anystirecd0incn: 5,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 35,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 35,
    actvtgtincmean: '░░.░',
    ltielig0incn: 11,
    ltielig0incpct: '░░.░',
    ltielig1incn: 30,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 11,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 19,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 29,
    slsplan1incpct: '░░.░',
    slsplan200incn: 9,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 3,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 14,
    slsplan21incpct: '░░.░',
    slsplan22incn: 15,
    slsplan22incpct: '░░.░',
    slsplan23incn: 9,
    slsplan23incpct: '░░.░',
    slsplan24incn: 3,
    slsplan24incpct: '░░.░',
    baseincn: 41,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 37,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 35,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 40,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 17,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 40,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 17,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 40,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 34,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 2,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 20,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 17,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 17,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 17,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 24,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 41,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'Midwest',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6066,
  },
  {
    id: 6067,
    finalcode: 80024,
    cutcat: 'TCC_RegionCode',
    cut: 5,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 5,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 4,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: null,
    tdccomean: null,
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 4,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: null,
    tdcongcomean: null,
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 2,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 2,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 5,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 5,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 5,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 5,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 5,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 5,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 4,
    actvtgtincmean: null,
    ltielig0incn: 2,
    ltielig0incpct: '░░.░',
    ltielig1incn: 2,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 2,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 5,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 3,
    slsplan21incpct: '░░.░',
    slsplan22incn: 2,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 5,
    baseinc5: null,
    baseinc10: null,
    baseinc15: null,
    baseinc20: null,
    baseinc25: null,
    baseinc30: null,
    baseinc35: null,
    baseinc40: null,
    baseinc45: null,
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: null,
    baseinc60: null,
    baseinc65: null,
    baseinc70: null,
    baseinc75: null,
    baseinc80: null,
    baseinc85: null,
    baseinc90: null,
    baseinc95: null,
    tgttccincn: 4,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 5,
    pctaddlinc5: null,
    pctaddlinc10: null,
    pctaddlinc15: null,
    pctaddlinc20: null,
    pctaddlinc25: null,
    pctaddlinc30: null,
    pctaddlinc35: null,
    pctaddlinc40: null,
    pctaddlinc45: null,
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: null,
    pctaddlinc60: null,
    pctaddlinc65: null,
    pctaddlinc70: null,
    pctaddlinc75: null,
    pctaddlinc80: null,
    pctaddlinc85: null,
    pctaddlinc90: null,
    pctaddlinc95: null,
    tccincn: 5,
    tccinc5: null,
    tccinc10: null,
    tccinc15: null,
    tccinc20: null,
    tccinc25: null,
    tccinc30: null,
    tccinc35: null,
    tccinc40: null,
    tccinc45: null,
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: null,
    tccinc60: null,
    tccinc65: null,
    tccinc70: null,
    tccinc75: null,
    tccinc80: null,
    tccinc85: null,
    tccinc90: null,
    tccinc95: null,
    ltaddlincn: 2,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 4,
    tdcinc5: null,
    tdcinc10: null,
    tdcinc15: null,
    tdcinc20: null,
    tdcinc25: null,
    tdcinc30: null,
    tdcinc35: null,
    tdcinc40: null,
    tdcinc45: null,
    tdcincmedian: null,
    tdcincmean: null,
    tdcinc55: null,
    tdcinc60: null,
    tdcinc65: null,
    tdcinc70: null,
    tdcinc75: null,
    tdcinc80: null,
    tdcinc85: null,
    tdcinc90: null,
    tdcinc95: null,
    ltaddlongincn: 2,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 4,
    tdconginc5: null,
    tdconginc10: null,
    tdconginc15: null,
    tdconginc20: null,
    tdconginc25: null,
    tdconginc30: null,
    tdconginc35: null,
    tdconginc40: null,
    tdconginc45: null,
    tdcongincmedian: null,
    tdcongincmean: null,
    tdconginc55: null,
    tdconginc60: null,
    tdconginc65: null,
    tdconginc70: null,
    tdconginc75: null,
    tdconginc80: null,
    tdconginc85: null,
    tdconginc90: null,
    tdconginc95: null,
    digitalrevincn: 4,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 1,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 2,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 1,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 2,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 2,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 2,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 5,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'Southwest',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6067,
  },
  {
    id: 6068,
    finalcode: 80024,
    cutcat: 'TCC_RegionCode',
    cut: 6,
    pairedcon: 6,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 6,
    pairedtdccon: 6,
    surveycode: 'D_23',
    supvtotalcon: 10,
    actvtgtcon: 8,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 10,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 9,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 10,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 10,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 8,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 10,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 10,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 9,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 9,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 8,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 8,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 5,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 3,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 4,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 10,
    supvtotalincn: 40,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 40,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 40,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 40,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 40,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 39,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 32,
    actvtgtincmean: '░░.░',
    ltielig0incn: 16,
    ltielig0incpct: '░░.░',
    ltielig1incn: 18,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 7,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 11,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 40,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 19,
    slsplan21incpct: '░░.░',
    slsplan22incn: 21,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 40,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 33,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 39,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 39,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 11,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 33,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 11,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 33,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 36,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 1,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 16,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 8,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 11,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 11,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 11,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 40,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'U.S. Region',
    rptcut: 'West',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6068,
  },
  {
    id: 6069,
    finalcode: 80024,
    cutcat: 'Tcc_MetroCode',
    cut: 1203,
    pairedcon: 9,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 9,
    pairedtdccon: 8,
    surveycode: 'D_23',
    supvtotalcon: 13,
    actvtgtcon: 11,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 15,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 13,
    havttgt1copct: '░░.░',
    ltielig0con: 6,
    ltielig0copct: '░░.░',
    ltielig1con: 8,
    ltielig1copct: '░░.░',
    slsplan1con: 13,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 1,
    slsplan99999copct: '░░.░',
    basecon: 15,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 11,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 14,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 15,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 14,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 6,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 14,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 11,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 11,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 8,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: '░░.░',
    slsplantgt1co30: '░░.░',
    slsplantgt1co35: '░░.░',
    slsplantgt1co40: '░░.░',
    slsplantgt1co45: '░░.░',
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: '░░.░',
    slsplantgt1co60: '░░.░',
    slsplantgt1co65: '░░.░',
    slsplantgt1co70: '░░.░',
    slsplantgt1co75: '░░.░',
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 1,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 5,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: '░░.░',
    revtgtteamcfycomean: '░░.░',
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 6,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: '░░.░',
    revtgtindvcfyco30: '░░.░',
    revtgtindvcfyco35: '░░.░',
    revtgtindvcfyco40: '░░.░',
    revtgtindvcfyco45: '░░.░',
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: '░░.░',
    revtgtindvcfyco60: '░░.░',
    revtgtindvcfyco65: '░░.░',
    revtgtindvcfyco70: '░░.░',
    revtgtindvcfyco75: '░░.░',
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 6,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 15,
    supvtotalincn: 108,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 121,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 121,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 121,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 121,
    stelig1incpct: '░░.░',
    anystirecd0incn: 8,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 113,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 106,
    actvtgtincmean: '░░.░',
    ltielig0incn: 35,
    ltielig0incpct: '░░.░',
    ltielig1incn: 74,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 19,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 55,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 107,
    slsplan1incpct: '░░.░',
    slsplan200incn: 10,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 4,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 71,
    slsplan21incpct: '░░.░',
    slsplan22incn: 36,
    slsplan22incpct: '░░.░',
    slsplan23incn: 10,
    slsplan23incpct: '░░.░',
    slsplan24incn: 4,
    slsplan24incpct: '░░.░',
    baseincn: 121,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 110,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 112,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 121,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 53,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 109,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 52,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 109,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 97,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 8,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: '░░.░',
    revtgtteamcfyinc30: '░░.░',
    revtgtteamcfyinc35: '░░.░',
    revtgtteamcfyinc40: '░░.░',
    revtgtteamcfyinc45: '░░.░',
    revtgtteamcfyincmedian: '░░.░',
    revtgtteamcfyincmean: '░░.░',
    revtgtteamcfyinc55: '░░.░',
    revtgtteamcfyinc60: '░░.░',
    revtgtteamcfyinc65: '░░.░',
    revtgtteamcfyinc70: '░░.░',
    revtgtteamcfyinc75: '░░.░',
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 68,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 51,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 53,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 52,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 63,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 121,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'New York-Newark-Jersey City',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6069,
  },
  {
    id: 6070,
    finalcode: 80024,
    cutcat: 'Tcc_MetroCode',
    cut: 3100,
    pairedcon: 5,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 5,
    pairedtdccon: 5,
    surveycode: 'D_23',
    supvtotalcon: 8,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 8,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 6,
    havttgt1copct: '░░.░',
    ltielig0con: 4,
    ltielig0copct: '░░.░',
    ltielig1con: 4,
    ltielig1copct: '░░.░',
    slsplan1con: 7,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 8,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 7,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 7,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 7,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 7,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 4,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 8,
    supvtotalincn: 17,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 17,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 17,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 0,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 16,
    flsa1incpct: '░░.░',
    flsa2incn: 1,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 17,
    stelig1incpct: '░░.░',
    anystirecd0incn: 4,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 12,
    actvtgtincmean: '░░.░',
    ltielig0incn: 5,
    ltielig0incpct: '░░.░',
    ltielig1incn: 12,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 1,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 11,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 15,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 2,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 7,
    slsplan21incpct: '░░.░',
    slsplan22incn: 8,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 2,
    slsplan24incpct: '░░.░',
    baseincn: 17,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 13,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 12,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 16,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 9,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 16,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 9,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 16,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 16,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 8,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 6,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 9,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 9,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 9,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 17,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Chicago-Naperville-Elgin',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6070,
  },
  {
    id: 6071,
    finalcode: 80024,
    cutcat: 'Tcc_MetroCode',
    cut: 6119,
    pairedcon: 2,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 2,
    pairedtdccon: 2,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 6,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 6,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 2,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 2,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 6,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 3,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 2,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 1,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 2,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 2,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 13,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 13,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 13,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 13,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 13,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 13,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 10,
    actvtgtincmean: '░░.░',
    ltielig0incn: 5,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 0,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 5,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 13,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 8,
    slsplan21incpct: '░░.░',
    slsplan22incn: 5,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 13,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 10,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 13,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 13,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 5,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 10,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 5,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 10,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 13,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 1,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 5,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 3,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 5,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 5,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 5,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 13,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'San Francisco-Oakland-Berkeley',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6071,
  },
  {
    id: 6072,
    finalcode: 80024,
    cutcat: 'Tcc_MetroCode',
    cut: 6132,
    pairedcon: 3,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 3,
    pairedtdccon: 3,
    surveycode: 'D_23',
    supvtotalcon: 6,
    actvtgtcon: 4,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 6,
    stelig1copct: '░░.░',
    havttgt0con: 1,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 3,
    ltielig0copct: '░░.░',
    ltielig1con: 2,
    ltielig1copct: '░░.░',
    slsplan1con: 6,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 6,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 4,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: null,
    tgttcccomean: null,
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 6,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 6,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 1,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 1,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 5,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 4,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: null,
    tottgtcomean: null,
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 1,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 0,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 2,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: null,
    revtgtindvcfycomean: null,
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 1,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 0,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 1,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 1,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 6,
    supvtotalincn: 12,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 12,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 0,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 0,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 0,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 12,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 12,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 12,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 8,
    actvtgtincmean: null,
    ltielig0incn: 5,
    ltielig0incpct: '░░.░',
    ltielig1incn: 5,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 4,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 1,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 12,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 7,
    slsplan21incpct: '░░.░',
    slsplan22incn: 5,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 12,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 8,
    tgttccinc5: null,
    tgttccinc10: null,
    tgttccinc15: null,
    tgttccinc20: null,
    tgttccinc25: null,
    tgttccinc30: null,
    tgttccinc35: null,
    tgttccinc40: null,
    tgttccinc45: null,
    tgttccincmedian: null,
    tgttccincmean: null,
    tgttccinc55: null,
    tgttccinc60: null,
    tgttccinc65: null,
    tgttccinc70: null,
    tgttccinc75: null,
    tgttccinc80: null,
    tgttccinc85: null,
    tgttccinc90: null,
    tgttccinc95: null,
    pctaddlincn: 12,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 12,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 1,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 10,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 1,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 10,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 11,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 0,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 3,
    revtgtindvcfyinc5: null,
    revtgtindvcfyinc10: null,
    revtgtindvcfyinc15: null,
    revtgtindvcfyinc20: null,
    revtgtindvcfyinc25: null,
    revtgtindvcfyinc30: null,
    revtgtindvcfyinc35: null,
    revtgtindvcfyinc40: null,
    revtgtindvcfyinc45: null,
    revtgtindvcfyincmedian: null,
    revtgtindvcfyincmean: null,
    revtgtindvcfyinc55: null,
    revtgtindvcfyinc60: null,
    revtgtindvcfyinc65: null,
    revtgtindvcfyinc70: null,
    revtgtindvcfyinc75: null,
    revtgtindvcfyinc80: null,
    revtgtindvcfyinc85: null,
    revtgtindvcfyinc90: null,
    revtgtindvcfyinc95: null,
    ltitgtvalueincn: 0,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 1,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 1,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 1,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 12,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Metropolitan Area',
    rptcut: 'Los Angeles-Long Beach-Anaheim',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6072,
  },
  {
    id: 6073,
    finalcode: 80024,
    cutcat: 'SalesRevIndvCat',
    cut: 170,
    pairedcon: 0,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 0,
    pairedtdccon: 0,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 5,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 5,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 45,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 45,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 29,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 1,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 5,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 1,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 0,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 9,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 45,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 45,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 45,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 43,
    actvtgtincmean: '░░.░',
    ltielig0incn: 13,
    ltielig0incpct: '░░.░',
    ltielig1incn: 32,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 6,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 26,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 45,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 24,
    slsplan21incpct: '░░.░',
    slsplan22incn: 21,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 45,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 43,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 45,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 45,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 26,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 45,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 26,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 45,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 34,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 2,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 43,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 20,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 26,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 26,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 26,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 45,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Sales Revenue Targets',
    rptcut: 'Individual > $10 Million',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6073,
  },
  {
    id: 6074,
    finalcode: 80024,
    cutcat: 'SalesRevIndvCat',
    cut: 171,
    pairedcon: 0,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 0,
    pairedtdccon: 0,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 5,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 4,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 5,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 44,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 44,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 26,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 3,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 8,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 2,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 5,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 44,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 44,
    stelig1incpct: '░░.░',
    anystirecd0incn: 5,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 39,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 39,
    actvtgtincmean: '░░.░',
    ltielig0incn: 14,
    ltielig0incpct: '░░.░',
    ltielig1incn: 30,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 5,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 25,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 44,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 25,
    slsplan21incpct: '░░.░',
    slsplan22incn: 19,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 44,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 44,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 38,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 44,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 25,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 44,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 25,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 44,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 40,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 1,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 43,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 17,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 25,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 25,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 25,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 44,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Sales Revenue Targets',
    rptcut: 'Individual $5 - $10 Million',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6074,
  },
  {
    id: 6075,
    finalcode: 80024,
    cutcat: 'SalesRevIndvCat',
    cut: 172,
    pairedcon: 0,
    pairedcomedian: null,
    pairedcomean: null,
    pairedtcccomedian: null,
    pairedtcccomean: null,
    pairedtdccomedian: null,
    pairedtdccomean: null,
    pairedtcccon: 0,
    pairedtdccon: 0,
    surveycode: 'D_23',
    supvtotalcon: 5,
    actvtgtcon: 5,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 5,
    stelig1copct: '░░.░',
    havttgt0con: 0,
    havttgt0copct: '░░.░',
    havttgt1con: 5,
    havttgt1copct: '░░.░',
    ltielig0con: 2,
    ltielig0copct: '░░.░',
    ltielig1con: 3,
    ltielig1copct: '░░.░',
    slsplan1con: 5,
    slsplan1copct: '░░.░',
    slsplan200con: 0,
    slsplan200copct: '░░.░',
    slsplan3000con: 0,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 5,
    baseco5: null,
    baseco10: null,
    baseco15: null,
    baseco20: null,
    baseco25: null,
    baseco30: null,
    baseco35: null,
    baseco40: null,
    baseco45: null,
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: null,
    baseco60: null,
    baseco65: null,
    baseco70: null,
    baseco75: null,
    baseco80: null,
    baseco85: null,
    baseco90: null,
    baseco95: null,
    tgttcccon: 5,
    tgttccco5: null,
    tgttccco10: null,
    tgttccco15: null,
    tgttccco20: null,
    tgttccco25: null,
    tgttccco30: null,
    tgttccco35: null,
    tgttccco40: null,
    tgttccco45: null,
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: null,
    tgttccco60: null,
    tgttccco65: null,
    tgttccco70: null,
    tgttccco75: null,
    tgttccco80: null,
    tgttccco85: null,
    tgttccco90: null,
    tgttccco95: null,
    pctaddlcon: 5,
    pctaddlco5: null,
    pctaddlco10: null,
    pctaddlco15: null,
    pctaddlco20: null,
    pctaddlco25: null,
    pctaddlco30: null,
    pctaddlco35: null,
    pctaddlco40: null,
    pctaddlco45: null,
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: null,
    pctaddlco60: null,
    pctaddlco65: null,
    pctaddlco70: null,
    pctaddlco75: null,
    pctaddlco80: null,
    pctaddlco85: null,
    pctaddlco90: null,
    pctaddlco95: null,
    tcccon: 5,
    tccco5: null,
    tccco10: null,
    tccco15: null,
    tccco20: null,
    tccco25: null,
    tccco30: null,
    tccco35: null,
    tccco40: null,
    tccco45: null,
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: null,
    tccco60: null,
    tccco65: null,
    tccco70: null,
    tccco75: null,
    tccco80: null,
    tccco85: null,
    tccco90: null,
    tccco95: null,
    ltaddlcon: 3,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: null,
    ltaddlco30: null,
    ltaddlco35: null,
    ltaddlco40: null,
    ltaddlco45: null,
    ltaddlcomedian: null,
    ltaddlcomean: null,
    ltaddlco55: null,
    ltaddlco60: null,
    ltaddlco65: null,
    ltaddlco70: null,
    ltaddlco75: null,
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 5,
    tdcco5: null,
    tdcco10: null,
    tdcco15: null,
    tdcco20: null,
    tdcco25: null,
    tdcco30: null,
    tdcco35: null,
    tdcco40: null,
    tdcco45: null,
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: null,
    tdcco60: null,
    tdcco65: null,
    tdcco70: null,
    tdcco75: null,
    tdcco80: null,
    tdcco85: null,
    tdcco90: null,
    tdcco95: null,
    ltaddlongcon: 3,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: null,
    ltaddlongco30: null,
    ltaddlongco35: null,
    ltaddlongco40: null,
    ltaddlongco45: null,
    ltaddlongcomedian: null,
    ltaddlongcomean: null,
    ltaddlongco55: null,
    ltaddlongco60: null,
    ltaddlongco65: null,
    ltaddlongco70: null,
    ltaddlongco75: null,
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 5,
    tdcongco5: null,
    tdcongco10: null,
    tdcongco15: null,
    tdcongco20: null,
    tdcongco25: null,
    tdcongco30: null,
    tdcongco35: null,
    tdcongco40: null,
    tdcongco45: null,
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: null,
    tdcongco60: null,
    tdcongco65: null,
    tdcongco70: null,
    tdcongco75: null,
    tdcongco80: null,
    tdcongco85: null,
    tdcongco90: null,
    tdcongco95: null,
    digitalrevcon: 3,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: null,
    digitalrevcomean: null,
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 5,
    tottgtco5: null,
    tottgtco10: null,
    tottgtco15: null,
    tottgtco20: null,
    tottgtco25: null,
    tottgtco30: null,
    tottgtco35: null,
    tottgtco40: null,
    tottgtco45: null,
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: null,
    tottgtco60: null,
    tottgtco65: null,
    tottgtco70: null,
    tottgtco75: null,
    tottgtco80: null,
    tottgtco85: null,
    tottgtco90: null,
    tottgtco95: null,
    slsplantgt1con: 3,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: null,
    slsplantgt1co30: null,
    slsplantgt1co35: null,
    slsplantgt1co40: null,
    slsplantgt1co45: null,
    slsplantgt1comedian: null,
    slsplantgt1comean: null,
    slsplantgt1co55: null,
    slsplantgt1co60: null,
    slsplantgt1co65: null,
    slsplantgt1co70: null,
    slsplantgt1co75: null,
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 2,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 0,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 1,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: null,
    revtgtteamcfycomean: null,
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 5,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: null,
    revtgtindvcfyco30: null,
    revtgtindvcfyco35: null,
    revtgtindvcfyco40: null,
    revtgtindvcfyco45: null,
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: null,
    revtgtindvcfyco60: null,
    revtgtindvcfyco65: null,
    revtgtindvcfyco70: null,
    revtgtindvcfyco75: null,
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 3,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: null,
    tgttdcco30: null,
    tgttdcco35: null,
    tgttdcco40: null,
    tgttdcco45: null,
    tgttdccomedian: null,
    tgttdccomean: null,
    tgttdcco55: null,
    tgttdcco60: null,
    tgttdcco65: null,
    tgttdcco70: null,
    tgttdcco75: null,
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 2,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: null,
    ltitgtvaluecomean: null,
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 3,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: null,
    ltipctbaseco30: null,
    ltipctbaseco35: null,
    ltipctbaseco40: null,
    ltipctbaseco45: null,
    ltipctbasecomedian: null,
    ltipctbasecomean: null,
    ltipctbaseco55: null,
    ltipctbaseco60: null,
    ltipctbaseco65: null,
    ltipctbaseco70: null,
    ltipctbaseco75: null,
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 3,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: null,
    ltipctbaseongco30: null,
    ltipctbaseongco35: null,
    ltipctbaseongco40: null,
    ltipctbaseongco45: null,
    ltipctbaseongcomedian: null,
    ltipctbaseongcomean: null,
    ltipctbaseongco55: null,
    ltipctbaseongco60: null,
    ltipctbaseongco65: null,
    ltipctbaseongco70: null,
    ltipctbaseongco75: null,
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 5,
    supvtotalincn: 12,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 12,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 7,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 1,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 1,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 0,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 1,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 2,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 12,
    flsa1incpct: '░░.░',
    flsa2incn: 0,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 12,
    stelig1incpct: '░░.░',
    anystirecd0incn: 0,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 12,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 12,
    actvtgtincmean: '░░.░',
    ltielig0incn: 4,
    ltielig0incpct: '░░.░',
    ltielig1incn: 8,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 2,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 6,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 12,
    slsplan1incpct: '░░.░',
    slsplan200incn: 0,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 0,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 6,
    slsplan21incpct: '░░.░',
    slsplan22incn: 6,
    slsplan22incpct: '░░.░',
    slsplan23incn: 0,
    slsplan23incpct: '░░.░',
    slsplan24incn: 0,
    slsplan24incpct: '░░.░',
    baseincn: 12,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 12,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 12,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 12,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 6,
    ltaddlinc5: null,
    ltaddlinc10: null,
    ltaddlinc15: null,
    ltaddlinc20: null,
    ltaddlinc25: null,
    ltaddlinc30: null,
    ltaddlinc35: null,
    ltaddlinc40: null,
    ltaddlinc45: null,
    ltaddlincmedian: null,
    ltaddlincmean: null,
    ltaddlinc55: null,
    ltaddlinc60: null,
    ltaddlinc65: null,
    ltaddlinc70: null,
    ltaddlinc75: null,
    ltaddlinc80: null,
    ltaddlinc85: null,
    ltaddlinc90: null,
    ltaddlinc95: null,
    tdcincn: 12,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 6,
    ltaddlonginc5: null,
    ltaddlonginc10: null,
    ltaddlonginc15: null,
    ltaddlonginc20: null,
    ltaddlonginc25: null,
    ltaddlonginc30: null,
    ltaddlonginc35: null,
    ltaddlonginc40: null,
    ltaddlonginc45: null,
    ltaddlongincmedian: null,
    ltaddlongincmean: null,
    ltaddlonginc55: null,
    ltaddlonginc60: null,
    ltaddlonginc65: null,
    ltaddlonginc70: null,
    ltaddlonginc75: null,
    ltaddlonginc80: null,
    ltaddlonginc85: null,
    ltaddlonginc90: null,
    ltaddlonginc95: null,
    tdcongincn: 12,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 8,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: null,
    digitalrevincmean: null,
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 2,
    revtgtteamcfyinc5: null,
    revtgtteamcfyinc10: null,
    revtgtteamcfyinc15: null,
    revtgtteamcfyinc20: null,
    revtgtteamcfyinc25: null,
    revtgtteamcfyinc30: null,
    revtgtteamcfyinc35: null,
    revtgtteamcfyinc40: null,
    revtgtteamcfyinc45: null,
    revtgtteamcfyincmedian: null,
    revtgtteamcfyincmean: null,
    revtgtteamcfyinc55: null,
    revtgtteamcfyinc60: null,
    revtgtteamcfyinc65: null,
    revtgtteamcfyinc70: null,
    revtgtteamcfyinc75: null,
    revtgtteamcfyinc80: null,
    revtgtteamcfyinc85: null,
    revtgtteamcfyinc90: null,
    revtgtteamcfyinc95: null,
    revtgtindvcfyincn: 12,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 4,
    ltitgtvalueinc5: null,
    ltitgtvalueinc10: null,
    ltitgtvalueinc15: null,
    ltitgtvalueinc20: null,
    ltitgtvalueinc25: null,
    ltitgtvalueinc30: null,
    ltitgtvalueinc35: null,
    ltitgtvalueinc40: null,
    ltitgtvalueinc45: null,
    ltitgtvalueincmedian: null,
    ltitgtvalueincmean: null,
    ltitgtvalueinc55: null,
    ltitgtvalueinc60: null,
    ltitgtvalueinc65: null,
    ltitgtvalueinc70: null,
    ltitgtvalueinc75: null,
    ltitgtvalueinc80: null,
    ltitgtvalueinc85: null,
    ltitgtvalueinc90: null,
    ltitgtvalueinc95: null,
    ltipctbaseincn: 6,
    ltipctbaseinc5: null,
    ltipctbaseinc10: null,
    ltipctbaseinc15: null,
    ltipctbaseinc20: null,
    ltipctbaseinc25: null,
    ltipctbaseinc30: null,
    ltipctbaseinc35: null,
    ltipctbaseinc40: null,
    ltipctbaseinc45: null,
    ltipctbaseincmedian: null,
    ltipctbaseincmean: null,
    ltipctbaseinc55: null,
    ltipctbaseinc60: null,
    ltipctbaseinc65: null,
    ltipctbaseinc70: null,
    ltipctbaseinc75: null,
    ltipctbaseinc80: null,
    ltipctbaseinc85: null,
    ltipctbaseinc90: null,
    ltipctbaseinc95: null,
    ltipctbaseongincn: 6,
    ltipctbaseonginc5: null,
    ltipctbaseonginc10: null,
    ltipctbaseonginc15: null,
    ltipctbaseonginc20: null,
    ltipctbaseonginc25: null,
    ltipctbaseonginc30: null,
    ltipctbaseonginc35: null,
    ltipctbaseonginc40: null,
    ltipctbaseonginc45: null,
    ltipctbaseongincmedian: null,
    ltipctbaseongincmean: null,
    ltipctbaseonginc55: null,
    ltipctbaseonginc60: null,
    ltipctbaseonginc65: null,
    ltipctbaseonginc70: null,
    ltipctbaseonginc75: null,
    ltipctbaseonginc80: null,
    ltipctbaseonginc85: null,
    ltipctbaseonginc90: null,
    ltipctbaseonginc95: null,
    tgttdcincn: 6,
    tgttdcinc5: null,
    tgttdcinc10: null,
    tgttdcinc15: null,
    tgttdcinc20: null,
    tgttdcinc25: null,
    tgttdcinc30: null,
    tgttdcinc35: null,
    tgttdcinc40: null,
    tgttdcinc45: null,
    tgttdcincmedian: null,
    tgttdcincmean: null,
    tgttdcinc55: null,
    tgttdcinc60: null,
    tgttdcinc65: null,
    tgttdcinc70: null,
    tgttdcinc75: null,
    tgttdcinc80: null,
    tgttdcinc85: null,
    tgttdcinc90: null,
    tgttdcinc95: null,
    nummatchincn: 12,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Sales Revenue Targets',
    rptcut: 'Individual < $5 Million',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6075,
  },
  {
    id: 6076,
    finalcode: 80024,
    cutcat: 'StartCatCode',
    cut: 1,
    pairedcon: 11,
    pairedcomedian: '░░.░',
    pairedcomean: '░░.░',
    pairedtcccomedian: '░░.░',
    pairedtcccomean: '░░.░',
    pairedtdccomedian: '░░.░',
    pairedtdccomean: '░░.░',
    pairedtcccon: 11,
    pairedtdccon: 10,
    surveycode: 'D_23',
    supvtotalcon: 16,
    actvtgtcon: 13,
    stelig0con: 0,
    stelig0copct: '░░.░',
    stelig1con: 18,
    stelig1copct: '░░.░',
    havttgt0con: 2,
    havttgt0copct: '░░.░',
    havttgt1con: 15,
    havttgt1copct: '░░.░',
    ltielig0con: 9,
    ltielig0copct: '░░.░',
    ltielig1con: 8,
    ltielig1copct: '░░.░',
    slsplan1con: 16,
    slsplan1copct: '░░.░',
    slsplan200con: 1,
    slsplan200copct: '░░.░',
    slsplan3000con: 1,
    slsplan3000copct: '░░.░',
    slsplan99999con: 0,
    slsplan99999copct: '░░.░',
    basecon: 18,
    baseco5: '░░.░',
    baseco10: '░░.░',
    baseco15: '░░.░',
    baseco20: '░░.░',
    baseco25: '░░.░',
    baseco30: '░░.░',
    baseco35: '░░.░',
    baseco40: '░░.░',
    baseco45: '░░.░',
    basecomedian: '░░.░',
    basecomean: '░░.░',
    baseco55: '░░.░',
    baseco60: '░░.░',
    baseco65: '░░.░',
    baseco70: '░░.░',
    baseco75: '░░.░',
    baseco80: '░░.░',
    baseco85: '░░.░',
    baseco90: '░░.░',
    baseco95: '░░.░',
    tgttcccon: 13,
    tgttccco5: '░░.░',
    tgttccco10: '░░.░',
    tgttccco15: '░░.░',
    tgttccco20: '░░.░',
    tgttccco25: '░░.░',
    tgttccco30: '░░.░',
    tgttccco35: '░░.░',
    tgttccco40: '░░.░',
    tgttccco45: '░░.░',
    tgttcccomedian: '░░.░',
    tgttcccomean: '░░.░',
    tgttccco55: '░░.░',
    tgttccco60: '░░.░',
    tgttccco65: '░░.░',
    tgttccco70: '░░.░',
    tgttccco75: '░░.░',
    tgttccco80: '░░.░',
    tgttccco85: '░░.░',
    tgttccco90: '░░.░',
    tgttccco95: '░░.░',
    pctaddlcon: 16,
    pctaddlco5: '░░.░',
    pctaddlco10: '░░.░',
    pctaddlco15: '░░.░',
    pctaddlco20: '░░.░',
    pctaddlco25: '░░.░',
    pctaddlco30: '░░.░',
    pctaddlco35: '░░.░',
    pctaddlco40: '░░.░',
    pctaddlco45: '░░.░',
    pctaddlcomedian: '░░.░',
    pctaddlcomean: '░░.░',
    pctaddlco55: '░░.░',
    pctaddlco60: '░░.░',
    pctaddlco65: '░░.░',
    pctaddlco70: '░░.░',
    pctaddlco75: '░░.░',
    pctaddlco80: '░░.░',
    pctaddlco85: '░░.░',
    pctaddlco90: '░░.░',
    pctaddlco95: '░░.░',
    tcccon: 17,
    tccco5: '░░.░',
    tccco10: '░░.░',
    tccco15: '░░.░',
    tccco20: '░░.░',
    tccco25: '░░.░',
    tccco30: '░░.░',
    tccco35: '░░.░',
    tccco40: '░░.░',
    tccco45: '░░.░',
    tcccomedian: '░░.░',
    tcccomean: '░░.░',
    tccco55: '░░.░',
    tccco60: '░░.░',
    tccco65: '░░.░',
    tccco70: '░░.░',
    tccco75: '░░.░',
    tccco80: '░░.░',
    tccco85: '░░.░',
    tccco90: '░░.░',
    tccco95: '░░.░',
    ltaddlcon: 6,
    ltaddlco5: null,
    ltaddlco10: null,
    ltaddlco15: null,
    ltaddlco20: null,
    ltaddlco25: '░░.░',
    ltaddlco30: '░░.░',
    ltaddlco35: '░░.░',
    ltaddlco40: '░░.░',
    ltaddlco45: '░░.░',
    ltaddlcomedian: '░░.░',
    ltaddlcomean: '░░.░',
    ltaddlco55: '░░.░',
    ltaddlco60: '░░.░',
    ltaddlco65: '░░.░',
    ltaddlco70: '░░.░',
    ltaddlco75: '░░.░',
    ltaddlco80: null,
    ltaddlco85: null,
    ltaddlco90: null,
    ltaddlco95: null,
    tdccon: 16,
    tdcco5: '░░.░',
    tdcco10: '░░.░',
    tdcco15: '░░.░',
    tdcco20: '░░.░',
    tdcco25: '░░.░',
    tdcco30: '░░.░',
    tdcco35: '░░.░',
    tdcco40: '░░.░',
    tdcco45: '░░.░',
    tdccomedian: '░░.░',
    tdccomean: '░░.░',
    tdcco55: '░░.░',
    tdcco60: '░░.░',
    tdcco65: '░░.░',
    tdcco70: '░░.░',
    tdcco75: '░░.░',
    tdcco80: '░░.░',
    tdcco85: '░░.░',
    tdcco90: '░░.░',
    tdcco95: '░░.░',
    ltaddlongcon: 6,
    ltaddlongco5: null,
    ltaddlongco10: null,
    ltaddlongco15: null,
    ltaddlongco20: null,
    ltaddlongco25: '░░.░',
    ltaddlongco30: '░░.░',
    ltaddlongco35: '░░.░',
    ltaddlongco40: '░░.░',
    ltaddlongco45: '░░.░',
    ltaddlongcomedian: '░░.░',
    ltaddlongcomean: '░░.░',
    ltaddlongco55: '░░.░',
    ltaddlongco60: '░░.░',
    ltaddlongco65: '░░.░',
    ltaddlongco70: '░░.░',
    ltaddlongco75: '░░.░',
    ltaddlongco80: null,
    ltaddlongco85: null,
    ltaddlongco90: null,
    ltaddlongco95: null,
    tdcongcon: 16,
    tdcongco5: '░░.░',
    tdcongco10: '░░.░',
    tdcongco15: '░░.░',
    tdcongco20: '░░.░',
    tdcongco25: '░░.░',
    tdcongco30: '░░.░',
    tdcongco35: '░░.░',
    tdcongco40: '░░.░',
    tdcongco45: '░░.░',
    tdcongcomedian: '░░.░',
    tdcongcomean: '░░.░',
    tdcongco55: '░░.░',
    tdcongco60: '░░.░',
    tdcongco65: '░░.░',
    tdcongco70: '░░.░',
    tdcongco75: '░░.░',
    tdcongco80: '░░.░',
    tdcongco85: '░░.░',
    tdcongco90: '░░.░',
    tdcongco95: '░░.░',
    digitalrevcon: 13,
    digitalrevco5: null,
    digitalrevco10: null,
    digitalrevco15: null,
    digitalrevco20: null,
    digitalrevco25: null,
    digitalrevco30: null,
    digitalrevco35: null,
    digitalrevco40: null,
    digitalrevco45: null,
    digitalrevcomedian: '░░.░',
    digitalrevcomean: '░░.░',
    digitalrevco55: null,
    digitalrevco60: null,
    digitalrevco65: null,
    digitalrevco70: null,
    digitalrevco75: null,
    digitalrevco80: null,
    digitalrevco85: null,
    digitalrevco90: null,
    digitalrevco95: null,
    tottgtcon: 13,
    tottgtco5: '░░.░',
    tottgtco10: '░░.░',
    tottgtco15: '░░.░',
    tottgtco20: '░░.░',
    tottgtco25: '░░.░',
    tottgtco30: '░░.░',
    tottgtco35: '░░.░',
    tottgtco40: '░░.░',
    tottgtco45: '░░.░',
    tottgtcomedian: '░░.░',
    tottgtcomean: '░░.░',
    tottgtco55: '░░.░',
    tottgtco60: '░░.░',
    tottgtco65: '░░.░',
    tottgtco70: '░░.░',
    tottgtco75: '░░.░',
    tottgtco80: '░░.░',
    tottgtco85: '░░.░',
    tottgtco90: '░░.░',
    tottgtco95: '░░.░',
    slsplantgt1con: 9,
    slsplantgt1co5: null,
    slsplantgt1co10: null,
    slsplantgt1co15: null,
    slsplantgt1co20: null,
    slsplantgt1co25: '░░.░',
    slsplantgt1co30: '░░.░',
    slsplantgt1co35: '░░.░',
    slsplantgt1co40: '░░.░',
    slsplantgt1co45: '░░.░',
    slsplantgt1comedian: '░░.░',
    slsplantgt1comean: '░░.░',
    slsplantgt1co55: '░░.░',
    slsplantgt1co60: '░░.░',
    slsplantgt1co65: '░░.░',
    slsplantgt1co70: '░░.░',
    slsplantgt1co75: '░░.░',
    slsplantgt1co80: null,
    slsplantgt1co85: null,
    slsplantgt1co90: null,
    slsplantgt1co95: null,
    slsplantgt2con: 3,
    slsplantgt2co5: null,
    slsplantgt2co10: null,
    slsplantgt2co15: null,
    slsplantgt2co20: null,
    slsplantgt2co25: null,
    slsplantgt2co30: null,
    slsplantgt2co35: null,
    slsplantgt2co40: null,
    slsplantgt2co45: null,
    slsplantgt2comedian: null,
    slsplantgt2comean: null,
    slsplantgt2co55: null,
    slsplantgt2co60: null,
    slsplantgt2co65: null,
    slsplantgt2co70: null,
    slsplantgt2co75: null,
    slsplantgt2co80: null,
    slsplantgt2co85: null,
    slsplantgt2co90: null,
    slsplantgt2co95: null,
    slsplantgt3con: 1,
    slsplantgt3co5: null,
    slsplantgt3co10: null,
    slsplantgt3co15: null,
    slsplantgt3co20: null,
    slsplantgt3co25: null,
    slsplantgt3co30: null,
    slsplantgt3co35: null,
    slsplantgt3co40: null,
    slsplantgt3co45: null,
    slsplantgt3comedian: null,
    slsplantgt3comean: null,
    slsplantgt3co55: null,
    slsplantgt3co60: null,
    slsplantgt3co65: null,
    slsplantgt3co70: null,
    slsplantgt3co75: null,
    slsplantgt3co80: null,
    slsplantgt3co85: null,
    slsplantgt3co90: null,
    slsplantgt3co95: null,
    slsplantgt4con: 0,
    slsplantgt4co5: null,
    slsplantgt4co10: null,
    slsplantgt4co15: null,
    slsplantgt4co20: null,
    slsplantgt4co25: null,
    slsplantgt4co30: null,
    slsplantgt4co35: null,
    slsplantgt4co40: null,
    slsplantgt4co45: null,
    slsplantgt4comedian: null,
    slsplantgt4comean: null,
    slsplantgt4co55: null,
    slsplantgt4co60: null,
    slsplantgt4co65: null,
    slsplantgt4co70: null,
    slsplantgt4co75: null,
    slsplantgt4co80: null,
    slsplantgt4co85: null,
    slsplantgt4co90: null,
    slsplantgt4co95: null,
    revtgtteamcfycon: 5,
    revtgtteamcfyco5: null,
    revtgtteamcfyco10: null,
    revtgtteamcfyco15: null,
    revtgtteamcfyco20: null,
    revtgtteamcfyco25: null,
    revtgtteamcfyco30: null,
    revtgtteamcfyco35: null,
    revtgtteamcfyco40: null,
    revtgtteamcfyco45: null,
    revtgtteamcfycomedian: '░░.░',
    revtgtteamcfycomean: '░░.░',
    revtgtteamcfyco55: null,
    revtgtteamcfyco60: null,
    revtgtteamcfyco65: null,
    revtgtteamcfyco70: null,
    revtgtteamcfyco75: null,
    revtgtteamcfyco80: null,
    revtgtteamcfyco85: null,
    revtgtteamcfyco90: null,
    revtgtteamcfyco95: null,
    revtgtindvcfycon: 6,
    revtgtindvcfyco5: null,
    revtgtindvcfyco10: null,
    revtgtindvcfyco15: null,
    revtgtindvcfyco20: null,
    revtgtindvcfyco25: '░░.░',
    revtgtindvcfyco30: '░░.░',
    revtgtindvcfyco35: '░░.░',
    revtgtindvcfyco40: '░░.░',
    revtgtindvcfyco45: '░░.░',
    revtgtindvcfycomedian: '░░.░',
    revtgtindvcfycomean: '░░.░',
    revtgtindvcfyco55: '░░.░',
    revtgtindvcfyco60: '░░.░',
    revtgtindvcfyco65: '░░.░',
    revtgtindvcfyco70: '░░.░',
    revtgtindvcfyco75: '░░.░',
    revtgtindvcfyco80: null,
    revtgtindvcfyco85: null,
    revtgtindvcfyco90: null,
    revtgtindvcfyco95: null,
    tgttdccon: 6,
    tgttdcco5: null,
    tgttdcco10: null,
    tgttdcco15: null,
    tgttdcco20: null,
    tgttdcco25: '░░.░',
    tgttdcco30: '░░.░',
    tgttdcco35: '░░.░',
    tgttdcco40: '░░.░',
    tgttdcco45: '░░.░',
    tgttdccomedian: '░░.░',
    tgttdccomean: '░░.░',
    tgttdcco55: '░░.░',
    tgttdcco60: '░░.░',
    tgttdcco65: '░░.░',
    tgttdcco70: '░░.░',
    tgttdcco75: '░░.░',
    tgttdcco80: null,
    tgttdcco85: null,
    tgttdcco90: null,
    tgttdcco95: null,
    ltitgtvaluecon: 5,
    ltitgtvalueco5: null,
    ltitgtvalueco10: null,
    ltitgtvalueco15: null,
    ltitgtvalueco20: null,
    ltitgtvalueco25: null,
    ltitgtvalueco30: null,
    ltitgtvalueco35: null,
    ltitgtvalueco40: null,
    ltitgtvalueco45: null,
    ltitgtvaluecomedian: '░░.░',
    ltitgtvaluecomean: '░░.░',
    ltitgtvalueco55: null,
    ltitgtvalueco60: null,
    ltitgtvalueco65: null,
    ltitgtvalueco70: null,
    ltitgtvalueco75: null,
    ltitgtvalueco80: null,
    ltitgtvalueco85: null,
    ltitgtvalueco90: null,
    ltitgtvalueco95: null,
    ltipctbasecon: 6,
    ltipctbaseco5: null,
    ltipctbaseco10: null,
    ltipctbaseco15: null,
    ltipctbaseco20: null,
    ltipctbaseco25: '░░.░',
    ltipctbaseco30: '░░.░',
    ltipctbaseco35: '░░.░',
    ltipctbaseco40: '░░.░',
    ltipctbaseco45: '░░.░',
    ltipctbasecomedian: '░░.░',
    ltipctbasecomean: '░░.░',
    ltipctbaseco55: '░░.░',
    ltipctbaseco60: '░░.░',
    ltipctbaseco65: '░░.░',
    ltipctbaseco70: '░░.░',
    ltipctbaseco75: '░░.░',
    ltipctbaseco80: null,
    ltipctbaseco85: null,
    ltipctbaseco90: null,
    ltipctbaseco95: null,
    ltipctbaseongcon: 6,
    ltipctbaseongco5: null,
    ltipctbaseongco10: null,
    ltipctbaseongco15: null,
    ltipctbaseongco20: null,
    ltipctbaseongco25: '░░.░',
    ltipctbaseongco30: '░░.░',
    ltipctbaseongco35: '░░.░',
    ltipctbaseongco40: '░░.░',
    ltipctbaseongco45: '░░.░',
    ltipctbaseongcomedian: '░░.░',
    ltipctbaseongcomean: '░░.░',
    ltipctbaseongco55: '░░.░',
    ltipctbaseongco60: '░░.░',
    ltipctbaseongco65: '░░.░',
    ltipctbaseongco70: '░░.░',
    ltipctbaseongco75: '░░.░',
    ltipctbaseongco80: null,
    ltipctbaseongco85: null,
    ltipctbaseongco90: null,
    ltipctbaseongco95: null,
    nummatchcon: 18,
    supvtotalincn: 178,
    supvtotalincmean: '░░.░',
    finalmatch1incn: 0,
    finalmatch1incpct: '░░.░',
    finalmatch2incn: 216,
    finalmatch2incpct: '░░.░',
    finalmatch3incn: 0,
    finalmatch3incpct: '░░.░',
    tcc_regioncode1incn: 107,
    tcc_regioncode1incpct: '░░.░',
    tcc_regioncode2incn: 11,
    tcc_regioncode2incpct: '░░.░',
    tcc_regioncode3incn: 53,
    tcc_regioncode3incpct: '░░.░',
    tcc_regioncode4incn: 2,
    tcc_regioncode4incpct: '░░.░',
    tcc_regioncode5incn: 5,
    tcc_regioncode5incpct: '░░.░',
    tcc_regioncode6incn: 38,
    tcc_regioncode6incpct: '░░.░',
    flsa1incn: 214,
    flsa1incpct: '░░.░',
    flsa2incn: 2,
    flsa2incpct: '░░.░',
    stelig0incn: 0,
    stelig0incpct: '░░.░',
    stelig1incn: 216,
    stelig1incpct: '░░.░',
    anystirecd0incn: 7,
    anystirecd0incpct: '░░.░',
    anystirecd1incn: 207,
    anystirecd1incpct: '░░.░',
    actvtgtincn: 189,
    actvtgtincmean: '░░.░',
    ltielig0incn: 53,
    ltielig0incpct: '░░.░',
    ltielig1incn: 141,
    ltielig1incpct: '░░.░',
    ltirecd0incn: 53,
    ltirecd0incpct: '░░.░',
    ltirecd1incn: 88,
    ltirecd1incpct: '░░.░',
    slsplan1incn: 175,
    slsplan1incpct: '░░.░',
    slsplan200incn: 37,
    slsplan200incpct: '░░.░',
    slsplan3000incn: 4,
    slsplan3000incpct: '░░.░',
    slsplan21incn: 96,
    slsplan21incpct: '░░.░',
    slsplan22incn: 79,
    slsplan22incpct: '░░.░',
    slsplan23incn: 37,
    slsplan23incpct: '░░.░',
    slsplan24incn: 4,
    slsplan24incpct: '░░.░',
    baseincn: 216,
    baseinc5: '░░.░',
    baseinc10: '░░.░',
    baseinc15: '░░.░',
    baseinc20: '░░.░',
    baseinc25: '░░.░',
    baseinc30: '░░.░',
    baseinc35: '░░.░',
    baseinc40: '░░.░',
    baseinc45: '░░.░',
    baseincmedian: '░░.░',
    baseincmean: '░░.░',
    baseinc55: '░░.░',
    baseinc60: '░░.░',
    baseinc65: '░░.░',
    baseinc70: '░░.░',
    baseinc75: '░░.░',
    baseinc80: '░░.░',
    baseinc85: '░░.░',
    baseinc90: '░░.░',
    baseinc95: '░░.░',
    tgttccincn: 191,
    tgttccinc5: '░░.░',
    tgttccinc10: '░░.░',
    tgttccinc15: '░░.░',
    tgttccinc20: '░░.░',
    tgttccinc25: '░░.░',
    tgttccinc30: '░░.░',
    tgttccinc35: '░░.░',
    tgttccinc40: '░░.░',
    tgttccinc45: '░░.░',
    tgttccincmedian: '░░.░',
    tgttccincmean: '░░.░',
    tgttccinc55: '░░.░',
    tgttccinc60: '░░.░',
    tgttccinc65: '░░.░',
    tgttccinc70: '░░.░',
    tgttccinc75: '░░.░',
    tgttccinc80: '░░.░',
    tgttccinc85: '░░.░',
    tgttccinc90: '░░.░',
    tgttccinc95: '░░.░',
    pctaddlincn: 207,
    pctaddlinc5: '░░.░',
    pctaddlinc10: '░░.░',
    pctaddlinc15: '░░.░',
    pctaddlinc20: '░░.░',
    pctaddlinc25: '░░.░',
    pctaddlinc30: '░░.░',
    pctaddlinc35: '░░.░',
    pctaddlinc40: '░░.░',
    pctaddlinc45: '░░.░',
    pctaddlincmedian: '░░.░',
    pctaddlincmean: '░░.░',
    pctaddlinc55: '░░.░',
    pctaddlinc60: '░░.░',
    pctaddlinc65: '░░.░',
    pctaddlinc70: '░░.░',
    pctaddlinc75: '░░.░',
    pctaddlinc80: '░░.░',
    pctaddlinc85: '░░.░',
    pctaddlinc90: '░░.░',
    pctaddlinc95: '░░.░',
    tccincn: 214,
    tccinc5: '░░.░',
    tccinc10: '░░.░',
    tccinc15: '░░.░',
    tccinc20: '░░.░',
    tccinc25: '░░.░',
    tccinc30: '░░.░',
    tccinc35: '░░.░',
    tccinc40: '░░.░',
    tccinc45: '░░.░',
    tccincmedian: '░░.░',
    tccincmean: '░░.░',
    tccinc55: '░░.░',
    tccinc60: '░░.░',
    tccinc65: '░░.░',
    tccinc70: '░░.░',
    tccinc75: '░░.░',
    tccinc80: '░░.░',
    tccinc85: '░░.░',
    tccinc90: '░░.░',
    tccinc95: '░░.░',
    ltaddlincn: 84,
    ltaddlinc5: '░░.░',
    ltaddlinc10: '░░.░',
    ltaddlinc15: '░░.░',
    ltaddlinc20: '░░.░',
    ltaddlinc25: '░░.░',
    ltaddlinc30: '░░.░',
    ltaddlinc35: '░░.░',
    ltaddlinc40: '░░.░',
    ltaddlinc45: '░░.░',
    ltaddlincmedian: '░░.░',
    ltaddlincmean: '░░.░',
    ltaddlinc55: '░░.░',
    ltaddlinc60: '░░.░',
    ltaddlinc65: '░░.░',
    ltaddlinc70: '░░.░',
    ltaddlinc75: '░░.░',
    ltaddlinc80: '░░.░',
    ltaddlinc85: '░░.░',
    ltaddlinc90: '░░.░',
    ltaddlinc95: '░░.░',
    tdcincn: 192,
    tdcinc5: '░░.░',
    tdcinc10: '░░.░',
    tdcinc15: '░░.░',
    tdcinc20: '░░.░',
    tdcinc25: '░░.░',
    tdcinc30: '░░.░',
    tdcinc35: '░░.░',
    tdcinc40: '░░.░',
    tdcinc45: '░░.░',
    tdcincmedian: '░░.░',
    tdcincmean: '░░.░',
    tdcinc55: '░░.░',
    tdcinc60: '░░.░',
    tdcinc65: '░░.░',
    tdcinc70: '░░.░',
    tdcinc75: '░░.░',
    tdcinc80: '░░.░',
    tdcinc85: '░░.░',
    tdcinc90: '░░.░',
    tdcinc95: '░░.░',
    ltaddlongincn: 84,
    ltaddlonginc5: '░░.░',
    ltaddlonginc10: '░░.░',
    ltaddlonginc15: '░░.░',
    ltaddlonginc20: '░░.░',
    ltaddlonginc25: '░░.░',
    ltaddlonginc30: '░░.░',
    ltaddlonginc35: '░░.░',
    ltaddlonginc40: '░░.░',
    ltaddlonginc45: '░░.░',
    ltaddlongincmedian: '░░.░',
    ltaddlongincmean: '░░.░',
    ltaddlonginc55: '░░.░',
    ltaddlonginc60: '░░.░',
    ltaddlonginc65: '░░.░',
    ltaddlonginc70: '░░.░',
    ltaddlonginc75: '░░.░',
    ltaddlonginc80: '░░.░',
    ltaddlonginc85: '░░.░',
    ltaddlonginc90: '░░.░',
    ltaddlonginc95: '░░.░',
    tdcongincn: 192,
    tdconginc5: '░░.░',
    tdconginc10: '░░.░',
    tdconginc15: '░░.░',
    tdconginc20: '░░.░',
    tdconginc25: '░░.░',
    tdconginc30: '░░.░',
    tdconginc35: '░░.░',
    tdconginc40: '░░.░',
    tdconginc45: '░░.░',
    tdcongincmedian: '░░.░',
    tdcongincmean: '░░.░',
    tdconginc55: '░░.░',
    tdconginc60: '░░.░',
    tdconginc65: '░░.░',
    tdconginc70: '░░.░',
    tdconginc75: '░░.░',
    tdconginc80: '░░.░',
    tdconginc85: '░░.░',
    tdconginc90: '░░.░',
    tdconginc95: '░░.░',
    digitalrevincn: 184,
    digitalrevinc5: null,
    digitalrevinc10: null,
    digitalrevinc15: null,
    digitalrevinc20: null,
    digitalrevinc25: null,
    digitalrevinc30: null,
    digitalrevinc35: null,
    digitalrevinc40: null,
    digitalrevinc45: null,
    digitalrevincmedian: '░░.░',
    digitalrevincmean: '░░.░',
    digitalrevinc55: null,
    digitalrevinc60: null,
    digitalrevinc65: null,
    digitalrevinc70: null,
    digitalrevinc75: null,
    digitalrevinc80: null,
    digitalrevinc85: null,
    digitalrevinc90: null,
    digitalrevinc95: null,
    revtgtteamcfyincn: 10,
    revtgtteamcfyinc5: '░░.░',
    revtgtteamcfyinc10: '░░.░',
    revtgtteamcfyinc15: '░░.░',
    revtgtteamcfyinc20: '░░.░',
    revtgtteamcfyinc25: '░░.░',
    revtgtteamcfyinc30: '░░.░',
    revtgtteamcfyinc35: '░░.░',
    revtgtteamcfyinc40: '░░.░',
    revtgtteamcfyinc45: '░░.░',
    revtgtteamcfyincmedian: '░░.░',
    revtgtteamcfyincmean: '░░.░',
    revtgtteamcfyinc55: '░░.░',
    revtgtteamcfyinc60: '░░.░',
    revtgtteamcfyinc65: '░░.░',
    revtgtteamcfyinc70: '░░.░',
    revtgtteamcfyinc75: '░░.░',
    revtgtteamcfyinc80: '░░.░',
    revtgtteamcfyinc85: '░░.░',
    revtgtteamcfyinc90: '░░.░',
    revtgtteamcfyinc95: '░░.░',
    revtgtindvcfyincn: 100,
    revtgtindvcfyinc5: '░░.░',
    revtgtindvcfyinc10: '░░.░',
    revtgtindvcfyinc15: '░░.░',
    revtgtindvcfyinc20: '░░.░',
    revtgtindvcfyinc25: '░░.░',
    revtgtindvcfyinc30: '░░.░',
    revtgtindvcfyinc35: '░░.░',
    revtgtindvcfyinc40: '░░.░',
    revtgtindvcfyinc45: '░░.░',
    revtgtindvcfyincmedian: '░░.░',
    revtgtindvcfyincmean: '░░.░',
    revtgtindvcfyinc55: '░░.░',
    revtgtindvcfyinc60: '░░.░',
    revtgtindvcfyinc65: '░░.░',
    revtgtindvcfyinc70: '░░.░',
    revtgtindvcfyinc75: '░░.░',
    revtgtindvcfyinc80: '░░.░',
    revtgtindvcfyinc85: '░░.░',
    revtgtindvcfyinc90: '░░.░',
    revtgtindvcfyinc95: '░░.░',
    ltitgtvalueincn: 87,
    ltitgtvalueinc5: '░░.░',
    ltitgtvalueinc10: '░░.░',
    ltitgtvalueinc15: '░░.░',
    ltitgtvalueinc20: '░░.░',
    ltitgtvalueinc25: '░░.░',
    ltitgtvalueinc30: '░░.░',
    ltitgtvalueinc35: '░░.░',
    ltitgtvalueinc40: '░░.░',
    ltitgtvalueinc45: '░░.░',
    ltitgtvalueincmedian: '░░.░',
    ltitgtvalueincmean: '░░.░',
    ltitgtvalueinc55: '░░.░',
    ltitgtvalueinc60: '░░.░',
    ltitgtvalueinc65: '░░.░',
    ltitgtvalueinc70: '░░.░',
    ltitgtvalueinc75: '░░.░',
    ltitgtvalueinc80: '░░.░',
    ltitgtvalueinc85: '░░.░',
    ltitgtvalueinc90: '░░.░',
    ltitgtvalueinc95: '░░.░',
    ltipctbaseincn: 84,
    ltipctbaseinc5: '░░.░',
    ltipctbaseinc10: '░░.░',
    ltipctbaseinc15: '░░.░',
    ltipctbaseinc20: '░░.░',
    ltipctbaseinc25: '░░.░',
    ltipctbaseinc30: '░░.░',
    ltipctbaseinc35: '░░.░',
    ltipctbaseinc40: '░░.░',
    ltipctbaseinc45: '░░.░',
    ltipctbaseincmedian: '░░.░',
    ltipctbaseincmean: '░░.░',
    ltipctbaseinc55: '░░.░',
    ltipctbaseinc60: '░░.░',
    ltipctbaseinc65: '░░.░',
    ltipctbaseinc70: '░░.░',
    ltipctbaseinc75: '░░.░',
    ltipctbaseinc80: '░░.░',
    ltipctbaseinc85: '░░.░',
    ltipctbaseinc90: '░░.░',
    ltipctbaseinc95: '░░.░',
    ltipctbaseongincn: 84,
    ltipctbaseonginc5: '░░.░',
    ltipctbaseonginc10: '░░.░',
    ltipctbaseonginc15: '░░.░',
    ltipctbaseonginc20: '░░.░',
    ltipctbaseonginc25: '░░.░',
    ltipctbaseonginc30: '░░.░',
    ltipctbaseonginc35: '░░.░',
    ltipctbaseonginc40: '░░.░',
    ltipctbaseonginc45: '░░.░',
    ltipctbaseongincmedian: '░░.░',
    ltipctbaseongincmean: '░░.░',
    ltipctbaseonginc55: '░░.░',
    ltipctbaseonginc60: '░░.░',
    ltipctbaseonginc65: '░░.░',
    ltipctbaseonginc70: '░░.░',
    ltipctbaseonginc75: '░░.░',
    ltipctbaseonginc80: '░░.░',
    ltipctbaseonginc85: '░░.░',
    ltipctbaseonginc90: '░░.░',
    ltipctbaseonginc95: '░░.░',
    tgttdcincn: 113,
    tgttdcinc5: '░░.░',
    tgttdcinc10: '░░.░',
    tgttdcinc15: '░░.░',
    tgttdcinc20: '░░.░',
    tgttdcinc25: '░░.░',
    tgttdcinc30: '░░.░',
    tgttdcinc35: '░░.░',
    tgttdcinc40: '░░.░',
    tgttdcinc45: '░░.░',
    tgttdcincmedian: '░░.░',
    tgttdcincmean: '░░.░',
    tgttdcinc55: '░░.░',
    tgttdcinc60: '░░.░',
    tgttdcinc65: '░░.░',
    tgttdcinc70: '░░.░',
    tgttdcinc75: '░░.░',
    tgttdcinc80: '░░.░',
    tgttdcinc85: '░░.░',
    tgttdcinc90: '░░.░',
    tgttdcinc95: '░░.░',
    nummatchincn: 216,
    actvtgtinc5: null,
    actvtgtinc10: null,
    actvtgtinc15: null,
    actvtgtinc20: null,
    actvtgtinc25: null,
    actvtgtinc30: null,
    actvtgtinc35: null,
    actvtgtinc40: null,
    actvtgtinc45: null,
    actvtgtincmedian: null,
    actvtgtinc55: null,
    actvtgtinc60: null,
    actvtgtinc65: null,
    actvtgtinc70: null,
    actvtgtinc75: null,
    actvtgtinc80: null,
    actvtgtinc85: null,
    actvtgtinc90: null,
    actvtgtinc95: null,
    actvtgtco5: null,
    actvtgtco10: null,
    actvtgtco15: null,
    actvtgtco20: null,
    actvtgtco25: null,
    actvtgtco30: null,
    actvtgtco35: null,
    actvtgtco40: null,
    actvtgtco45: null,
    actvtgtcomedian: null,
    actvtgtcomean: null,
    actvtgtco55: null,
    actvtgtco60: null,
    actvtgtco65: null,
    actvtgtco70: null,
    actvtgtco75: null,
    actvtgtco80: null,
    actvtgtco85: null,
    actvtgtco90: null,
    actvtgtco95: null,
    rptcutcat: 'Tenure',
    rptcut: 'Eligible for a Full Year of Awards',
    surveydataview: 'By Position',
    postitle: 'Senior Account Executive',
    sortorder: 6076,
  },
];
