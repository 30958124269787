// Styles for this component
import './Participation.scss';

// React Imports
import React, { useEffect } from 'react';

// Third-party Imports
import { connect } from 'react-redux';

// Croner Imports
import { getCSRF } from '../../actions/authentication';
import ParticipationContent from './ParticipationContent';
import ParticipationHeader from './ParticipationHeader';

const Participation = ({ companyGuid, csrfToken, features, userGuid, getCSRF }) => {
  useEffect(() => {
    // Get CSRF token
    getCSRF();
  }, [getCSRF]);

  if (
    features && 
    Object.keys(features).length &&
    features.find(
      (obj) => obj.id === 2 && obj.render
    )
  ) {
    return (
      <div id='participation'>
        <ParticipationHeader />
        <ParticipationContent
          csrfToken={csrfToken}
          surveyId={'80bbedb8-a66a-ed11-9562-000d3a32fe4e'}
          companyGuid={companyGuid}
          userGuid={userGuid}
        />
      </div>
    );
  }
  return <h1>Unavailable</h1>;
};

const mapDispatchToProps = {
  getCSRF, // This connects the getCSRF action to the component
};

const mapStateToProps = (state) => {
  return {
    companyGuid: state.auth.companyGuid,
    csrfToken: state.auth.csrfToken,
    features: state.features.features,
    userGuid: state.auth.userGuid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participation);